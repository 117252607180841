import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import { math } from 'polished'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'
import { Column, RETABLE_ID_AVAILABILITIES, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { c } from 'ttag'
import GenericAddAvailabilityBtn from 'modules/asset/availability/components/GenericAddAvailabilityBtn'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Asset } from 'modules/asset/store/asset.types'
import { testIdHistoryButton } from 'modules/asset/availability/Availabilities.ids'
import { Availability } from 'modules/asset/availability/types/availability.types'
import UpdateTableItemActions from 'modules/reTable/UpdateTableItemsActions'
import { QUERY_ACTIVE_TAB, QUERY_ASSET } from 'utils/query-string'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForMaintenance, isAdmin, isImpersonatedAdmin } from 'utils/user'

interface ToolbarContainerProps {
  width: string
}

const ToolbarContainer = styled(Box)<ToolbarContainerProps>`
  width: ${(props) => props.width};
  max-width: 100%;
`

interface AvailabilityTableToolbarProps {
  onAddNewItem: (event: React.MouseEvent<HTMLElement> | null, asset: Asset) => void
  onToggleHistory: () => void
  showHistory?: boolean
  columns: Column[]
  tableHeaderHasActions: boolean
  formDirty: boolean
  assets: Asset[]
  showHistoryButton?: boolean
  editedAvailability: Availability | null
}

const AvailabilityTableToolbar: React.FC<AvailabilityTableToolbarProps> = ({
  onAddNewItem,
  onToggleHistory,
  showHistory,
  columns,
  tableHeaderHasActions,
  formDirty,
  assets,
  showHistoryButton,
  editedAvailability,
}) => {
  const user = useSelector(getUserResultSelector)
  const hasAccess = useMemo(() => hasPermissionForMaintenance(user) || isAdmin(user) || isImpersonatedAdmin(user), [
    user,
  ])

  const [currentAsset, setCurrentAsset] = useState<Asset | null>(null)
  const [action, setAction] = useState<ReTableRowContextActions | null>(null)
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState<boolean>(false)

  const handleCancelAction = useCallback(() => {
    setOpenUnsavedDialog(false)
    setAction(null)
    setCurrentAsset(null)
  }, [])

  const handleProceedAddNewItem = useCallback(
    (data?: Asset) => {
      const asset = data || currentAsset
      if (asset) {
        onAddNewItem(null, asset)
      }
      handleCancelAction()
    },
    [currentAsset, onAddNewItem],
  )

  const handleAddNewItem = useCallback(
    (event: React.MouseEvent<HTMLElement> | null, asset: Asset) => {
      if (event) event.stopPropagation()
      setCurrentAsset(asset)
      setAction(ReTableRowContextActions.ADD_ROW)
      if (formDirty) {
        setOpenUnsavedDialog(true)
      } else {
        handleProceedAddNewItem(asset)
      }
    },
    [formDirty, handleProceedAddNewItem],
  )

  const columnsWidth = useMemo(() => {
    const width = (columns || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(`${width} + ${editableRowActionsCellWidth} + ${tableHeaderHasActions ? TableHeaderActionCellWidth : 0}`)
  }, [columns])

  const actions = useMemo(() => {
    return (
      <>
        {showHistoryButton && (
          <Button
            data-testid={testIdHistoryButton}
            disabled={editedAvailability?.uiIsPast}
            onClick={onToggleHistory}
            color="primary"
            size="small"
          >
            {showHistory ? c('Availability').t`Hide history` : c('Availability').t`Show history`}
          </Button>
        )}

        <UpdateTableItemActions
          openUnsavedDialogInitialValue={openUnsavedDialog}
          currentActionInitialValue={action}
          onAddItem={handleProceedAddNewItem}
          dialogContext={c('Availability').t`availability limitation`}
          dialogText={c(`Availability`).t`availability limitation`}
          pageQueries={[QUERY_ASSET, QUERY_ACTIVE_TAB]}
          navigationDialogKey={'availabilityLimitation'}
          customActionComponent={
            hasAccess ? <GenericAddAvailabilityBtn assets={assets} onAddNewAvailability={handleAddNewItem} /> : <></>
          }
          onCancelAction={handleCancelAction}
        />
      </>
    )
  }, [
    handleAddNewItem,
    showHistory,
    onToggleHistory,
    showHistoryButton,
    openUnsavedDialog,
    action,
    handleProceedAddNewItem,
    hasAccess,
  ])

  return (
    <ToolbarContainer flexGrow={1} width={columnsWidth}>
      <ReTableToolbar id={RETABLE_ID_AVAILABILITIES} actions={actions} showClearSorting={true} />
    </ToolbarContainer>
  )
}

export default React.memo(AvailabilityTableToolbar)
