import React from 'react'

interface FloatingElementProps {
  position: Record<string, number>
  children: React.ReactElement
}

const Floatingelement: React.FC<FloatingElementProps> = ({ position, children }) => {
  return (
    <div
      id="floatingElement"
      style={{
        position: 'absolute',
        top: `${position.y - 90}px`,
        left: `${position.x - 80}px`,
        width: 'inherit',
        minHeight: '50px',
        backgroundColor: 'white',
        zIndex: 9999999,
        boxShadow: '0px 0px 4px grey',
      }}
    >
      {children}
    </div>
  )
}

export default Floatingelement
