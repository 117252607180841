import { ChartDataRangeType } from 'modules/workspace/store/workspace.types'
import { Asset } from 'modules/asset/store/asset.types'
import { DataStream, DataStreamSelectionItem } from 'modules/dataStreams/dataStreams.types'
import { SimpleRange } from 'utils/timeseries'
import { EnercastDate } from 'utils/date'
import { Timezone } from 'fixtures/timezones'

export interface CreateScheduleInputData {
  start?: EnercastDate
  end?: EnercastDate
  range?: ChartDataRangeType
  asset?: Asset | null
  sourceDataStream?: DataStream | DataStreamSelectionItem | null
  targetScheduleDataStream?: DataStream | DataStreamSelectionItem | null
  savedSeries?: SimpleRange[]
  useSource?: boolean
  refetch?: boolean
  detectedInterval?: number
  timezoneWhenFormInitialized?: Timezone
  savedInUserSetting?: boolean
}

export interface CreateScheduleOutputData {
  series: SimpleRange[]
  changed: boolean
}

export interface HighlightedSchedulePeriod {
  start: Date
  end: Date
  event: any
}

export enum ScheduleSeriesUpdateModes {
  offset = 'offset',
  percentage = 'percentage',
  linearly = 'linearly',
  point = 'point',
  minimum = 'minimum',
  maximum = 'maximum',
}

export enum ScheduleEditModeChartOptions {
  select = 'select',
  zoom = 'zoom',
}

export interface SubmitScheduleSeriesProps {
  scheduleInput: CreateScheduleInputData
  withTemplate?: boolean
}

export interface SavedScheduleConfig {
  assetId: string
  sourceDataStreamId: string
  targetScheduleDataStreamId: string
}

export interface ScheduleTemplateTableItem {
  name?: string
  assetId: string
  assetName: string
  capacitySplit: string
  posName: string
  scheduleSavingTime: string
  scheduleSource: string
  scheduleTarget: string
  parent: boolean
  errorStatus?: string
  warnings?: string[]
}

export enum ScheduleTemplateUploadStatus {
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_WARNINGS = 'SUCCESS_WITH_WARNINGS',
  TEMPLATE_CONTAINS_MORE_SCHEDULES_THAN_THE_ONE_SELECTED = 'TEMPLATE_CONTAINS_MORE_SCHEDULES_THAN_THE_ONE_SELECTED',
  ASSET_CONTAINS_CHILDREN_NOT_INCLUDED_IN_THE_TEMPLATE = 'ASSET_CONTAINS_CHILDREN_NOT_INCLUDED_IN_THE_TEMPLATE',
  ASSET_SCHEDULE_NOT_FOUND_ERROR = 'ASSET_SCHEDULE_NOT_FOUND_ERROR',
  TEMPLATE_CONTRACT_CAPACITIES_ERROR = 'TEMPLATE_CONTRACT_CAPACITIES_ERROR',
  INCOMPATIBLE_TEMPLATE_ERROR = 'INCOMPATIBLE_TEMPLATE_ERROR',
  TEMPLATE_PROCESSING_ERROR = 'TEMPLATE_PROCESSING_ERROR',
  TemplateUploadException = 'TemplateUploadException',
  ASSET_NOT_FOUND_ERROR = 'ASSET_NOT_FOUND_ERROR',
  POS_NAME_NOT_FOUND_IN_ASSET_TREE = 'POS_NAME_NOT_FOUND_IN_ASSET_TREE',
  UPLOADED_SCHEDULE_NOT_IN_TEMPLATE_DATE = 'UPLOADED_SCHEDULE_NOT_IN_TEMPLATE_DATE',
  ERROR_PROCESSING_TEMPLATE_ASSET = 'ERROR_PROCESSING_TEMPLATE_ASSET',
  NO_FILES_SUBMITTED = 'NO_FILES_SUBMITTED',
  INCOMPLETE_TEMPLATE_INFORMATION = 'INCOMPLETE_TEMPLATE_INFORMATION',
  TEMPLATE_UPLOAD_ERROR = 'TEMPLATE_UPLOAD_ERROR',
  MISSING_TEMPLATE_CAPACITY_VALUE = 'MISSING_TEMPLATE_CAPACITY_VALUE',
  MISSING_TEMPLATE_PARENT = 'MISSING_TEMPLATE_PARENT',
  SELECTED_ASSET_MISSING_POS_NAME_VALUE = 'SELECTED_ASSET_MISSING_POS_NAME_VALUE',
  MISSING_TEMPLATE_DATE = 'MISSING_TEMPLATE_DATE',
  MISSING_TEMPLATE_SCHEDULING_ENTITY = 'MISSING_TEMPLATE_SCHEDULING_ENTITY',
  ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD = 'ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD',
  ASSET_SCHEDULE_PERIOD_SHORTER_THAN_TEMPLATE_SCHEDULE_PERIOD = 'ASSET_SCHEDULE_PERIOD_SHORTER_THAN_TEMPLATE_SCHEDULE_PERIOD',
  ASSET_CAPACITY_VALUE_MISMATCH = 'ASSET_CAPACITY_VALUE_MISMATCH',
  MISSING_ASSET_AC_CAPACITY_VALUE = 'MISSING_ASSET_AC_CAPACITY_VALUE',
  REQUIRED_CHILD_ASSETS_MISSING_IN_ASSET_TREE = 'REQUIRED_CHILD_ASSETS_MISSING_IN_ASSET_TREE',
}
