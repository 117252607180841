import React from 'react'

import { Box } from '@material-ui/core'
import LayoutTitle from 'ui/LayoutTitle'
import { t } from 'ttag'
import styled from 'styled-components'
import { usePenaltyRegulationTableItems } from 'utils/penaltyRegulations'
import PenaltyRegulationsTable from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationsTable'
import {
  KEY_SHOW_IN_ACTIVE_PENALTIES,
  useUserSetting,
  useUserSettingSaveMutation,
} from 'modules/auth/api/userSettings.api'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import Flex from 'ui/styles/Flex'
import { QUERY_PENALTY_DETAILS, useQueryParams } from 'utils/query-string'
import { transitionFor } from 'themes/theme-light'

const TableWrapper = styled(Box)`
  width: ${(props) => props.width};
  height: calc(100% - 5.2em);
`

const PenaltyRegulations: React.FC = () => {
  const { mutate: mutateShowDeletedPenalties } = useUserSettingSaveMutation<boolean>(KEY_SHOW_IN_ACTIVE_PENALTIES)
  const { queryParams } = useQueryParams()
  const isDetailsShown = queryParams[QUERY_PENALTY_DETAILS]
  const penalties = usePenaltyRegulationTableItems()
  const userTimezone = useSelector(getUserTimezoneSelector)

  const userShowInActivePenaltiesResults = useUserSetting<Record<string, boolean>>(KEY_SHOW_IN_ACTIVE_PENALTIES)
  const showInActive = userShowInActivePenaltiesResults?.data?.value || false

  const handleToggleShowInActive = () => {
    mutateShowDeletedPenalties({ value: !showInActive })
  }

  return (
    <>
      <Box ml={1}>
        <Flex alignItems="center" justifyContent="space-between">
          <LayoutTitle icon="table">{t`Penalty regulations`}</LayoutTitle>
          <div
            style={{
              marginRight: isDetailsShown ? '50em' : '18px',
              // transition: transitionFor(['margin-right', 'right'], 800),
            }}
          >
            {userTimezone}
          </div>
        </Flex>
      </Box>

      <TableWrapper ml={1} mr={1}>
        <PenaltyRegulationsTable
          showInActive={showInActive}
          onToggleShowInActive={handleToggleShowInActive}
          tableItems={penalties}
        />
      </TableWrapper>
    </>
  )
}

export default PenaltyRegulations
