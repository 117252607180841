import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Asset, TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'

import { ReTableBody } from 'modules/reTable/ReTableBody'
import { Column, RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSortSelector, reTableVirtualRangeSelector } from 'modules/reTable/redux_store/state/view.state'
import { CoordinateCachedData } from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { getAssetQueryObj, isQueryPresentInUrl, QUERY_ASSET } from 'utils/query-string'
import {
  SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
  SAVE_WORKSPACE_DRAFT_SELECT_MODEL,
} from 'modules/workspace/store/workspace.types'
import { useDispatch } from 'react-redux'
import { isAsset, isCluster } from 'utils/asset'
import TreeRow from 'modules/asset/tree/TreeRow'
import { reTableCrop } from 'modules/reTable/reTable.functionality'
import { FindAllCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { DataStream, TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { ForecastModel } from 'modules/forecastModels/forecastModels.types'

interface TreeBodyProps {
  isWide: boolean
  columns: Column[]
  assets: Asset[]
  isAssetDetails: boolean
  onSelect: (asset: Asset, selectionOptions: TreeDataStreamsSelectionOptions) => void
  assetOperational: CoordinateCachedData[]
  backcastStatus: any
  assetFilterSetup: Record<string, FindAllCleansingFilterSettings>
  selectedAssets: string[]
  assetDetailsId: string
  showNumCapFraction: boolean
  dataStreams: DataStream[]
  onSetShowInvalidShiftClickSnackbar: (value: boolean) => void
}

// exported component
const AssetTreeBody: React.FC<TreeBodyProps> = ({
  isWide,
  columns,
  assets,
  isAssetDetails,
  assetOperational,
  backcastStatus,
  assetFilterSetup,
  selectedAssets,
  assetDetailsId,
  showNumCapFraction,
  dataStreams,
  onSetShowInvalidShiftClickSnackbar,
}) => {
  const dispatch = useDispatch()
  const { onDeleteQueryStrings, onAddQueryString } = useUpdateQueryString()
  const [selectedAssetDetailsId, setSelectedAssetDetailsId] = useState<string[]>([])
  const selectedAssetsRef = useRef([])
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_ASSETS)
  const virtualRange = useReTableSelectorWithId(reTableVirtualRangeSelector, RETABLE_ID_ASSETS)
  const thirdPartyForecastDataStreams = dataStreams.filter(
    (dataStream) => dataStream.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
  )
  const [prevSelectedAsset, setPrevSelectedAsset] = useState<Asset | null>(null)

  const assetsToRender = useMemo(() => {
    return reTableCrop({ items: assets, virtualRange })
  }, [assets, virtualRange.start, virtualRange.end])

  const handleShowAssetDetails = useCallback((asset: Asset) => {
    const urlQueryData = isQueryPresentInUrl(QUERY_ASSET, asset?.id)

    const closeDetails = urlQueryData.queryPresent && urlQueryData.hasPassedValue
    if (closeDetails) {
      onDeleteQueryStrings([QUERY_ASSET])
    } else {
      onAddQueryString(getAssetQueryObj(asset, urlQueryData.queryParams))
    }
    setSelectedAssetDetailsId(closeDetails ? [] : [asset?.id])
  }, [])

  const handleSelectAssetOrModel = useCallback(
    (assetOrModel: Asset | ForecastModel, selectionOptions: TreeDataStreamsSelectionOptions) => {
      const selectedAssets = selectedAssetsRef.current || []
      if (isAsset(assetOrModel)) {
        const currentAsset = assetOrModel
        if (selectionOptions.shiftKey && prevSelectedAsset) {
          // Parent is is not the actual generator/park/cluster relationship but just the way they are displayed in the tree
          const currentAssetUiParent = (currentAsset?.uiParents || []).toString()
          const prevAssetUiParent = (prevSelectedAsset?.uiParents || []).toString()
          // If the first asset and the previous asset belongs to different branch/uiParent in the tree do not select anything
          if (currentAssetUiParent !== prevAssetUiParent) {
            return
          }
          // Shift click will only select the assets on same level
          if (currentAsset.uiLevel !== prevSelectedAsset.uiLevel) {
            onSetShowInvalidShiftClickSnackbar(true)
          } else {
            const assetsOnSameLevel = assets.filter((a) => a.uiLevel === prevSelectedAsset.uiLevel)
            // const assetsOnSameLevel = assets
            const prevAssetSelectedIndex = assetsOnSameLevel.findIndex((a) => a.id === prevSelectedAsset?.id)
            const currAssetIndex = assetsOnSameLevel.findIndex((a) => a.id === currentAsset?.id)
            const sortIndexes = [prevAssetSelectedIndex, currAssetIndex].sort((a, b) => a - b)

            const assetsToSelect = assetsOnSameLevel
              .slice(sortIndexes[0], sortIndexes[1] + 1)
              .filter((a) => a?.uiParents.toString() === currentAssetUiParent)

            dispatch({
              type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
              selectMultipleAssets: assetsToSelect,
              selectionOptions,
            })
          }
        } else {
          const alreadySelected = selectedAssets.some((assetId) => assetId === assetOrModel.id)
          if (alreadySelected && selectionOptions.ctrlKey) {
            // Remove the selected item
            setPrevSelectedAsset(null)
          } else {
            setPrevSelectedAsset(assetOrModel)
          }

          dispatch({
            type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
            asset: currentAsset,
            selectionOptions,
          })
        }
      } else {
        // Select the model
        const model = assetOrModel
        dispatch({
          type: SAVE_WORKSPACE_DRAFT_SELECT_MODEL,
          model,
          selectionOptions,
        })
      }
    },
    [thirdPartyForecastDataStreams, assets, prevSelectedAsset],
  )

  const handleSelect = useCallback(
    (asset: Asset, selectionOptions: TreeDataStreamsSelectionOptions) => {
      const urlQueryData = isQueryPresentInUrl(QUERY_ASSET)
      if (urlQueryData.queryPresent) {
        handleShowAssetDetails(asset)
      } else {
        setTimeout(() => {
          handleSelectAssetOrModel(asset, selectionOptions)
        }, 1)
      }
    },
    [handleSelectAssetOrModel],
  )

  // Handlers to select/deselect assets Ctrl-click, then hold Ctrl+mouse key and move the mouse
  // Start here
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)
  const [selectionStartIndex, setSelectionStartIndex] = useState(-1)
  const [selectionEndIndex, setSelectionEndIndex] = useState(-1)
  const [removeMultipleAssets, setRemoveMultipleAssets] = useState(false)
  const handleMouseDown = (index: number, event) => {
    if (event.ctrlKey || event.metaKey) {
      // deselect multiple assets if the start asset is already selected
      setRemoveMultipleAssets(selectedAssets.includes(assets[index]?.id))
      setIsCtrlPressed(true)
      if (selectionStartIndex === -1) {
        setSelectionStartIndex(index)
      }
      event.preventDefault()
    }
  }

  const handleMouseEnter = (index: number, event) => {
    if (isCtrlPressed && selectionStartIndex !== -1) {
      setSelectionEndIndex(index)
      handleSelectMultipleAssetsWithCtrlClick(selectionStartIndex, index)
    }
  }

  const handleMouseUp = (index: number, event) => {
    if (isCtrlPressed && selectionStartIndex !== -1) {
      setSelectionEndIndex(index)
      handleSelectMultipleAssetsWithCtrlClick(selectionStartIndex, index)
      resetSelection()
    }
  }

  const handleSelectMultipleAssetsWithCtrlClick = (start: number, end: number) => {
    if (start !== end) {
      // We set to null because this is only used when shift click
      setPrevSelectedAsset(null)
      const assetsToSelect = assets.filter((a, index) => {
        return index >= Math.min(start, end) && index <= Math.max(start, end)
      })

      if (assetsToSelect) {
        if (removeMultipleAssets) {
          dispatch({
            type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
            unSelectMultipleAssets: assetsToSelect,
            selectionOptions: { shiftKey: true, ctrlKey: false },
          })
        } else {
          dispatch({
            type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
            selectMultipleAssets: assetsToSelect,
            selectionOptions: { shiftKey: true, ctrlKey: false },
          })
        }
      }
    }
  }

  const resetSelection = () => {
    setIsCtrlPressed(false)
    setSelectionStartIndex(-1)
    setSelectionEndIndex(-1)
  }

  // ------------------------ Ends here -------------------

  useEffect(() => {
    selectedAssetsRef.current = selectedAssets
  }, [selectedAssets])

  useEffect(() => {
    if (!isAssetDetails) {
      setSelectedAssetDetailsId([])
    } else if (assetDetailsId && !selectedAssetDetailsId.includes(assetDetailsId)) {
      setSelectedAssetDetailsId([assetDetailsId])
    }
  }, [isAssetDetails, assetDetailsId, selectedAssetDetailsId.length])

  return (
    <ReTableBody>
      {assetsToRender.map((asset, index) => {
        const selectedItem = selectedAssets.find((assetId) => asset?.id === assetId)
        const isSelected = Boolean(selectedItem)

        const detailsVisible = selectedAssetDetailsId.includes(asset.id)
        const i = assetsToRender.slice(index + 1, assetsToRender.length).filter((a) => a.id === asset.id).length
        const key = `${asset.name}:${asset.id}:${asset.uiAncestors.join('_')}:${i}`
        const operationalData = !isCluster(asset)
          ? (assetOperational || []).find((data) => {
              return (
                data.latitude == asset?.location?.coordinate.latitude &&
                data.longitude == asset?.location?.coordinate.longitude
              )
            })
          : null
        const backcastStatusPerAsset = backcastStatus?.[asset.id]
        const filterSettingsPerAsset = assetFilterSetup?.[asset.id]

        const lightMode = isSelected && selectedAssetDetailsId.find((id) => Boolean(id))

        return (
          <TreeRow
            key={key}
            asset={asset}
            level={sort.active ? 0 : asset.uiLevel}
            isSelected={isSelected}
            isWide={isWide}
            showNumCapFraction={showNumCapFraction}
            columns={columns}
            lightMode={Boolean(lightMode)}
            detailsVisible={detailsVisible}
            onSelect={handleSelect}
            onToggleDetails={handleShowAssetDetails}
            operationalData={operationalData}
            backcastStatusPerAsset={backcastStatusPerAsset}
            filterSettingsPerAsset={filterSettingsPerAsset}
            index={index}
            mouseDown={handleMouseDown}
            mouseEnter={handleMouseEnter}
            mouseUp={handleMouseUp}
            prevSelectedAsset={prevSelectedAsset}
          />
        )
      })}
    </ReTableBody>
  )
}

// AssetTreeBody.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(AssetTreeBody)
