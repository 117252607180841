import { DataSelectionMode, DataStreamSelection, TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { DateRange } from 'utils/date'
import { MonthlyViewModeIds } from 'utils/seasonalForecast'
import {
  CreateScheduleInputData,
  HighlightedSchedulePeriod,
  ScheduleEditModeChartOptions,
  ScheduleSeriesUpdateModes,
} from 'modules/workspace/schedule/schedule.types'

// get workspace draft
export const GET_WORKSPACE_DRAFT_REQUEST = 'GET_WORKSPACE_DRAFT_REQUEST'
export const GET_WORKSPACE_DRAFT_SUCCESS = 'GET_WORKSPACE_DRAFT_SUCCESS'
export const GET_WORKSPACE_DRAFT_FAILURE = 'GET_WORKSPACE_DRAFT_FAILURE'
export const GET_WORKSPACE_DRAFT_DISMISS = 'GET_WORKSPACE_DRAFT_DISMISS'
export type GET_WORKSPACE_DRAFT_REQUEST = typeof GET_WORKSPACE_DRAFT_REQUEST
export type GET_WORKSPACE_DRAFT_SUCCESS = typeof GET_WORKSPACE_DRAFT_SUCCESS
export type GET_WORKSPACE_DRAFT_FAILURE = typeof GET_WORKSPACE_DRAFT_FAILURE
export type GET_WORKSPACE_DRAFT_DISMISS = typeof GET_WORKSPACE_DRAFT_DISMISS
export type GET_WORKSPACE_DRAFT_ACTION_TYPE =
  | GET_WORKSPACE_DRAFT_REQUEST
  | GET_WORKSPACE_DRAFT_SUCCESS
  | GET_WORKSPACE_DRAFT_FAILURE
  | GET_WORKSPACE_DRAFT_DISMISS

// save workspace draft
export const SAVE_WORKSPACE_DRAFT_REQUEST = 'SAVE_WORKSPACE_DRAFT_REQUEST'
export const SAVE_WORKSPACE_DRAFT_SUCCESS = 'SAVE_WORKSPACE_DRAFT_SUCCESS'
export const SAVE_WORKSPACE_DRAFT_FAILURE = 'SAVE_WORKSPACE_DRAFT_FAILURE'
export const SAVE_WORKSPACE_DRAFT_DISMISS = 'SAVE_WORKSPACE_DRAFT_DISMISS'
export const SAVE_WORKSPACE_DRAFT_SELECT_ASSET = 'SAVE_WORKSPACE_DRAFT_SELECT_ASSET'
export const SAVE_WORKSPACE_DRAFT_SELECT_MODEL = 'SAVE_WORKSPACE_DRAFT_SELECT_MODEL'
export type SAVE_WORKSPACE_DRAFT_REQUEST = typeof SAVE_WORKSPACE_DRAFT_REQUEST
export type SAVE_WORKSPACE_DRAFT_SUCCESS = typeof SAVE_WORKSPACE_DRAFT_SUCCESS
export type SAVE_WORKSPACE_DRAFT_FAILURE = typeof SAVE_WORKSPACE_DRAFT_FAILURE
export type SAVE_WORKSPACE_DRAFT_DISMISS = typeof SAVE_WORKSPACE_DRAFT_DISMISS
export type SAVE_WORKSPACE_DRAFT_SELECT_ASSET = typeof SAVE_WORKSPACE_DRAFT_SELECT_ASSET
export type SAVE_WORKSPACE_DRAFT_SELECT_MODEL = typeof SAVE_WORKSPACE_DRAFT_SELECT_MODEL
export type SAVE_WORKSPACE_DRAFT_ACTION_TYPE =
  | SAVE_WORKSPACE_DRAFT_REQUEST
  | SAVE_WORKSPACE_DRAFT_SUCCESS
  | SAVE_WORKSPACE_DRAFT_FAILURE
  | SAVE_WORKSPACE_DRAFT_DISMISS
  | SAVE_WORKSPACE_DRAFT_SELECT_ASSET
  | SAVE_WORKSPACE_DRAFT_SELECT_MODEL

// asset selection mode
export const ASSET_SELECTION_MODE_SINGLE = 'ASSET_SELECTION_MODE_SINGLE'
export const ASSET_SELECTION_MODE_MULTIPLE = 'ASSET_SELECTION_MODE_MULTIPLE'
export const ASSET_SELECTION_MODE_HIERARCHY = 'ASSET_SELECTION_MODE_HIERARCHY'
export type ASSET_SELECTION_MODE_SINGLE = typeof ASSET_SELECTION_MODE_SINGLE
export type ASSET_SELECTION_MODE_MULTIPLE = typeof ASSET_SELECTION_MODE_MULTIPLE
export type ASSET_SELECTION_MODE_HIERARCHY = typeof ASSET_SELECTION_MODE_HIERARCHY
export type AssetSelectionMode =
  | ASSET_SELECTION_MODE_SINGLE
  | ASSET_SELECTION_MODE_MULTIPLE
  | ASSET_SELECTION_MODE_HIERARCHY

export enum BackCastRangeType {
  CHART_DATA_RANGE_LAST_MONTH = 'CHART_DATA_RANGE_LAST_MONTH',
  CHART_DATA_RANGE_LAST_3_MONTHS = 'CHART_DATA_RANGE_LAST_3_MONTHS',
  CHART_DATA_RANGE_LAST_6_MONTHS = 'CHART_DATA_RANGE_LAST_6_MONTHS',
  CHART_DATA_RANGE_LAST_YEAR = 'CHART_DATA_RANGE_LAST_YEAR',
  CHART_DATA_RANGE_LAST_2_YEARS = 'CHART_DATA_RANGE_LAST_2_YEARS',
  CHART_DATA_RANGE_LAST_3_YEARS = 'CHART_DATA_RANGE_LAST_3_YEARS',
  CHART_DATA_RANGE_CUSTOM = 'CHART_DATA_RANGE_CUSTOM',
}

export enum MeterDataCleansingRangeType {
  CHART_DATA_RANGE_CUSTOM = 'CHART_DATA_RANGE_CUSTOM',
  AVAILABLE_PERIOD = 'AVAILABLE_PERIOD',
}

// chart data range
export enum ChartDataRangeType {
  CHART_DATA_RANGE_YESTERDAY = 'CHART_DATA_RANGE_YESTERDAY',
  CHART_DATA_RANGE_TODAY = 'CHART_DATA_RANGE_TODAY',
  CHART_DATA_RANGE_TOMORROW = 'CHART_DATA_RANGE_TOMORROW',
  CHART_DATA_RANGE_NEXT_30_DAYS = 'CHART_DATA_RANGE_NEXT_30_DAYS',
  CHART_DATA_RANGE_NEXT_7_DAYS = 'CHART_DATA_RANGE_NEXT_7_DAYS',
  CHART_DATA_RANGE_PLUS_MINUS_7_DAYS = 'CHART_DATA_RANGE_PLUS_MINUS_7_DAYS',
  CHART_DATA_RANGE_LAST_7_DAYS = 'CHART_DATA_RANGE_LAST_7_DAYS',
  CHART_DATA_RANGE_LAST_30_DAYS = 'CHART_DATA_RANGE_LAST_30_DAYS',
  CHART_DATA_RANGE_THIS_WEEK = 'CHART_DATA_RANGE_THIS_WEEK',
  CHART_DATA_RANGE_LAST_WEEK = 'CHART_DATA_RANGE_LAST_WEEK',
  CHART_DATA_RANGE_THIS_MONTH = 'CHART_DATA_RANGE_THIS_MONTH',
  CHART_DATA_RANGE_LAST_MONTH = 'CHART_DATA_RANGE_LAST_MONTH',
  CHART_DATA_RANGE_THIS_QUARTER = 'CHART_DATA_RANGE_THIS_QUARTER',
  CHART_DATA_RANGE_THIS_QUARTER_TO_DATE = 'CHART_DATA_RANGE_THIS_QUARTER_TO_DATE',
  CHART_DATA_RANGE_THIS_MONTH_TO_DATE = 'CHART_DATA_RANGE_THIS_MONTH_TO_DATE',
  CHART_DATA_RANGE_LAST_QUARTER = 'CHART_DATA_RANGE_LAST_QUARTER',
  CHART_DATA_RANGE_THIS_YEAR = 'CHART_DATA_RANGE_THIS_YEAR',
  CHART_DATA_RANGE_THIS_YEAR_TO_DATE = 'CHART_DATA_RANGE_THIS_YEAR_TO_DATE',
  CHART_DATA_RANGE_LAST_YEAR = 'CHART_DATA_RANGE_LAST_YEAR',
  // CHART_DATA_RANGE_LAST_3_YEARS = 'CHART_DATA_RANGE_LAST_3_YEARS',
  CHART_DATA_RANGE_CUSTOM = 'CHART_DATA_RANGE_CUSTOM',
}

export enum RangeTypes {
  BACKAST_RANGE = 'BACKAST_RANGE',
  MAIN_CHART_RANGE = 'MAIN_CHART_RANGE',
}

// chart aggregation mode
export enum ChartAggregationMode {
  CHART_AGGREGATION_MODE_GROUP_BY_ASSET = 'CHART_AGGREGATION_MODE_GROUP_BY_ASSET',
  CHART_AGGREGATION_MODE_AGGREGATE_SUM = 'CHART_AGGREGATION_MODE_AGGREGATE_SUM',
  CHART_AGGREGATION_MODE_AGGREGATE_MIN = 'CHART_AGGREGATION_MODE_AGGREGATE_MIN',
  CHART_AGGREGATION_MODE_AGGREGATE_MAX = 'CHART_AGGREGATION_MODE_AGGREGATE_MAX',
  CHART_AGGREGATION_MODE_AGGREGATE_AVG = 'CHART_AGGREGATION_MODE_AGGREGATE_AVG',
  CHART_AGGREGATION_MODE_AGGREGATE_WEIGHTED_AVG = 'CHART_AGGREGATION_MODE_AGGREGATE_WEIGHTED_AVG',
}

// chart widgets
export enum ChartWidget {
  WIDGET_LINE_CHART = 'WIDGET_LINE_CHART',
  WIDGET_DAILY_MEAN_CHART = 'WIDGET_DAILY_MEAN_CHART',
  WIDGET_FORECAST_ERROR_CHART = 'WIDGET_FORECAST_ERROR_CHART',
  WIDGET_QUALITY_TABLE = 'WIDGET_QUALITY_TABLE',
  WIDGET_META_FORECAST = 'WIDGET_META_FORECAST',
  WIDGET_PENALTY_TABLE = 'WIDGET_PENALTY_TABLE',
  WIDGET_SEASONAL_TABLE = 'WIDGET_SEASONAL_TABLE',
  WIDGET_EVALUATION_TABLE = 'WIDGET_EVALUATION_TABLE',
  WIDGET_SCHEDULE = 'WIDGET_SCHEDULE',
}

// chart type
export const CHART_TYPE_LINE = 'CHART_TYPE_LINE'
export const CHART_TYPE_OPTIMIZATION = 'CHART_TYPE_OPTIMIZATION'
export type CHART_TYPE_LINE = typeof CHART_TYPE_LINE
export type CHART_TYPE_OPTIMIZATION = typeof CHART_TYPE_OPTIMIZATION
export type ChartType = CHART_TYPE_LINE | CHART_TYPE_OPTIMIZATION

// config options
export interface AssetOptions {
  selection: string[]
  selectionMode: AssetSelectionMode
}

export interface ChartDataRange {
  rangeType: ChartDataRangeType | BackCastRangeType
  customRange: DateRange | null
}

// selected chart range is represented by number of milliseconds from start and end of whole range
export type ChartRangeSelectionOffset = [number, number]

export interface ChartConfig {
  name: string
  aggregationMode: ChartAggregationMode | null
  range: ChartRangeSelectionOffset // this is the displayed range (selected in the highstock navigator) - milliseconds from start and end
  dataRange: ChartDataRange // this is the range of loaded series data
  widgets: ChartWidget[]
  type: ChartType
}

export interface DataStreamOptions {
  selection: DataStreamSelection
  selectionMode: DataSelectionMode
  selectedDataStreamDetailsIds: string[]
}

export enum PenaltyWidgetSelectionModes {
  SELECTION = 'SELECTION',
  DROPDOWN = 'DROPDOWN',
}

export interface PenaltyWidgetOptions {
  siteForecastId: string
  selectionMode: PenaltyWidgetSelectionModes
}

export interface MonthlyViewWidgetOptions {
  selectionMode: MonthlyViewModeIds
}

export interface WorkspaceDraftScheduleSeries {
  scheduleSeriesInput?: CreateScheduleInputData
  changeSeriesMode?: ScheduleSeriesUpdateModes
  scheduleEditModeChartOptions?: ScheduleEditModeChartOptions
  hasSelectedSchedulePeriodToHighlight?: HighlightedSchedulePeriod
  touchedPoints?: number[]
}

// workspace configs
export interface WorkspaceConfig {
  id?: string
  asset: AssetOptions
  chart: ChartConfig
  data: DataStreamOptions
  default?: true
  lastUpdated?: string
  e3WidgetSettings?: Record<string, any>
  selectedModels?: any[]
  penaltyWidget?: PenaltyWidgetOptions
  monthlyViewWidget?: MonthlyViewWidgetOptions
  schedule?: WorkspaceDraftScheduleSeries
}

// penalty calculation

export interface EquidistantTimeSeries {
  resolution: string
  firstTimeKey: {
    start: Date
    end: Date
  }
  data: (number | null)[]
}

export interface TimeSeriesInterval {
  start: Date
  end: Date
  unboundedStart: boolean
  unboundedEnd: boolean
  empty: boolean
}

export interface TimeSeriesTime {
  instant: Date
  duration: string
  start: Date
  end: Date
  interval: TimeSeriesInterval
  right: Date
  left: Date
}

export interface LineChartSettings {
  showAvailabilityPlotBands: boolean
  showDayPlotBands: boolean
  showChartTooltip: boolean
  showBackCast: boolean
  scaleToNameplateCapacity: boolean
}

// This interface is used when you want to implement chart settings button independently.
// Add other keys , depending on usage.
export interface ChartSettings {
  showChartTooltip: boolean
}

export type MetaForecastWidgetType = TimeSeriesSubType.E3_THIRD_PARTY_FORECAST | TimeSeriesSubType.E3_WEATHER_TRACK
