import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  PenaltyFormData,
  PenaltyTableTypeOfRow,
  usePenaltyFormSaveMainItemKey,
  usePenaltyRegulations,
  usePenaltyRegulationsSaveMutation,
} from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

import { Form } from 'react-final-form'
import {
  checkDetailsHaveChanged,
  checkForUnspecifiedGenerators,
  getNewPenaltyEntityData,
  getPenaltyEntityName,
  getPenaltyUpdateWarningMessage,
  preparePenaltyFormDataToSubmit,
  usePenaltyRegulationTableItems,
} from 'utils/penaltyRegulations'
import { PenaltyRegulationTableItemNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { FormApi, Mutator } from 'final-form'

import { validatePenaltyRegulationForm } from 'utils/formValidations'
import {
  getPenaltyDetailsNewQueryObj,
  getPenaltyDetailsQueryObj,
  getPenaltyDetailsQueryStrings,
  useQueryString,
} from 'utils/query-string'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { c, t } from 'ttag'
import { formMutators, FormSaveOptions, genericFormSubscription } from 'utils/form'
import styled from 'styled-components'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import PenaltyForm from 'modules/data/penalties/PenaltyRegulationNew/PenaltyForm'
import { Box } from '@material-ui/core'
import CenteredLoading from 'ui/CenteredLoading'
import Flex from 'ui/styles/Flex'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'

const FormContainer = styled(Flex)`
  height: 100%;
`

interface DetailsContainerProps {
  disable: boolean
}

export const DisableContainer = styled.div<DetailsContainerProps>`
  pointer-events: ${(props) => (props.disable ? 'none' : 'inherit')};
  opacity: ${(props) => (props.disable ? '0.6' : 'inherit')};
`

let formReference: FormApi<PenaltyRegulationTableItemNew>

interface PenaltyRegulationDetailsProps {
  newEntityType: PenaltyTableTypeOfRow | null
  id: string
}

const PenaltyRegulationDetails: React.FC<PenaltyRegulationDetailsProps> = ({ id, newEntityType }) => {
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const userTimezone = useSelector(getUserTimezoneSelector)
  const [detailsToShowInForm, setDetailsToShowInForm] = useState<PenaltyRegulationTableItemNew>()
  const [showDialog, setShowDialog] = useState(false)
  const [unspecifiedGeneratorErrorMsg, setUnspecifiedGeneratorErrorMsg] = useState<string | string[]>('')
  const [saveWarningMsg, setSaveWarningMsg] = useState<string>('')
  const [transformedDataBeforeSave, setTransformedDataBeforeSave] = useState<PenaltyFormData>()

  const { VERSION, RULESET } = PenaltyTableTypeOfRow
  const penaltyItems = usePenaltyRegulationTableItems()
  const regulationResult = usePenaltyRegulations()
  const savePenaltyFormResult = usePenaltyRegulationsSaveMutation(formReference)
  const {
    mutate: savePenalty,
    reset: resetSaveResult,
    isLoading: resultIsLoading,
    isSuccess: resultIsSuccess,
    data: savedData,
  } = savePenaltyFormResult

  const isNew = Boolean(newEntityType)
  const penaltyIndex = isNew ? undefined : penaltyItems.findIndex((penalty) => penalty.id === id)
  const isLastPenalty = penaltyIndex === penaltyItems.length - 1
  const nextPenaltyItem = penaltyIndex ? (isLastPenalty ? penaltyItems[0] : penaltyItems[penaltyIndex + 1]) : null

  const handleSavePenalty = (data: PenaltyFormData) => {
    const dataToSave = data || transformedDataBeforeSave
    setSaveWarningMsg('')
    // Check if other ruleset or the current ruleset has 'unspecified' generator
    if (dataToSave.typeOfRow === RULESET) {
      const hasUnspecifiedGeneratorMsg = checkForUnspecifiedGenerators({
        ruleSetFormData: dataToSave,
        penaltyItems,
        prevData: detailsToShowInForm,
      })
      if (hasUnspecifiedGeneratorMsg) {
        setUnspecifiedGeneratorErrorMsg(hasUnspecifiedGeneratorMsg)
        setShowDialog(true)
        return true
      } else {
        savePenalty(dataToSave)
      }
    } else {
      savePenalty(dataToSave)
    }
  }

  const handleFormSubmit = useCallback(
    (formData: PenaltyRegulationTableItemNew) => {
      const preparedData = preparePenaltyFormDataToSubmit(formData, userTimezone)
      setTransformedDataBeforeSave(preparedData)

      const detailsChanged = checkDetailsHaveChanged({ prevData: detailsToShowInForm, updatedData: preparedData })
      if (detailsChanged) {
        setShowDialog(true)
        setSaveWarningMsg(getPenaltyUpdateWarningMessage())
      } else {
        handleSavePenalty(preparedData)
      }
    },
    [detailsToShowInForm, userTimezone],
  )

  const versionActiveRuleSets = useMemo(() => {
    if (detailsToShowInForm) {
      const versionId = detailsToShowInForm?.versionId
      const ruleSets = penaltyItems?.filter(
        (item) => item?.typeOfRow === PenaltyTableTypeOfRow.RULESET && item?.versionId === versionId && !item.deleted,
      )
      // Remove the current ruleset
      return (ruleSets || [])?.filter((rs) => rs.id !== id)
    } else return []
  }, [penaltyItems, detailsToShowInForm, id])

  const regulationVersions = useMemo(() => {
    if (detailsToShowInForm) {
      const regulationId = detailsToShowInForm?.regulationId
      const versions = penaltyItems?.filter(
        (item) =>
          item?.typeOfRow === PenaltyTableTypeOfRow.VERSION && item?.regulationId === regulationId && !item.deleted,
      )
      // Remove the current version
      return (versions || [])?.filter((v) => v.id !== id)
    } else return []
  }, [penaltyItems, detailsToShowInForm, id])

  const handleCloseDetails = useCallback(() => {
    onDeleteQueryStrings(getPenaltyDetailsQueryStrings())
  }, [onDeleteQueryStrings])

  /**
   * Load details into Form
   * */
  useEffect(() => {
    if (!resultIsLoading && !regulationResult?.isLoading) {
      const penalty = penaltyItems.find((item) => item.id === id)
      if (newEntityType) {
        const regulationId = newEntityType === VERSION ? penalty?.id : penalty?.regulationId
        const versionId = newEntityType === RULESET ? penalty?.id : null
        const newPenaltyEntityData = getNewPenaltyEntityData({
          regulationId: regulationId || null,
          versionId,
          formDetailsType: newEntityType,
          userTimezone,
        })
        setDetailsToShowInForm(newPenaltyEntityData)
      } else if (penalty) {
        setDetailsToShowInForm(penalty)
      }
    }
  }, [JSON.stringify(penaltyItems), id, newEntityType, resultIsLoading, regulationResult?.isLoading, userTimezone])

  /**
   * Redirections need to be performed after saving
   * */
  const saveMainItemKey = usePenaltyFormSaveMainItemKey()
  const savedMainItem = useMemo(() => {
    const mainItem = isNew ? saveMainItemKey.data?.create : saveMainItemKey.data?.update
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNew, saveMainItemKey.data])

  useEffect(() => {
    if (resultIsSuccess && savedData) {
      const savedPenalty = savedData

      const { SAVE, SAVE_AND_NEW, SAVE_AND_NEXT } = FormSaveOptions
      setTimeout(() => {
        if (savedMainItem === SAVE) {
          onUpdateQueryString(getPenaltyDetailsQueryObj(savedPenalty?.uuid))
        } else if (savedMainItem === SAVE_AND_NEXT && nextPenaltyItem) {
          onUpdateQueryString(getPenaltyDetailsQueryObj(nextPenaltyItem?.uuid as string))
        } else if (savedMainItem === SAVE_AND_NEW && newEntityType) {
          onUpdateQueryString(getPenaltyDetailsNewQueryObj(id as string, newEntityType))
        } else {
          onDeleteQueryStrings(getPenaltyDetailsQueryStrings())
        }
        resetSaveResult()
      }, 100)
    }
  }, [
    isNew,
    savedMainItem,
    onUpdateQueryString,
    resultIsSuccess,
    JSON.stringify(savedData),
    JSON.stringify(nextPenaltyItem),
    id,
    newEntityType,
  ])

  const handleConfirmAction = () => {
    setShowDialog(false)
    if (saveWarningMsg) {
      handleSavePenalty()
    }
  }

  const handleCancelAction = () => {
    setShowDialog(false)
  }

  const formSubscription = useMemo(() => genericFormSubscription(), [])
  const detailsType = detailsToShowInForm?.typeOfRow

  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <PenaltyForm
          form={form}
          saveResult={savePenaltyFormResult}
          onCloseSlider={handleCloseDetails}
          onFormSubmit={handleSubmit}
          isNew={isNew}
          detailsType={detailsType}
        />
      )
    },
    [isNew, savePenaltyFormResult, detailsType],
  )

  /**
   * React form mutators
   * */
  const mutators = useMemo<{
    [key: string]: Mutator<PenaltyRegulationTableItemNew, Partial<PenaltyRegulationTableItemNew>>
  }>(() => {
    return formMutators()
  }, [])

  const entityName = getPenaltyEntityName(detailsType)

  return (
    <div>
      <ConfirmationDialog
        heading={c('Penalties:').jt`Update ${entityName}`}
        text={saveWarningMsg || unspecifiedGeneratorErrorMsg}
        confirmAction={saveWarningMsg ? t`Proceed` : t`Ok`}
        onConfirm={handleConfirmAction}
        onCancel={saveWarningMsg ? handleCancelAction : undefined}
        cancelAction={saveWarningMsg ? t`Cancel` : undefined}
        openDialog={showDialog}
      />

      {resultIsLoading || regulationResult.isLoading ? (
        <Box mt={3}>
          <CenteredLoading size="2em" />
        </Box>
      ) : detailsToShowInForm ? (
        <FormContainer direction="column">
          <Form
            mutators={mutators}
            onSubmit={handleFormSubmit}
            initialValues={detailsToShowInForm}
            validate={(values) =>
              validatePenaltyRegulationForm({
                formData: values,
                activeRuleSets: versionActiveRuleSets,
                regulationVersions,
              })
            }
            subscription={formSubscription}
            render={formRender}
          />
        </FormContainer>
      ) : (
        <DetailsNotFound
          onClose={handleCloseDetails}
          message={t`Penalty not found. Please select one from the list.`}
        />
      )}
    </div>
  )
}

export default PenaltyRegulationDetails
