import { CellRenderType, Column, ColumnSortType, ReTableItem } from 'modules/reTable/reTable.types'
import { c, t } from 'ttag'
import {
  PenaltyBandCostType,
  PenaltyBandNew,
  PenaltyGeneratorType,
  PenaltyRegulationGridType,
  PenaltyRegulationNew,
  PenaltyRegulationRuleSet,
  PenaltyRegulationTableItemNew,
  RegulationTableItem,
  RuleSetTableItem,
  VersionTableItem,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'

import {
  PenaltyFormData,
  PenaltyTableTypeOfRow,
  usePenaltyRegulations,
} from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import { isMaxNumber } from 'utils/dataFormatting'
import { useMemo } from 'react'
import { KEY_SHOW_IN_ACTIVE_PENALTIES, useUserSetting } from 'modules/auth/api/userSettings.api'
import { isAfter, isBefore } from 'date-fns'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { convertUtcToZonedTime, convertZonedTimeToUtc, isSameDate } from 'utils/date'
import { Timezone } from 'fixtures/timezones'

export enum PenaltyBandTypes {
  OVER_INJECTION_BANDS = 'overInjectionPenaltyBands',
  UNDER_INJECTION_BANDS = 'underInjectionPenaltyBands',
}

export const PenaltyNameColumnWidth = '21em '

export const getPenaltyRegulationColumnsNew: () => Column[] = () => [
  {
    name: 'name',
    label: c('PenaltyRegulation').t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: PenaltyNameColumnWidth,
    fieldName: 'created',
    isEditable: true,
    fixed: true,
  },
]

export const getPenaltyGridTypes = () => {
  return [
    {
      label: c('PenaltyRegulation').t`State`,
      id: PenaltyRegulationGridType.STATE,
    },
    {
      label: c('PenaltyRegulation').t`Regional`,
      id: PenaltyRegulationGridType.REGIONAL,
    },
  ]
}

export const getPenaltyCostTypes = () => {
  return [
    {
      label: c('PenaltyRegulation').t`Timeseries percentage`,
      id: PenaltyBandCostType.TIMESERIES_PERCENTAGE,
    },
    {
      label: c('PenaltyRegulation').t`Revenue percentage`,
      id: PenaltyBandCostType.REVENUE_PERCENTAGE,
    },
    {
      label: c('PenaltyRegulation').t`Absolute amount`,
      id: PenaltyBandCostType.FIXED_AMOUNT,
    },
  ]
}

export const getPenaltyGeneratorTypes = () => {
  return [
    {
      label: c('PenaltyRegulation').t`PV`,
      id: PenaltyGeneratorType.PV,
    },
    {
      label: c('PenaltyRegulation').t`Wind`,
      id: PenaltyGeneratorType.WIND,
    },
    {
      label: c('PenaltyRegulation').t`Hybrid`,
      id: PenaltyGeneratorType.HYBRID,
    },
    {
      label: c('PenaltyRegulation').t`Unspecified`,
      id: PenaltyGeneratorType.UNSPECIFIED,
    },
  ]
}

export const getPenaltyCreationErrorMessage = () => {
  return c('Penalties:')
    .t`Cannot add a Ruleset to this version because it already has a ruleset with Unspecified generator type.`
}

export const getPenaltyUpdateWarningMessage = () => {
  return c('Penalties')
    .t`The change might effect the penalty evaluations for all assets where this penalty regulation is used.`
}

export const getNewPenaltyBandData = (
  currentIndex: number,
  lastBandData: PenaltyBandNew | null,
  isFirstBand = false,
): PenaltyBandNew => {
  const penaltyBandData: Partial<PenaltyBandNew> = {
    index: isFirstBand ? 0 : currentIndex + 1,
    from: isFirstBand ? 0 : isMaxNumber(lastBandData?.to) ? '' : lastBandData?.to,
    to: '',
    kpi: 'deviation',
    penaltyCostType: PenaltyBandCostType.REVENUE_PERCENTAGE,
    penaltyCostPerUnit: 0,
  }
  return penaltyBandData
}

export const getPenaltyEntityName = (itemType: PenaltyTableTypeOfRow) => {
  const { REGULATION, VERSION } = PenaltyTableTypeOfRow

  if (itemType === REGULATION) {
    return t`Regulation`
  } else if (itemType === VERSION) {
    return t`Version`
  } else return t`Rule set`
}

export const getPenaltyFormHeaderTitle = (itemType: PenaltyTableTypeOfRow) => {
  const { REGULATION, VERSION } = PenaltyTableTypeOfRow

  if (itemType === REGULATION) {
    return t`Regulation details`
  } else if (itemType === VERSION) {
    return t`Version details`
  } else return t`Rule set details`
}

export const UI_DIFFERENT_BANDS_KEYS = 'uiDifferentBands'
export const usePenaltyRegulationTableItems = () => {
  const userShowInActivePenaltiesResults = useUserSetting<Record<string, boolean>>(KEY_SHOW_IN_ACTIVE_PENALTIES)
  const showInActive = userShowInActivePenaltiesResults?.data?.value || false
  const userTimezone = useSelector(getUserTimezoneSelector)
  const regulationResult = usePenaltyRegulations()
  const regulations: PenaltyRegulationNew[] = useMemo(() => {
    const sortedRegulations = (regulationResult?.data || []).sort((a, b) => {
      const valueA = a.regulationName.toUpperCase()
      const valueB = b.regulationName.toUpperCase()
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    })
    return sortedRegulations as PenaltyRegulationNew[]
  }, [regulationResult])

  const tableItems: PenaltyRegulationTableItemNew[] = []

  if (regulations && regulations.length) {
    // REGULATION: Loop through Regulations
    regulations?.forEach((regulationObj: PenaltyRegulationNew) => {
      // Versions sort by active since date to show most recent ones at the top
      const regulationVersions = (regulationObj?.regulationVersions || [])
        .filter((version) => version.deleted == null && version.updated == null)
        .sort((a, b) => (isBefore(new Date(a.activeSince), new Date(b.activeSince)) ? 1 : -1))

      const regulationVersionsIds = regulationVersions?.map((rv) => rv.uuid)

      const regulationTableItem: RegulationTableItem = {
        id: regulationObj?.uuid,
        name: regulationObj?.regulationName,
        typeOfRow: PenaltyTableTypeOfRow.REGULATION,
        uiAncestors: [],
        uiDescendants: regulationVersionsIds,
        uiParents: [],
        uiChildren: regulationVersionsIds,
        uiLevel: 0,
        effectiveNow: true, // Regulations are always effective
        ...regulationObj,
      }

      // Push regulation
      tableItems.push(regulationTableItem)

      // TODO effectiveNow should be populated in backend but it is not currently
      //  we can compute it here: versions that are in the future are effective, as well as the latest from the past
      const now = new Date()
      regulationVersions.forEach((version) => {
        // all future regulations are "effective now"
        version.effectiveNow = isAfter(new Date(version.activeSince), now)
      })

      const pastVersions = regulationVersions.filter((version) => isBefore(new Date(version.activeSince), now))
      if (pastVersions.length > 0) {
        regulationVersions.forEach((version) => {
          // since we sorted regulation versions already by active since date
          // we can be sure that the first past version is the most recent one
          // and should be effective now
          if (version.uuid === pastVersions[0].uuid) {
            version.effectiveNow = true
          }
        })
      }

      // VERSION: Loop through regulation versions
      regulationVersions.forEach((versionObj) => {
        const versionRuleSets = (versionObj?.penaltyRegulationRuleSets || []).filter(
          (rs) => rs.deleted == null && rs.updated == null,
        )
        const versionRuleSetsIds = versionRuleSets?.map((vrs) => vrs.uuid)
        // Convert UTC to local date because when making API call this date is automatically converts
        const modifiedActiveSinceDate = userTimezone
          ? convertUtcToZonedTime(versionObj.activeSinceTZ.date, userTimezone)
          : versionObj.activeSinceTZ.date

        // Create version reTable item
        const versionTableItem: VersionTableItem = {
          ...versionObj,
          id: versionObj.uuid,
          activeSinceTZ: {
            date: modifiedActiveSinceDate,
            timezone: versionObj.activeSinceTZ.timezone,
          },
          penaltyRegulationRuleSets: versionRuleSets,
          regulationId: regulationObj.uuid,
          name: versionObj?.regulationVersionName,
          typeOfRow: PenaltyTableTypeOfRow.VERSION,
          shared: regulationObj.shared,
          uiAncestors: [regulationObj?.uuid],
          uiDescendants: versionRuleSetsIds,
          uiParents: [regulationObj?.uuid], // Add regulation as a parent
          uiChildren: versionRuleSetsIds,
          uiLevel: 1,
        }

        // Push version
        tableItems.push(versionTableItem)

        // RULESET: Loop through version rule sets
        versionRuleSets?.forEach((ruleSet, index) => {
          // Add deviation as KPI for all bands
          const transformedOverInjectionPenaltyBands = (ruleSet?.overInjectionPenaltyBands || []).map((band) => {
            return {
              ...band,
              kpi: 'deviation',
            }
          })
          const transformedUnderInjectionPenaltyBands = (ruleSet.underInjectionPenaltyBands || []).map((band) => {
            return {
              ...band,
              kpi: 'deviation',
            }
          })
          // Create ruleset reTable item
          const ruleSetTableItem: RuleSetTableItem = {
            id: ruleSet.uuid || `${regulationObj?.uuid}${versionObj?.uuid},${index}`,
            generatorTypes: ruleSet.generatorTypes,
            overInjectionPenaltyBands: transformedOverInjectionPenaltyBands,
            underInjectionPenaltyBands: transformedUnderInjectionPenaltyBands,
            deleted: ruleSet.deleted,
            updated: ruleSet.updated,
            created: ruleSet.created,
            versionId: versionObj?.uuid,
            regulationId: regulationObj?.uuid,
            shared: regulationObj.shared,
            name: versionObj?.regulationVersionName,
            typeOfRow: PenaltyTableTypeOfRow.RULESET,
            [UI_DIFFERENT_BANDS_KEYS]:
              ruleSet.overInjectionPenaltyBands.length && ruleSet.underInjectionPenaltyBands.length,
            uiAncestors: [regulationObj?.uuid, versionObj.uuid],
            uiDescendants: [],
            uiParents: [versionObj?.uuid], // Add version as a parent
            uiChildren: [],
            uiLevel: 3,
            effectiveNow: versionObj.effectiveNow,
            uuid: ruleSet.uuid,
          }

          // Add ruleset as a descendant to its ancestor which is the regulation
          const regulationIndex = tableItems.findIndex((regulation) => regulationObj?.uuid === regulation?.id)
          if (regulationIndex >= 0) {
            tableItems[regulationIndex]?.uiDescendants?.push(ruleSet?.uuid)
          }
          // Push ruleSet
          tableItems.push(ruleSetTableItem)
        })
      })
    })
  }

  if (showInActive) {
    return tableItems
  } else {
    return tableItems.filter((item) => item.effectiveNow)
  }
}

export const preparePenaltyFormDataToSubmit = (
  data: PenaltyRegulationTableItemNew,
  userTimezone: Timezone,
): PenaltyFormData => {
  const reTableItemKeys: (keyof ReTableItem)[] = ['uiAncestors', 'uiDescendants', 'uiParents', 'uiChildren', 'uiLevel']
  // Delete all keys which are related to reTable
  const transformedData = { ...data }

  if (data.typeOfRow === PenaltyTableTypeOfRow.VERSION && transformedData?.activeSinceTZ?.date) {
    transformedData.activeSinceTZ.date = convertZonedTimeToUtc(transformedData.activeSinceTZ.date, userTimezone)
  }

  reTableItemKeys.forEach((key) => {
    delete transformedData[key]
  })
  if (!transformedData[UI_DIFFERENT_BANDS_KEYS]) {
    transformedData.underInjectionPenaltyBands = []
  }
  return transformedData as PenaltyFormData
}

export const getNewRuleSetData = (regulationId: string, versionId: string) => {
  return {
    generatorTypes: [],
    id: 'new',
    overInjectionPenaltyBands: [getNewPenaltyBandData(-1, null, true)],
    typeOfRow: PenaltyTableTypeOfRow.RULESET,
    underInjectionPenaltyBands: [],
    [UI_DIFFERENT_BANDS_KEYS]: false,
    regulationId: regulationId,
    versionId: versionId,
  }
}

export const getNewVersionData = (regulationId: string, userTimezone: Timezone) => {
  return {
    generatorTypes: [],
    id: 'new',
    name: '',
    regulationId: regulationId,
    activeSinceTZ: {
      date: new Date(),
      timezone: userTimezone,
    },
    typeOfRow: PenaltyTableTypeOfRow.VERSION,
  }
}

export const getNewRegulationDataNew = () => {
  return {
    name: '',
    id: 'new',
    typeOfRow: PenaltyTableTypeOfRow.REGULATION,
    country: '',
    state: '',
  }
}

interface GetNewPenaltyEntityDataProps {
  regulationId?: string | null
  versionId?: string | null
  formDetailsType: PenaltyTableTypeOfRow
  userTimezone: Timezone
}

export const getNewPenaltyEntityData = ({
  regulationId,
  versionId,
  formDetailsType,
  userTimezone,
}: GetNewPenaltyEntityDataProps) => {
  if (formDetailsType === PenaltyTableTypeOfRow.RULESET && regulationId && versionId) {
    return getNewRuleSetData(regulationId, versionId)
  } else if (formDetailsType === PenaltyTableTypeOfRow.VERSION && regulationId) {
    return getNewVersionData(regulationId, userTimezone)
  } else if (formDetailsType === PenaltyTableTypeOfRow.REGULATION) {
    return getNewRegulationDataNew()
  }
}

/**
 * Check if other ruleset or the current ruleset has 'unspecified' generator and r
 * */
interface CheckForUnspecifiedGeneratorsProps {
  prevData: PenaltyRegulationTableItemNew | undefined
  ruleSetFormData: PenaltyRegulationTableItemNew
  penaltyItems: PenaltyRegulationTableItemNew[]
}

export const checkForUnspecifiedGenerators = ({
  prevData,
  ruleSetFormData,
  penaltyItems,
}: CheckForUnspecifiedGeneratorsProps) => {
  const { UNSPECIFIED } = PenaltyGeneratorType
  const version = penaltyItems?.find((penalty) => penalty.id === prevData?.versionId)
  const versionRuleSets = (version?.penaltyRegulationRuleSets || []).filter(
    (ruleSet: PenaltyRegulationRuleSet) => ruleSet.deleted == null && ruleSet.updated == null,
  ) as PenaltyRegulationRuleSet[]
  const otherRuleSets = versionRuleSets.filter((ruleSet) => ruleSet.uuid !== ruleSetFormData.id)

  const formHasUnSpecified = ruleSetFormData?.generatorTypes.includes(UNSPECIFIED)
  if (formHasUnSpecified && otherRuleSets.length) {
    return c('Penalties:')
      .t`Cannot add a Ruleset with Unspecified generator type to this version because it already has a ruleset with other generator type.`
  } else {
    const otherRuleSetHasUnspecified = (otherRuleSets || []).some((ruleSet) =>
      ruleSet.generatorTypes.includes(PenaltyGeneratorType.UNSPECIFIED),
    )
    if (otherRuleSetHasUnspecified) {
      return getPenaltyCreationErrorMessage
    }
  }
  return ''
}

interface CheckDetailsHaveChangedProps {
  prevData: PenaltyRegulationTableItemNew | undefined
  updatedData: PenaltyFormData
}

export const checkDetailsHaveChanged = ({ prevData, updatedData }: CheckDetailsHaveChangedProps) => {
  // Do not check for new entities
  if (prevData.id === 'new') {
    return false
  }
  if (updatedData.typeOfRow === PenaltyTableTypeOfRow.REGULATION) {
    const fields: (keyof PenaltyRegulationNew)[] = ['gridType', 'shapeId', 'country', 'state', 'shared']
    if (prevData && updatedData) {
      const detailsChanged = fields.some((field) => prevData[`${field}`] != updatedData[`${field}`])
      return detailsChanged
    }
  } else if (updatedData.typeOfRow === PenaltyTableTypeOfRow.VERSION) {
    const updatedDataActiveSince = new Date(updatedData?.activeSinceTZ.date)
    const prevDataActiveSince = new Date(prevData?.activeSinceTZ.date)
    return !isSameDate(updatedDataActiveSince, prevDataActiveSince)
  } else if (updatedData.typeOfRow === PenaltyTableTypeOfRow.RULESET) {
    // Every change can effect the penalties calculations
    return true
  }
  return false
}
