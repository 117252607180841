import { isBefore, parseISO } from 'date-fns'
import { ERROR, WARN } from 'fixtures/plausability'
import { QualityOverviewItem } from 'modules/quality/quality.types'
import {
  CellRenderType,
  Columns,
  ColumnSortType,
  Filter,
  ReTableContextMenuItem,
  ReTableItem,
  ReTableRowContextActions,
  ReTableSortData,
  Sort,
} from 'modules/reTable/reTable.types'
import { VirtualRange } from 'ui/scroll/VirtualScroll'
import { includesCaseInsensitive } from 'utils/array'
import { getCoords, getNomcap, isGenerator, isParkWithNoPlants } from 'utils/asset'
import { isNumeric } from 'utils/dataFormatting'
import { MAX_DATE, MIN_DATE } from 'utils/date'
import { parseFileUploadStatus } from 'utils/file'
import { getPrimaryQualityValue } from 'utils/quality'
import { t } from 'ttag'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const editableRowActionsCellWidth = '4em'

export enum ReTableGenericAttributes {
  ITEMS_PADDING_HEADER = 1,
  ITEMS_PADDING_FOOTER = 0,
  RENDER_AHEAD_COUNT = 25,
}

export const filterBySearch = (item: ReTableItem, search: string, columns: Columns) => {
  return columns
    .filter((column) => column.searchable)
    .reduce((result, column) => {
      let text = ''
      let found = false
      switch (column.columnSortType) {
        case ColumnSortType.FIELD:
          text = item[column.name] ? item[column.name].toString() : ''
          found = includesCaseInsensitive(text, search)
          break
        // case ColumnData.COORDS:
        //   const coords = getCoords(item)
        //   const lat = limitToFourDecimals(coords.latitude).toString()
        //   const lng = limitToFourDecimals(coords.longitude).toString()
        //   found = includesCaseInsensitive(lat, search) || includesCaseInsensitive(lng, search)
        //   break
        case ColumnSortType.NOMCAP:
          text = getNomcap(item).toString()
          found = includesCaseInsensitive(text, search)
          break
        case ColumnSortType.VALUE_WITH_UNIT:
          text = item.uiValueUnit ? item.uiValueUnit.toString() : ''
          found = includesCaseInsensitive(text, search)
          break
      }
      if (column.cellRenderType === CellRenderType.DATE) {
        text = item[column.name] ? new Date(item[column.name]).toString() : ''
        found = includesCaseInsensitive(text, search)
      }
      return result || found
    }, false)
}

export const filterByField = (item: ReTableItem, filter: Filter) => {
  const filterValue = filter.value || []
  return filterValue.length === 0 ? true : filterValue.includes(item.type)
}

export interface ItemMatchesProps {
  item: ReTableItem
  columns: Columns
  collapsed: string[]
  filters: Record<string, Filter>
  search: string
  sort: Sort
}
export const itemMatches = ({ item, columns, collapsed, filters, search, sort }: ItemMatchesProps): boolean => {
  // search, filter and expanded matches
  const hasSort = sort.active
  const hasSearch = Boolean(search)
  const hasFilter = Boolean(filters && filters.type)
  const isSearchMatch = hasSearch ? filterBySearch(item, search, columns) : hasFilter
  const isFilterMatch = hasFilter ? filterByField(item, filters.type) : hasSearch
  const isExpanded =
    hasSearch || hasFilter
      ? false
      : hasSort || typeof item.uiAncestors === 'undefined'
      ? true
      : !item.uiAncestors.some((ancestor) => collapsed.includes(ancestor))
  return hasSearch || hasFilter ? isSearchMatch && isFilterMatch : isExpanded
}

export interface ReTableFilterProps {
  items: ReTableItem[]
  columns: Columns
  collapsed: string[]
  collapsedSearch: string[]
  filters: Record<string, Filter>
  search: string
  selected: string[]
  sort: Sort
}
export const reTableFilter = ({
  items,
  columns,
  collapsed,
  collapsedSearch,
  filters,
  search,
  selected,
  sort,
}: ReTableFilterProps): ReTableItem[] => {
  const collapsedActive = sort.active ? [] : search ? collapsedSearch : collapsed
  const collapsedWithoutSelected = collapsedActive.filter((collapsedId) => {
    return selected.every((selectedId) => {
      const selectedItem = items.find((item) => item.id === selectedId)
      const ancestors = selectedItem?.uiAncestors || []
      return !ancestors.includes(collapsedId)
    })
  })

  const matchingIds = (items || [])
    .filter((item) =>
      itemMatches({
        item,
        columns,
        collapsed: collapsedWithoutSelected,
        filters,
        search,
        sort,
      }),
    )
    .map((item) => item.id)

  const filteredItems: ReTableItem[] = []

  ;(items || []).forEach((item: ReTableItem) => {
    let itemMatches = false
    const filteredItem = { ...item }
    const descendants = filteredItem.uiDescendants || []
    const parents = filteredItem.uiParents || []
    const ancestors = filteredItem.uiAncestors || []

    const hasMatchingDescendants = Boolean(
      descendants.length && descendants.some((descendant) => matchingIds.includes(descendant)),
    )
    const hasMatchingParents = Boolean(parents.length && parents.some((parent) => matchingIds.includes(parent)))
    const hasMatchingAncestors = Boolean(
      ancestors.length && ancestors.some((ancestor) => matchingIds.includes(ancestor)),
    )

    filteredItem.uiIsMatching = matchingIds.includes(filteredItem.id)
    filteredItem.uiHasMatchingDescendants = hasMatchingDescendants
    filteredItem.uiHasMatchingParents = hasMatchingParents

    if (filteredItem.uiIsMatching) {
      // show direct hits, but not if one ancestor is collapsed
      itemMatches = !ancestors.length || ancestors.every((ancestor) => !collapsedActive.includes(ancestor))
    } else if (search && !sort.active) {
      if (Array.isArray(filteredItem.uiDescendants)) {
        // when search is active and tree hierarchy visible, then show each node that has matching descendants or parents
        itemMatches =
          (hasMatchingDescendants || hasMatchingAncestors) &&
          ancestors.every((parent) => !collapsedActive.includes(parent))
      }
    }

    if (itemMatches) {
      filteredItems.push(filteredItem)
    }
  })

  return filteredItems
}

interface ReTableSortProps {
  items: ReTableItem[]
  columns: Columns
  sort: Sort
  data: ReTableSortData
}
export const reTableSort = ({ items, columns, sort, data }: ReTableSortProps): ReTableItem[] => {
  const sortedItems = [...items]
  if (sort.active && sort.column) {
    const parts = sort.column ? sort.column.split('.') : ['']
    const column = columns.find((c) => {
      return c.name === parts.slice(0, Math.max(1, parts.length - 1)).join('.')
    })
    if (!column) return sortedItems

    // sort order and default values for empty columns
    const first = sort.ascending ? -1 : 1
    const last = sort.ascending ? 1 : -1
    const emptyInteger = sort.ascending ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER
    const emptyDate = sort.ascending ? MAX_DATE : MIN_DATE

    sortedItems.sort((a, b) => {
      let valueA
      let valueB
      let secondaryA
      let secondaryB
      switch (column.columnSortType) {
        case ColumnSortType.FIELD:
        case ColumnSortType.VALUE_WITH_UNIT:
          valueA = a[column.name]
          valueB = b[column.name]
          break

        case ColumnSortType.FIELD_GENERATOR:
          const hasFieldA = isGenerator(a) || isParkWithNoPlants(a)
          const hasFieldB = isGenerator(b) || isParkWithNoPlants(b)
          valueA = hasFieldA ? a[column.name] : null
          valueB = hasFieldB ? b[column.name] : null
          break

        case ColumnSortType.NOMCAP:
          valueA = getNomcap(a)
          valueB = getNomcap(b)
          break

        case ColumnSortType.COUNTRY_CODE:
          valueA = data.countryCodes[a.id]
          valueB = data.countryCodes[b.id]
          break

        case ColumnSortType.COORDS:
          valueA = getCoords(a)[column.name] || emptyInteger
          valueB = getCoords(b)[column.name] || emptyInteger
          break

        case ColumnSortType.PLAUSIBILITY:
          const errorsA = (data.plausibilityData[a.id]?.results || []).filter((a) => a.status === ERROR).length
          const errorsB = (data.plausibilityData[b.id]?.results || []).filter((b) => b.status === ERROR).length
          const warnsA = (data.plausibilityData[a.id]?.results || []).filter((a) => a.status === WARN).length
          const warnsB = (data.plausibilityData[b.id]?.results || []).filter((b) => b.status === WARN).length

          valueA = sort.ascending ? warnsA : errorsA
          valueB = sort.ascending ? warnsB : errorsB
          secondaryA = sort.ascending ? errorsA : warnsA
          secondaryB = sort.ascending ? errorsB : warnsB
          break

        case ColumnSortType.SITE_FORECAST:
          valueA = data?.siteForecastNames[a.id] || null
          valueB = data?.siteForecastNames[b.id] || null
          break

        case ColumnSortType.METERDATA:
          valueA = (data.meterdataSummaries[a.id] || {}).availabilityDays || emptyInteger
          valueB = (data.meterdataSummaries[b.id] || {}).availabilityDays || emptyInteger
          break

        case ColumnSortType.PENALTY_ACCURACY:
          const AccuracyValueA = data.assetPenaltyAccuracy?.[a.id]
          const AccuracyValueB = data.assetPenaltyAccuracy?.[b.id]
          valueA = isNumeric(AccuracyValueA) ? AccuracyValueA : null
          valueB = isNumeric(AccuracyValueB) ? AccuracyValueB : null
          break

        case ColumnSortType.ASSET_OPERATIONAL:
          valueA = data.assetOperationalData?.[a.id]
          valueB = data.assetOperationalData?.[b.id]
          break

        case ColumnSortType.ASSET_SPECIFIC_FILTER_SETUP:
          valueA = data.assetFilterSetup?.[a.id].specificFilterSetings
          valueB = data.assetFilterSetup?.[b.id].specificFilterSetings
          break

        case ColumnSortType.LAST_SET_NETWORK:
          valueA = data.lastSetNetwork?.[a.id] || emptyDate
          valueB = data.lastSetNetwork?.[b.id] || emptyDate
          break

        case ColumnSortType.QUALITY_EVALUATION:
          const forecastConfigId = parts[1]
          const overviewType = parts[2]

          const overviewA: QualityOverviewItem = ((data.qualityOverview || {})[a.id] || {})[forecastConfigId] || {}
          const overviewB: QualityOverviewItem = ((data.qualityOverview || {})[b.id] || {})[forecastConfigId] || {}

          const qualityValueA = getPrimaryQualityValue(overviewA[overviewType])
          const qualityValueB = getPrimaryQualityValue(overviewB[overviewType])
          valueA = isNumeric(qualityValueA) ? qualityValueA : emptyInteger
          valueB = isNumeric(qualityValueB) ? qualityValueB : emptyInteger
          break

        case ColumnSortType.FILE_UPLOAD_STATUS:
          const aStatus = parseFileUploadStatus(a)
          const bStatus = parseFileUploadStatus(b)
          const aIndex = aStatus.type === 'success' ? 0 : aStatus.type === 'progress' ? 1 : 2
          const bIndex = bStatus.type === 'success' ? 0 : bStatus.type === 'progress' ? 1 : 2
          valueA = aIndex.toString()
          valueB = bIndex.toString()
          break
      }

      switch (column.cellRenderType) {
        case CellRenderType.TEXT:
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.toLowerCase() < valueB.toLowerCase() ? first : last
          } else if (typeof valueA === 'string') {
            return -1
          } else if (typeof valueB === 'string') {
            return 1
          } else {
            return 0
          }

        case CellRenderType.NUMERIC:
        case CellRenderType.CUSTOM:
        case CellRenderType.CUSTOM_NUMERIC:
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.toLowerCase() < valueB.toLowerCase() ? first : last
          } else if (isNumeric(valueA) && isNumeric(valueB)) {
            return valueA < valueB ? first : last
          } else if (isNumeric(valueA)) {
            return -1
          } else if (isNumeric(valueB)) {
            return 1
          } else {
            return 0
          }

        case CellRenderType.TUPLE:
          if (valueA || valueB) {
            return (valueA || emptyInteger) < (valueB || emptyInteger) ? first : last
          } else if (secondaryA || secondaryB) {
            return (secondaryA || emptyInteger) < (secondaryB || emptyInteger) ? first : last
          } else {
            return 0
          }

        case CellRenderType.DATE:
          const dateA = valueA ? (typeof valueA === 'string' ? parseISO(valueA) : new Date(valueA)) : emptyDate
          const dateB = valueB ? (typeof valueB === 'string' ? parseISO(valueB) : new Date(valueB)) : emptyDate

          if (dateA && dateB) {
            return isBefore(dateA, dateB) ? first : last
          } else if (dateA) {
            return -1
          } else if (dateB) {
            return 1
          } else {
            return 0
          }

        case CellRenderType.BOOLEAN:
          if (typeof valueA === 'boolean' || typeof valueB === 'boolean') {
            const boolA = valueA || emptyInteger
            const boolB = valueB || emptyInteger
            const difference = Number(boolA) - Number(boolB)
            return difference * last
          }

          if (isNumeric(valueA) && isNumeric(valueB)) {
            return valueA < valueB ? first : last
          } else if (isNumeric(valueA)) {
            return -1
          } else if (isNumeric(valueB)) {
            return 1
          } else {
            return 0
          }

        default:
          return 0
      }
    })
  }

  return sortedItems
}

interface ReTableCropProps {
  items: ReTableItem[]
  virtualRange: VirtualRange
}
export const reTableCrop = ({ items, virtualRange }: ReTableCropProps): ReTableItem[] => {
  return items.slice(virtualRange.start, virtualRange.end)
}

export const getTableRowContextMenuActions: (withAddAction?: boolean) => ReTableContextMenuItem[] = (withAddAction) => {
  const actions = [
    {
      itemName: ReTableRowContextActions.EDIT_ROW,
      itemLabel: t`Edit`,
      icon: 'pen',
    },
    {
      itemName: ReTableRowContextActions.DELETE_ROW,
      itemLabel: t`Delete`,
      icon: 'trash-alt',
    },
  ]

  if (withAddAction) {
    actions.unshift({
      itemName: ReTableRowContextActions.ADD_ROW,
      itemLabel: t`Add`,
      icon: 'plus',
    })
  }

  return actions
}

export const getSortIcon: (cellRenderType: CellRenderType, sort: Sort) => IconProp = (cellRenderType, sort) => {
  switch (cellRenderType) {
    case CellRenderType.TEXT:
      return sort.ascending ? 'sort-alpha-up' : 'sort-alpha-down-alt'
    case CellRenderType.NUMERIC:
    case CellRenderType.CUSTOM_NUMERIC:
    case CellRenderType.CUSTOM:
    case CellRenderType.DATE:
      return sort.ascending ? 'sort-numeric-up' : 'sort-numeric-down-alt'
    case CellRenderType.TUPLE:
    case CellRenderType.BOOLEAN:
      return sort.ascending ? 'sort-amount-down' : 'sort-amount-up'
    default:
      return 'sort'
  }
}
