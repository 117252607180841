import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import SectionTimingPreview from 'modules/dataStreams/siteForecast/SectionTimingPreview'
import { Box, Typography } from '@material-ui/core'
import { c, msgid, t } from 'ttag'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import {
  transformSiteForecastDataForForm,
  getExportFormatConfigurationText,
  getAddSiteForecastFromTableSaveMenuItems,
  getSiteForecastFrequencyTypeText,
  getSiteForecastHorizonTypeText,
  createNameSuggestion,
} from 'utils/siteForecastConfiguration'
import {
  ForecastConfig,
  ForecastConfigurationCategory,
  ForecastSaveMainItem,
} from 'modules/dataStreams/dataStreams.types'
import DataStreamPreviewContainer from 'modules/dataStreams/DataStreamPreviewContainer'
import { getNewSiteForecastQueryObj, QUERY_NEW_SCHEDULE, useQueryParams, useQueryString } from 'utils/query-string'
import {
  useSiteForecastConfigs,
  useSiteForecastConfigSaveMutation,
} from 'modules/dataStreams/api/siteForecastConfigs.api'
import {
  useSiteForecastSaveMainItemKey,
  useSiteForecastSaveMainItemKeySaveMutation,
} from 'modules/dataStreams/api/dataStreamsUserSettings.api'
import { FormSaveOptions } from 'utils/form'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import ActionButton from 'ui/form/ActionButton'
import {
  QUERY_KEY_DELIVERY_FORMATS,
  saveDeliveryFormat,
} from 'modules/delivery/deliveryFormats/api/deliveryFormats.api'
import {
  QUERY_KEY_DELIVERY_FORMAT_TEMPLATES,
  useDeliveryFormatTemplates,
} from 'modules/delivery/deliveryFormats/api/deliveryFormatTemplates.api'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { createSiteForecastSteps } from 'modules/app/tour/appTourSteps'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { AppTours, TourStepCategory } from 'modules/app/tour/appTour.types'
import { AddTourStepsTime } from 'modules/app/tour/appTourUtils'
import { useQueryClient } from 'react-query'

interface SiteForecastPreviewProps {
  siteForecast: ForecastConfig | null
}

const SiteForecastPreview: React.FC<SiteForecastPreviewProps> = ({ siteForecast }) => {
  const { addTourSteps, removeTourSteps, isTourActive } = useContext(AppTourContext)

  const { queryParams } = useQueryParams()
  const { onUpdateQueryString } = useQueryString()
  const deliveryFormatTemplates = useDeliveryFormatTemplates()
  const user = useSelector(getUserResultSelector)
  const siteForecastConfigs = useSiteForecastConfigs()
  const queryClient = useQueryClient()

  const isNewSchedule = useMemo(() => Boolean(queryParams[QUERY_NEW_SCHEDULE]), [queryParams])

  const saveForecastConfigResult = useSiteForecastConfigSaveMutation(false, null, false)
  const { mutate: saveForecastConfigMutation, isSuccess, reset: resetForecastSaveMutation } = saveForecastConfigResult

  const handleSaveForecastConfig = useCallback(
    (data: ForecastConfig) => {
      const transformedData = transformSiteForecastDataForForm(data, true, false, user, false)

      const configNames = (siteForecastConfigs.data || []).map((config) => config.name)
      if (data?.name && configNames.includes(data.name)) {
        transformedData.name = createNameSuggestion(data, configNames)
      }

      const usedDeliveryFormatTemplate = (deliveryFormatTemplates.data || []).find(
        (template) => template.id === transformedData.exportFormatConfigId,
      )
      const needsCreationOfNewDeliveryFormat = usedDeliveryFormatTemplate && !usedDeliveryFormatTemplate.customer

      // This part handles schedule creation from Use template directly
      if (isNewSchedule) {
        transformedData.category = ForecastConfigurationCategory.SITE_SCHEDULE
      }

      if (needsCreationOfNewDeliveryFormat && usedDeliveryFormatTemplate) {
        usedDeliveryFormatTemplate.id = null
        saveDeliveryFormat(usedDeliveryFormatTemplate).then((template) => {
          queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMATS)
          queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMAT_TEMPLATES)
          transformedData.exportFormatConfigId = template.id
          saveForecastConfigMutation(transformedData)
        })
      } else {
        saveForecastConfigMutation(transformedData)
      }
    },
    [deliveryFormatTemplates.data, user, isNewSchedule],
  )

  // Save menu item code
  const saveMainItemKey = useSiteForecastSaveMainItemKey()
  const savedMainItem = useMemo(() => saveMainItemKey.data?.createForecastFromTable || FormSaveOptions.SAVE, [
    saveMainItemKey?.data,
  ])

  const {
    mutate: saveMainMutation,
    isLoading: savingMenuItemLoading,
    isSuccess: saveMenuItemSuccess,
    reset,
  } = useSiteForecastSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(() => getAddSiteForecastFromTableSaveMenuItems(), [])

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE, SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: ForecastSaveMainItem = {
          updateForecast: saveMainItemKey?.data?.updateForecast || SAVE_AND_CLOSE,
          createForecast: saveMainItemKey?.data?.createForecast || SAVE_AND_CLOSE,
          createForecastFromTable: item.key as FormSaveOptions,
        }
        saveMainMutation(data)
      }
      if (siteForecast) {
        if (item.key === SAVE) {
          handleSaveForecastConfig(siteForecast)
        } else if (siteForecast && siteForecast?.id) {
          onUpdateQueryString(getNewSiteForecastQueryObj(siteForecast.id))
        }
      }
    },
    [savedMainItem, saveMainItemKey, siteForecast, onUpdateQueryString, handleSaveForecastConfig],
  )

  useEffect(() => {
    if (saveMenuItemSuccess) {
      reset()
    }
  }, [saveMenuItemSuccess])

  // react elements
  const preview = useMemo(() => {
    if (siteForecast) {
      const evaluationLengthInDays = siteForecast.horizon.untilEndOfDay ? siteForecast.horizon.lengthHours / 24 : 0
      const horizonTypeText = getSiteForecastHorizonTypeText(siteForecast.horizon.type)

      const exportFormatConfigurationText = siteForecast.exportFormatConfiguration
        ? getExportFormatConfigurationText(siteForecast.exportFormatConfiguration)
        : ''
      const deliveryFrequencyText =
        siteForecast.updateTimes.offsetMinutes != 0
          ? c('Site Forecast:Preview').t` (${siteForecast.updateTimes.offsetMinutes} min offset)`
          : ''
      return (
        <>
          <SectionTimingPreview forecastDetails={siteForecast} />
          <Typography variant="subtitle2">{c('Site Forecast:Preview').t`Details`}</Typography>

          <DetailsKeyValueGrid>
            <DetailsKey>{c('Site Forecast:Preview').t`Name:`}</DetailsKey>
            <DetailsValue>{siteForecast.name}</DetailsValue>
          </DetailsKeyValueGrid>

          <DetailsKeyValueGrid>
            <DetailsKey>{c('Site Forecast:Preview').t`Length of forecast:`}</DetailsKey>
            <DetailsValue>
              {siteForecast.horizon.untilEndOfDay
                ? c('Site Forecast:Preview').ngettext(
                    msgid`${evaluationLengthInDays} day, until end of the day`,
                    `${evaluationLengthInDays} days, until end of the day`,
                    evaluationLengthInDays,
                  )
                : c('Site Forecast:Preview').ngettext(
                    msgid`${siteForecast.horizon.lengthHours} hour`,
                    `${siteForecast.horizon.lengthHours} hours`,
                    siteForecast.horizon.lengthHours,
                  )}
            </DetailsValue>
          </DetailsKeyValueGrid>

          <DetailsKeyValueGrid>
            <DetailsKey>{c('Site Forecast:Preview').t`Start of forecast:`}</DetailsKey>
            <DetailsValue>
              {siteForecast.horizon.offsetDays != 0 || siteForecast.horizon.offsetHours != 0 ? (
                <>
                  {c('Site Forecast:Preview').ngettext(
                    msgid`${siteForecast.horizon.offsetDays} day`,
                    `${siteForecast.horizon.offsetDays} days`,
                    siteForecast.horizon.offsetDays,
                  )}
                  {', '}
                  {c('Site Forecast:Preview').ngettext(
                    msgid`${siteForecast.horizon.offsetHours} hour`,
                    `${siteForecast.horizon.offsetHours} hours`,
                    siteForecast.horizon.offsetHours,
                  )}
                  {', '}
                  {c('Site Forecast:Preview').ngettext(
                    msgid`${siteForecast.horizon.offsetMinutes} minute`,
                    `${siteForecast.horizon.offsetMinutes} minutes`,
                    siteForecast.horizon.offsetMinutes,
                  )}
                </>
              ) : (
                <>{horizonTypeText}</>
              )}
            </DetailsValue>
          </DetailsKeyValueGrid>

          {(siteForecast.horizon.offsetDays != 0 || siteForecast.horizon.offsetHours != 0) && (
            <DetailsKeyValueGrid>
              <DetailsKey>{c('Site Forecast:Preview').t`Start calculated from:`}</DetailsKey>
              <DetailsValue>
                <>{horizonTypeText}</>
              </DetailsValue>
            </DetailsKeyValueGrid>
          )}

          <DetailsKeyValueGrid>
            <DetailsKey>{c('Site Forecast:Preview').t`Delivery frequency:`}</DetailsKey>
            <DetailsValue>
              {getSiteForecastFrequencyTypeText(siteForecast.updateTimes.frequency)}
              {deliveryFrequencyText}
            </DetailsValue>
          </DetailsKeyValueGrid>

          {siteForecast.exportFormatConfiguration && (
            <DetailsKeyValueGrid>
              <DetailsKey>{c('Site Forecast:Preview').t`Delivery format:`}</DetailsKey>
              <DetailsValue>{exportFormatConfigurationText}</DetailsValue>
            </DetailsKeyValueGrid>
          )}
        </>
      )
    } else return <></>
  }, [siteForecast])

  const forecastSaveButton = useMemo(() => {
    return (
      <ActionButton
        className="appTour-createSiteForecast-saveOrEdit"
        result={saveForecastConfigResult}
        formInvalid={false}
        formTouched={true}
        formModifiedSinceLastSubmit={false}
        disabled={!siteForecast || isSuccess || savingMenuItemLoading || saveMainItemKey.isLoading}
        menuItems={saveMenuItems}
        mainItemKey={savedMainItem}
        onSelectMenuItem={handleSaveMenuSelect}
        icon="save"
        variant="contained"
        color="primary"
        size="small"
        saveOptionLoading={savingMenuItemLoading}
      >
        {t`Save`}
      </ActionButton>
    )
  }, [
    siteForecast,
    saveForecastConfigResult,
    isSuccess,
    savingMenuItemLoading,
    savedMainItem,
    saveMainItemKey.isLoading,
    saveMenuItems,
    handleSaveMenuSelect,
  ])

  const getTemplateTypeName = useMemo(() => {
    if (isNewSchedule) {
      return t`schedule`
    }
    return t`site forecast`
  }, [isNewSchedule])

  useEffect(() => {
    resetForecastSaveMutation()
  }, [siteForecast?.id])

  const handleCloseTour = useCallback(() => {
    if (typeof removeTourSteps === 'function' && isTourActive) removeTourSteps(AppTours.ADD_SITE_FORECAST)
  }, [removeTourSteps, isTourActive])

  useEffect(() => {
    const addSteps = siteForecastConfigs?.data?.length == 1 && typeof addTourSteps === 'function' && isTourActive
    if (!addSteps) return
    const steps = createSiteForecastSteps().filter((item) => item.stepCategory === TourStepCategory.TABLE_OR_PAGE)
    const addStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (addStepsTimer) clearTimeout(addStepsTimer)
    }
  }, [siteForecastConfigs?.data, addTourSteps, isTourActive])

  useEffect(() => {
    if (isSuccess) {
      handleCloseTour()
    }
  }, [isSuccess, handleCloseTour])

  useEffect(() => {
    return () => {
      handleCloseTour()
    }
  }, [])

  return (
    <DataStreamPreviewContainer
      isDataStreamSelected={Boolean(siteForecast)}
      previewHeader={c('Site Forecast:Preview').t`Forecast configuration overview`}
      emptyBoxText={c('Site Forecast:Preview')
        .jt`Choose a template for the new ${getTemplateTypeName}. You can use the template as it is, or customize it to your individual needs.`}
      useBtnText={c('Site Forecast:Preview').t`Use this template`}
      customActionButton={forecastSaveButton}
    >
      <Box m={2}>{preview}</Box>
    </DataStreamPreviewContainer>
  )
}

export default React.memo(SiteForecastPreview)
