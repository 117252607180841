import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Typography } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { c, t } from 'ttag'

import { SET_AUTODEPLOY_REQUEST } from 'modules/asset/store/setAutoDeploy.state'
import {
  setStartTrainingErrorSelector,
  setStartTrainingLoadingSelector,
  setStartTrainingSuccessSelector,
  START_TRAINING_REQUEST,
  START_TRAINING_RESET,
} from 'modules/asset/store/startTraining.state'
import {
  ACTIVATE_MODELS_REQUEST,
  ACTIVATE_MODELS_RESET,
  activateModelsErrorSelector,
  activateModelsLoadingSelector,
  ActivateModelsMode,
  activateModelsSuccessSelector,
} from 'modules/asset/store/activateModels.state'
import { hasAnyAuthority, isAdmin, isImpersonatedAdmin } from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import ActionMenuItem from 'ui/elements/ActionMenuItem'
import { isSolarAsset, isWindAsset, useUniqueSelectedAssets } from 'utils/asset'
import { ROUTE_METER_DATA_BULK_CLEANSING } from 'modules/app/Routes'
import { useRouter } from 'utils/route'
import {
  setStartTrainingForSolarErrorSelector,
  setStartTrainingForSolarLoadingSelector,
  setStartTrainingForSolarSuccessSelector,
  START_TRAINING_REQUEST_FOR_SOLAR,
  START_TRAINING_RESET_FOR_SOLAR,
} from 'modules/asset/store/startTrainingForSolar.state'
import { Roles } from 'utils/userManagement'

interface TrainingMenuProps {
  onClose: () => void
}
const TrainingMenuContent: React.FC<TrainingMenuProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { history } = useRouter()
  const user = useSelector(getUserResultSelector)
  const selectedAssets = useUniqueSelectedAssets()

  const setStartTrainingLoading = useSelector(setStartTrainingLoadingSelector)
  const setStartTrainingError = useSelector(setStartTrainingErrorSelector)
  const setStartTrainingSuccess = useSelector(setStartTrainingSuccessSelector)

  const setStartTrainingForSolarLoading = useSelector(setStartTrainingForSolarLoadingSelector)
  const setStartTrainingForSolarError = useSelector(setStartTrainingForSolarErrorSelector)
  const setStartTrainingForSolarSuccess = useSelector(setStartTrainingForSolarSuccessSelector)

  const activateModelsLoading = useSelector(activateModelsLoadingSelector)
  const activateModelsError = useSelector(activateModelsErrorSelector)
  const activateModelsSuccess = useSelector(activateModelsSuccessSelector)

  const handleStartTraining = useCallback(() => {
    // Make api call for wind plant
    const windAssets = selectedAssets.filter((a) => isWindAsset(a))
    if (windAssets.length) {
      dispatch({ type: START_TRAINING_REQUEST, assets: windAssets })
    }
    // Make api call for solar plant
    const solarAssets = selectedAssets.filter((a) => isSolarAsset(a))
    if (solarAssets.length) {
      dispatch({ type: START_TRAINING_REQUEST_FOR_SOLAR, assets: solarAssets })
    }
  }, [onClose, selectedAssets])

  const activateModels = useCallback(
    (mode: ActivateModelsMode) => {
      dispatch({ type: ACTIVATE_MODELS_REQUEST, assets: selectedAssets, mode })
    },
    [onClose, selectedAssets],
  )

  const handleSetAutoDeploy = useCallback(
    (enabled: boolean) => {
      dispatch({ type: SET_AUTODEPLOY_REQUEST, assets: selectedAssets, enabled })
    },
    [selectedAssets],
  )

  const enableAutoDeploy = useCallback(() => {
    handleSetAutoDeploy(true)
    onClose()
  }, [onClose, handleSetAutoDeploy])

  const disableAutoDeploy = useCallback(() => {
    handleSetAutoDeploy(false)
    onClose()
  }, [onClose, handleSetAutoDeploy])

  const bulkMdcHandler = () => {
    history.push(ROUTE_METER_DATA_BULK_CLEANSING)
  }

  useEffect(() => {
    return () => {
      dispatch({ type: START_TRAINING_RESET })
      dispatch({ type: START_TRAINING_RESET_FOR_SOLAR })
      dispatch({ type: ACTIVATE_MODELS_RESET, mode: ActivateModelsMode.PREVIOUS })
      dispatch({ type: ACTIVATE_MODELS_RESET, mode: ActivateModelsMode.CANDIDATE })
    }
  }, [])

  const hasPermissionForBulkMDC = hasAnyAuthority(user, [
    Roles.METER_DATA_CLEANSING_BULK_WIND,
    Roles.METER_DATA_CLEANSING_BULK_PV,
  ])

  return (
    <>
      <Box m={2}>
        <Typography variant="subtitle2">{t`Training`}</Typography>
      </Box>
      {(hasPermissionForBulkMDC || isAdmin(user) || isImpersonatedAdmin(user)) && (
        <>
          <Divider />
          <ActionMenuItem
            icon="play-circle"
            text={c('Asset Details').t`Meter data cleansing`}
            loading={false}
            error={null}
            success={false}
            onClick={bulkMdcHandler}
            isAdminComponent={hasPermissionForBulkMDC ? false : isAdmin(user) || isImpersonatedAdmin(user)}
          />
        </>
      )}
      {(hasPermissionForBulkMDC || isAdmin(user) || isImpersonatedAdmin(user)) && (
        <>
          <Divider />
          <ActionMenuItem
            icon="play-circle"
            text={t`Start training now`}
            loading={setStartTrainingLoading || setStartTrainingForSolarLoading}
            error={setStartTrainingError || setStartTrainingForSolarError}
            success={setStartTrainingSuccess || setStartTrainingForSolarSuccess}
            onClick={handleStartTraining}
            isAdminComponent={hasPermissionForBulkMDC ? false : isAdmin(user) || isImpersonatedAdmin(user)}
          />
        </>
      )}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && (
        <>
          <Divider />
          <ActionMenuItem
            icon="arrow-left"
            text={t`Rollback to previous models`}
            loading={activateModelsLoading[ActivateModelsMode.PREVIOUS]}
            error={activateModelsError[ActivateModelsMode.PREVIOUS]}
            success={activateModelsSuccess[ActivateModelsMode.PREVIOUS]}
            onClick={() => activateModels(ActivateModelsMode.PREVIOUS)}
            isAdminComponent={isAdmin(user) || isImpersonatedAdmin(user)}
          />
          <ActionMenuItem
            icon="arrow-right"
            text={t`Activate candidate models`}
            loading={activateModelsLoading[ActivateModelsMode.CANDIDATE]}
            error={activateModelsError[ActivateModelsMode.CANDIDATE]}
            success={activateModelsSuccess[ActivateModelsMode.CANDIDATE]}
            onClick={() => activateModels(ActivateModelsMode.CANDIDATE)}
            isAdminComponent={isAdmin(user) || isImpersonatedAdmin(user)}
          />
        </>
      )}
      <Divider />
      <Box mx={2} my={1}>
        <Typography>{t`Better networks are automatically set when this is enabled`}</Typography>
      </Box>
      <MenuItem onClick={enableAutoDeploy}>
        <ListItemIcon>
          <FontAwesomeIcon icon="power-off" fixedWidth color={green[500]} />
        </ListItemIcon>
        <ListItemText primary={t`Enable`} />
      </MenuItem>
      <MenuItem onClick={disableAutoDeploy}>
        <ListItemIcon>
          <FontAwesomeIcon icon="stop-circle" fixedWidth color={red[500]} />
        </ListItemIcon>
        <ListItemText primary={t`Disable`} />
      </MenuItem>
    </>
  )
}

export default React.memo(TrainingMenuContent)
