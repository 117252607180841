import React, { useCallback, useMemo, useState } from 'react'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import Flex from 'ui/styles/Flex'
import { TDIndented } from 'ui/styles/table'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { ReTableRowContextActions, Sort } from 'modules/reTable/reTable.types'
import { convertZonedTimeToUtc, formatDate } from 'utils/date'
import {
  getPenaltyDetailsNewQueryObj,
  QUERY_PENALTY_DETAILS,
  QUERY_PENALTY_NEW_ENTITY_TYPE,
  useQueryString,
} from 'utils/query-string'
import ReTableEditableRowActionsCell from 'modules/reTable/ReTableEditableRowActionsCell'
import { getTableRowContextMenuActions } from 'modules/reTable/reTable.functionality'
import {
  PenaltyGeneratorType,
  PenaltyRegulationRuleSet,
  PenaltyRegulationTableItemNew,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { StyledReTableRow } from 'ui/elements/StyledRetableRow'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { Box } from '@material-ui/core'
import InBuiltEntityIcon from 'ui/elements/InBuiltEntityIcon'
import { getInBuiltEntityMessage, InBuiltEntities } from 'utils/workspace'
import DetailsLink from 'ui/DetailsLink'
import { t } from 'ttag'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { math } from 'polished'
import { getPenaltyEntityName, getPenaltyUpdateWarningMessage, PenaltyNameColumnWidth } from 'utils/penaltyRegulations'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'
import { Timezone } from 'fixtures/timezones'

interface PenaltyRegulationTableRowNewProps {
  item: PenaltyRegulationTableItemNew
  sort: Sort
  selected: boolean
  onDeleteRowItem: (item: PenaltyRegulationTableItemNew) => void
  onOpenDialog: () => void
}

const PenaltyRegulationTableRow: React.FC<PenaltyRegulationTableRowNewProps> = ({
  item,
  sort,
  selected,
  onDeleteRowItem,
  onOpenDialog,
}) => {
  const user = useSelector(getUserResultSelector)
  const userTimezone = user?.timezone as Timezone
  const { updateQueryString } = useQueryString()
  const { VERSION, REGULATION, RULESET } = PenaltyTableTypeOfRow
  const [rowHovered, setRowHovered] = useState(false)

  const contextMenuItems = useMemo(() => {
    const showAddActions = item.typeOfRow !== RULESET
    const actions = getTableRowContextMenuActions(showAddActions).map((action) => {
      const modifiedAction = { ...action }
      if (action.itemName === ReTableRowContextActions.ADD_ROW) {
        const label =
          item.typeOfRow === REGULATION ? t`Add new version` : item.typeOfRow === VERSION ? t`Add new rule set` : t`Add`
        modifiedAction.itemLabel = label
      }
      return modifiedAction
    })
    return actions
  }, [item, RULESET])

  const getCellContent = (item: PenaltyRegulationTableItemNew) => {
    const width = math(`${PenaltyNameColumnWidth} - 1em - ${item.uiLevel}em`)

    switch (item.typeOfRow) {
      case REGULATION:
        return (
          <FixedWidthTextContainer width={width} text={item.name as string}>
            <Flex>
              {item.name}
              {item.shared && (
                <Box ml={0.6} mr={0.7}>
                  <InBuiltEntityIcon message={getInBuiltEntityMessage(InBuiltEntities.PENALTY)} />
                </Box>
              )}
            </Flex>
          </FixedWidthTextContainer>
        )
      case RULESET:
        const ruleSetGeneratorTypes = (item?.generatorTypes as string[])?.join(', ')
        return (
          <FixedWidthTextContainer width={width} text={ruleSetGeneratorTypes}>
            {ruleSetGeneratorTypes}
          </FixedWidthTextContainer>
        )

      case VERSION:
        const activeSince = item?.activeSinceTZ?.date
        const versionNameWithDate = `${item?.name} ${formatDate(activeSince as Date)}`
        const activeSinceInUTC = activeSince ? convertZonedTimeToUtc(activeSince, userTimezone) : null
        const activeSinceTimezone = item?.activeSinceTZ?.timezone
        return (
          <Flex>
            <FixedWidthTextContainer width={'10em'} text={item?.name}>
              {item?.name}
            </FixedWidthTextContainer>
            {formatDate(activeSince as Date)}
            {activeSinceInUTC && activeSinceTimezone && activeSinceTimezone !== userTimezone && (
              <DateTimezonePopover dateInUTC={activeSinceInUTC} originalTimezone={activeSinceTimezone as Timezone} />
            )}
          </Flex>
        )
    }
  }

  const handleSelectRegulation = useCallback(() => {
    updateQueryString({
      [QUERY_PENALTY_DETAILS]: item?.id,
      [QUERY_PENALTY_NEW_ENTITY_TYPE]: undefined,
    })
  }, [updateQueryString])

  const handleAddPenaltyEntity = useCallback(() => {
    const typeOfItem = item?.typeOfRow as PenaltyTableTypeOfRow
    let versionHasUnSpecifiedRuleSet = false

    if (item.typeOfRow === VERSION) {
      const ruleSets = item?.penaltyRegulationRuleSets as PenaltyRegulationRuleSet[]
      versionHasUnSpecifiedRuleSet = (ruleSets || []).some((ruleSet) =>
        ruleSet.generatorTypes.includes(PenaltyGeneratorType.UNSPECIFIED),
      )
    }

    if (versionHasUnSpecifiedRuleSet) {
      onOpenDialog()
    } else {
      const create = typeOfItem === VERSION ? RULESET : typeOfItem === REGULATION ? VERSION : ''
      const queryObj = {
        ...getPenaltyDetailsNewQueryObj(item?.id, create as PenaltyTableTypeOfRow),
      }
      updateQueryString(queryObj)
    }
  }, [item, updateQueryString])

  const handleMenuItemClick = useCallback(
    (action: ReTableRowContextActions) => {
      const { ADD_ROW, EDIT_ROW, DELETE_ROW } = ReTableRowContextActions
      if (action === EDIT_ROW) {
        handleSelectRegulation()
      }
      if (action === DELETE_ROW) {
        onDeleteRowItem(item)
      }
      if (action === ADD_ROW) {
        handleAddPenaltyEntity()
      }
    },
    [item, handleSelectRegulation, handleAddPenaltyEntity],
  )

  const showContextMenu = isAdmin(user) || isImpersonatedAdmin(user) || !item.shared

  return (
    <StyledReTableRow
      is_shaded={item.effectiveNow ? 0 : 1}
      key={JSON.stringify(item)}
      selected={selected}
      light_mode={selected}
      onMouseOver={() => setRowHovered(true)}
      onMouseLeave={() => setRowHovered(false)}
    >
      <ReTableCell onClick={handleSelectRegulation} colSpan={2} no_border={true}>
        <Flex justifyContent="space-between" alignItems="center">
          <TDIndented level={sort.active ? 0 : item.uiLevel || 0}>
            <Flex alignItems="center">
              {!sort.active && item.typeOfRow !== RULESET && <ReTableExpandToggle item={item} />}

              {getCellContent(item)}
            </Flex>
          </TDIndented>
          {(rowHovered || selected) && (
            <DetailsLink lightMode={false} title={t`Show details`} onToggle={handleSelectRegulation} />
          )}
        </Flex>
      </ReTableCell>

      <ReTableEditableRowActionsCell
        rowSelected={selected}
        showContextMenu={showContextMenu}
        showFormActions={false}
        actionMenuItems={contextMenuItems}
        onClickMenuItem={handleMenuItemClick}
        dialogText={getPenaltyUpdateWarningMessage()}
        overrideText={true}
        dialogContext={getPenaltyEntityName(item.typeOfRow)}
        navigationDialogKey={''}
        showTableRowContextMenu={true}
        cellWidth={'1em'}
      />
    </StyledReTableRow>
  )
}

export default React.memo(PenaltyRegulationTableRow)
