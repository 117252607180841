import { t } from 'ttag'

import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import {
  AssetMaintenanceDataAttributes,
  Availability,
  AvailabilityTableItem,
  AvailabilityType,
  CalculatedValueType,
} from 'modules/asset/availability/types/availability.types'
import { FormApi } from 'final-form'
import { Timezone } from 'fixtures/timezones'
import { convertUtcToZonedTime, convertZonedTimeToUtc } from 'utils/date'
import { Asset } from 'modules/asset/store/asset.types'
import { isBefore } from 'date-fns'
import { formatNumber, getUnit, limitDecimals, thousandSeparator } from 'utils/dataFormatting'
import { getNomcap, isCluster } from 'utils/asset'
import { AppUnits } from 'utils/units'

export const getAvailabilityColumns: () => Column[] = () => [
  // TODO See also PR-4047.
  // -- HSL
  {
    name: 'uiAssetName',
    label: t`Asset`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    fieldName: 'uiAssetName',
    width: '16em',
    fixed: true,
    isEditable: false,
  },
  {
    name: 'from',
    label: t`Start`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'from',
    isEditable: true,
    width: '12em',
    fixed: true,
  },
  {
    name: 'to',
    label: t`End`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'to',
    isEditable: true,
    width: '12em',
    fixed: true,
  },
  {
    name: 'type',
    label: t`Type`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'type',
    isEditable: true,
    selectItems: getAvailabilityTypes(),
    width: '10em',
    fixed: true,
  },
  {
    name: 'value',
    label: t`Value`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: true,
    width: '10em',
    fixed: true,
  },
  {
    name: 'description',
    label: t`Description`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'description',
    isEditable: true,
    width: '22em',
    fixed: true,
  },
]

export const getAvailabilityTypes = () => {
  return [
    {
      id: AvailabilityType.CAP,
      label: t`Capped`,
    },
    {
      id: AvailabilityType.SCALE,
      label: t`Scaled`,
    },
    {
      id: AvailabilityType.OFF,
      label: t`Off`,
    },
  ]
}

export const getAvailabilityTypeLabel = (type: AvailabilityType) => {
  const availableTypeData = getAvailabilityTypes().find((a) => type && type === a.id)
  if (availableTypeData) {
    return availableTypeData.label
  } else return ''
}

export const transformAvailabilityDataForForm = (availability: Availability) => {
  const transformedData = { ...availability }
  const availableTypeData = getAvailabilityTypes().find((a) => availability.type === a.id)
  if (availableTypeData) {
    transformedData.type = availableTypeData
  }

  return transformedData
}

export const prepareAvailabilityDataBeforeSave = (
  availability: Availability,
  formReference: FormApi<Availability>,
  userTimezone: Timezone,
) => {
  if (availability.isNewAvailability) {
    delete availability.id
    // delete availability.isNewAvailability
  }

  const timezoneToConvertFrom = userTimezone
  availability.timeZone = timezoneToConvertFrom

  if (availability.from) {
    const date = new Date(availability.from)
    date.setSeconds(0o0, 0o00)
    availability.from = convertZonedTimeToUtc(date, timezoneToConvertFrom)
  }
  if (availability.to) {
    const date = new Date(availability.to)
    date.setSeconds(0o0, 0o00)
    availability.to = convertZonedTimeToUtc(date, timezoneToConvertFrom)
  }
  const availabilityType = availability.type?.id
  if (availabilityType) {
    if (availabilityType === AvailabilityType.OFF) {
      // If the availability type is 'OFF', set values for (type, value and unit) because backend doesn't  support the type 'OFF'
      availability.type = AvailabilityType.SCALE
      availability.value = 0
      availability.unit = 'KILOWATT'
    } else {
      availability.type = availabilityType
    }
  }

  delete availability.uiType
  delete availability.uiAssetName
  delete availability.uiValueUnit

  delete availability.uiAncestors
  delete availability.uiParents
  delete availability.uiChildren
  delete availability.uiDescendants
  delete availability.uiLevel

  return availability
}

export const sortAvailabilites = (availabilites: Availability[]): Availability[] => {
  const availabilitesToSort = [...availabilites]
  availabilitesToSort.sort((a, b) => {
    return isBefore(new Date(a.from), new Date(b.from)) ? 1 : -1
  })
  return availabilitesToSort
}

export const transformAvailabilitiesForReTable = (
  availabilities: Availability[],
  allAssets: Asset[],
  userTimezone: Timezone,
): AvailabilityTableItem[] => {
  const sortedAvailabilities = sortAvailabilites(availabilities)
  const availabilityTableItems = sortedAvailabilities.map((availability) => {
    return enrichAvailabilityDataAfterLoad(availability, allAssets, userTimezone)
  })
  return availabilityTableItems
}

export const valueWithUnit = (availability: Availability) => {
  const { value, unit } = availability
  if (!value && !unit) return ''
  const availabilityValue = formatNumber({ data: value, showFractionalPart: false })
  const availabilityUnit = getUnit(unit)
  return availabilityValue + availabilityUnit
}

// normalization and transformation of data
export const enrichAvailabilityDataAfterLoad = (
  availability: Availability,
  allAssets: Asset[],
  userTimezone: Timezone,
) => {
  const now = new Date()
  const asset = allAssets.find((asset: Asset) => asset.id == availability.siteId)
  const enrichedAvailability = { ...availability }

  enrichedAvailability.from = userTimezone
    ? convertUtcToZonedTime(enrichedAvailability.from, userTimezone)
    : enrichedAvailability.from

  if (enrichedAvailability.to) {
    enrichedAvailability.to = userTimezone
      ? convertUtcToZonedTime(enrichedAvailability.to, userTimezone)
      : enrichedAvailability.to
  } else {
    enrichedAvailability.to = ''
  }
  if (enrichedAvailability.type === AvailabilityType.SCALE && enrichedAvailability.value === 0) {
    enrichedAvailability.type = AvailabilityType.OFF
  }

  enrichedAvailability.uiType = getAvailabilityTypeLabel(enrichedAvailability.type)
  enrichedAvailability.uiAssetName = asset?.name
  enrichedAvailability.uiValueUnit = valueWithUnit(enrichedAvailability)
  enrichedAvailability.uiIsPast = isBefore(new Date(enrichedAvailability.to), now)

  // hierarchy information
  const ancestors = [enrichedAvailability.siteId]
  enrichedAvailability.uiAncestors = ancestors
  enrichedAvailability.uiParents = ancestors
  enrichedAvailability.uiChildren = []
  enrichedAvailability.uiDescendants = []
  enrichedAvailability.uiLevel = 1

  return enrichedAvailability
}

export const getNewAvailabilityData = (asset: Asset, userTimezone) => {
  const newAvailabilityId = `${asset.id}-new-availability`
  return {
    id: newAvailabilityId,
    siteId: asset.id,
    isNewAvailability: true,
    from: new Date(),
    to: '',
    description: '',
    timeZone: userTimezone,
    uiAncestors: [asset.id],
    uiChildren: [],
    type: isCluster(asset) ? AvailabilityType.CAP : '',
  }
}

const formatCalculatedValue = (value: number) => {
  const formatedValue = limitDecimals(value, 2)
  return thousandSeparator(parseFloat(formatedValue))
}

export const calculateAvailabilityValue = (value: number | string, unit: string, asset: Asset) => {
  let result: CalculatedValueType = {} as CalculatedValueType
  if (!unit || !value) {
    result = { unit: '', data: '0' }
  } else {
    const nomCap = getNomcap(asset)
    result.unit = unit === 'PERCENT' ? AppUnits.KILO_WATT : AppUnits.PERCENTAGE
    switch (unit) {
      case 'WATT':
        result.data = formatCalculatedValue((100 * Number(value)) / (nomCap * 1000))
        break
      case 'KILOWATT':
        result.data = formatCalculatedValue((100 * Number(value)) / nomCap)
        break
      case 'MEGAWATT':
        result.data = formatCalculatedValue((100 * Number(value)) / (nomCap / 1000))
        break
      case 'PERCENT':
      default:
        result.data = formatCalculatedValue((nomCap * Number(value)) / 100)
        break
    }
  }
  return result
}

type MapAvailabilityToAssets = (assets: Asset[], availabilities: Availability[]) => AssetMaintenanceDataAttributes[]

export const mapAvailabilityToAssets: MapAvailabilityToAssets = (assets, availabilities) => {
  if (availabilities?.length > 0 && assets.length > 0) {
    const result = assets.map((asset) => {
      const data: AssetMaintenanceDataAttributes = {
        asset: asset,
        maintenanceList: availabilities.filter((a) => a.siteId === asset.id),
      }
      return data
    })
    return result
  } else {
    return []
  }
}

// type guards

export const isAvailability = (value: any): value is Availability => {
  return Boolean(value.siteId)
}
