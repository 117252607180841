import { Asset } from 'modules/asset/store/asset.types'
import { Timezone } from 'fixtures/timezones'

export const AvailabilityType = {
  CAP: 'CAP',
  SCALE: 'SCALE',
  OFF: 'OFF',
}
export type AvailabilityType = keyof typeof AvailabilityType

export interface Availability {
  id?: string
  lastModified: string
  siteId: string
  timeZone: Timezone
  value: number | string
  unit: string
  from: string | Date
  to: string | Date
  type: AvailabilityType
  description: string
  uiIsPast?: boolean

  isNewAvailability?: boolean
  uiAssetName?: string
  uiType?: string
  uiValueUnit?: string
  uiAncestors: string[]
  uiParents: string[]
  uiChildren: string[]
  uiDescendants: string[]
  uiLevel: number
}

export interface AvailabilityTableItem extends Availability {
  // To handle adding new availabilities in the table
  name?: string
  isNewAvailability?: boolean
  uiAssetName?: string
  uiType?: string
  uiValueUnit?: string
  uiAncestors: string[]
  uiParents: string[]
  uiChildren: string[]
  uiDescendants: string[]
  uiLevel: number
}

export interface CalculatedValueType {
  unit: string
  data: string
}

export interface AssetMaintenanceDataAttributes {
  asset: Asset
  maintenanceList: Availability[]
}
