import { Asset, Coordinate, SolarPlantTrackerType, TYPE_SOLARPLANT } from 'modules/asset/store/asset.types'
import { User } from 'modules/auth/Auth.types'
import { DeepPartial } from 'ts-essentials'
import { c } from 'ttag'
import { TabItem } from 'utils/tabs'
import { hasAnyAuthority, isAdmin, isDemo, isImpersonatedAdmin, userHasGlobalAccessWithoutDemo } from 'utils/user'
import { showMDCForThisAssetAndUser } from 'utils/asset'
import { Roles } from 'utils/userManagement'

export const PARK_DATA_MODE_MANUAL = 'PARK_DATA_MODE_MANUAL'
export const PARK_DATA_MODE_CALCULATE = 'PARK_DATA_MODE_CALCULATE'

export const MAX_LAT = 90
export const MIN_LAT = -90
export const MAX_LNG = 180
export const MIN_LNG = -180
export const MAX_AZIMUTH = 180
export const MIN_AZIMUTH = -180
export const MIN_TILT = 0
export const MAX_TILT = 90
export const HSAT_TILT_AZIMUTH_MAX = 90
export const HSAT_TILT_AZIMUTH_MIN = -90
export const TSAT_MAX_TILT = 89
export const DEFAULT_LAT = 51.1657
export const DEFAULT_LNG = 10.4515
export const MAX_PPA_RATE = 1000
export const MIN_PPA_RATE = 0
export const MIN_PORT_NUMBER = 0
export const MAX_PORT_NUMBER = 65535
export const MAX_DELIVERY_TIMEOUT = 25000
export const MIN_DELIVERY_TIMEOUT = 1000

export const TrackerNamesEnum = {
  none: 'None',
  ttdatAadat: 'TTDAT/AADAT',
  vsat: 'VSAT',
  tsat: 'TSAT',
  hsat: 'HSAT',
}

export enum ClusterTypesEnum {
  HYBRID = 'HYBRID',
  POOL = 'POOL',
  COLLECTION = 'COLLECTION',
}

export const assetTabNames = {
  details: 'details',
  meterData: 'meterData',
  meterDataCleansing: 'meterDataCleansing',
  availability: 'availability',
  quality: 'quality',
  training: 'training',
  forecastModels: 'forecastModels',
}

export const getAssetTabs: (asset: Asset, loginUser: User) => TabItem[] = (asset: Asset, loginUser: User) => {
  const { details, meterData, meterDataCleansing, availability, quality, forecastModels } = assetTabNames
  const hasPermissionForMDC = hasAnyAuthority(loginUser, [
    Roles.METER_DATA_CLEANSING_WIND,
    Roles.METER_DATA_CLEANSING_PV,
  ])

  return [
    {
      name: details,
      label: c('Asset Details').t`Details`,
      showCount: false,
    },
    {
      name: meterData,
      label: c('Asset Details').t`Meter data`,
      showCount: false,
    },
    {
      name: meterDataCleansing,
      label: c('Asset Details').t`Meter data cleansing`,
      showCount: false,
      userHasPermission: (user: User) => {
        return showMDCForThisAssetAndUser({ asset, user })
      },
      isAdminComponent: hasPermissionForMDC ? false : isAdmin(loginUser) || isImpersonatedAdmin(loginUser),
    },

    {
      name: availability,
      label: c('Asset Details').t`Availability (planned)`,
      showCount: true,
    },
    {
      name: quality,
      label: c('Asset Details').t`Quality`,
      showCount: false,
      userHasPermission: (user: User) => {
        return isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)
      },
      isAdminComponent: userHasGlobalAccessWithoutDemo(loginUser),
    },
    {
      name: forecastModels,
      label: c('Asset Details').t`Forecast Models`,
      showCount: true,
    },
  ]
}

export const getTrackerValues: () => SolarPlantTrackerType[] = () => [
  { id: 0, name: TrackerNamesEnum.none, label: c('Asset:Tracker').t`None` },
  { id: 1, name: TrackerNamesEnum.ttdatAadat, label: c('Asset:Tracker').t`TTDAT/AADAT` },
  { id: 2, name: TrackerNamesEnum.vsat, label: c('Asset:Tracker').t`VSAT` },
  { id: 2, name: TrackerNamesEnum.tsat, label: c('Asset:Tracker').t`TSAT` },
  { id: 3, name: TrackerNamesEnum.hsat, label: c('Asset:Tracker').t`HSAT` },
]

export const getClusterTypes = () => {
  return [
    { key: ClusterTypesEnum.HYBRID, label: c('Asset:ClusterType').t`Hybrid` },
    { key: ClusterTypesEnum.POOL, label: c('Asset:ClusterType').t`Pool` },
    { key: ClusterTypesEnum.COLLECTION, label: c('Asset:ClusterType').t`Collection` },
  ]
}

export const defaultLatLng = (): Coordinate => {
  return {
    latitude: DEFAULT_LAT,
    longitude: DEFAULT_LNG,
  }
}

export const defaultAssetFormData = (): DeepPartial<Asset> => {
  return {
    type: TYPE_SOLARPLANT,
    location: { coordinate: {} },
    typeSpecificAttributes: { trackerName: TrackerNamesEnum.none, azimuth: '0', tilt: '30' },
    manufacturer: 'Default',
    model: 'Lowland',
    generatorIds: [],
    enterManually: false,
    autoDeploymentEnabled: true,
    uiNotOperational: false,
    uiAssetType: TYPE_SOLARPLANT,
  }
}

export const defaultTrackerValues = (trackerName: string) => {
  switch (trackerName) {
    case TrackerNamesEnum.none:
      return {
        azimuth: 0,
        tilt: 30,
        tracker: 0,
      }
    case TrackerNamesEnum.ttdatAadat:
      return {
        trackerAzimuthFrom: -180,
        trackerAzimuthTo: 180,
        trackerTiltFrom: 1,
        trackerTiltTo: 90,
        tracker: 1,
      }
    case TrackerNamesEnum.vsat:
      return {
        trackerAzimuthFrom: -180,
        trackerAzimuthTo: 180,
        tilt: 90,
        tracker: 2,
      }
    case TrackerNamesEnum.tsat:
      return {
        trackerAzimuthFrom: -180,
        trackerAzimuthTo: 180,
        tilt: 30,
        tracker: 2,
      }
    case TrackerNamesEnum.hsat:
      return {
        azimuth: 0,
        trackerTiltFrom: -90,
        trackerTiltTo: 90,
        tracker: 3,
      }
  }
}
