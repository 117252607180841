import { Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import { useLineChartSettings, useLineChartSettingsSaveMutation } from 'modules/workspace/api/lineChart.api'
import { LineChartSettings } from 'modules/workspace/store/workspace.types'
import React, { useCallback, useMemo } from 'react'
import { t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
interface LineChartSettingsMenuProps {
  forWorkbench?: boolean
}

const LineChartSettingsMenu: React.FC<LineChartSettingsMenuProps> = ({ forWorkbench }) => {
  const [settingsMenuEl, setSettingsMenuEl] = React.useState<null | HTMLElement>(null)
  const settingsResult = useLineChartSettings()
  const saveLineChartSettingsResult = useLineChartSettingsSaveMutation()
  const user = useSelector(getUserResultSelector)
  const [
    availabilityPlotBands,
    dayPlotBands,
    tooltip,
    showBackCast,
    scaleToNameplateCapacity,
  ]: (keyof LineChartSettings)[] = [
    'showAvailabilityPlotBands',
    'showDayPlotBands',
    'showChartTooltip',
    'showBackCast',
    'scaleToNameplateCapacity',
  ]

  const saveLineChartSettingsMutation = saveLineChartSettingsResult.mutate
  const chartSettings = useMemo(() => settingsResult.data as LineChartSettings, [settingsResult])

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsMenuEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setSettingsMenuEl(null)
  }, [])

  const saveLineChartSettings = useCallback((data: LineChartSettings) => {
    saveLineChartSettingsMutation(data)
  }, [])

  const onChangeSettings = useCallback(
    (type: keyof LineChartSettings) => {
      const data: LineChartSettings = {
        ...chartSettings,
        [type]: Boolean(!chartSettings[type]),
      }

      saveLineChartSettings(data)
    },
    [chartSettings],
  )

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleMenuClick}>
        <FontAwesomeActionIcon aria-controls="simple-menu" aria-haspopup="true" icon="cog" />
      </IconButton>

      <Menu
        id="line-chart-settings-menu"
        anchorEl={settingsMenuEl}
        keepMounted
        open={Boolean(settingsMenuEl)}
        onClose={handleClose}
      >
        {forWorkbench && (
          <>
            <MenuItem onClick={() => onChangeSettings(availabilityPlotBands)}>
              <ListItemIcon>
                <Checkbox checked={chartSettings?.showAvailabilityPlotBands || false} color="primary" />
              </ListItemIcon>
              <ListItemText primary={t`Show availability plot bands`} />
            </MenuItem>
            <MenuItem onClick={() => onChangeSettings(dayPlotBands)}>
              <ListItemIcon>
                <Checkbox checked={chartSettings?.showDayPlotBands || false} color="primary" />
              </ListItemIcon>
              <ListItemText primary={t`Show day plot bands`} />
            </MenuItem>
          </>
        )}
        <MenuItem onClick={() => onChangeSettings(tooltip)}>
          <ListItemIcon>
            <Checkbox checked={chartSettings?.showChartTooltip || false} color="primary" />
          </ListItemIcon>
          <ListItemText primary={t`Show tooltip`} />
        </MenuItem>
        {forWorkbench && hasPermissionForSiteAssessmentBackcast(user) && (
          <MenuItem onClick={() => onChangeSettings(showBackCast)}>
            <ListItemIcon>
              <Checkbox checked={chartSettings?.showBackCast || false} color="primary" />
            </ListItemIcon>
            <ListItemText primary={t`Show backcast`} />
          </MenuItem>
        )}
        <MenuItem onClick={() => onChangeSettings(scaleToNameplateCapacity)}>
          <ListItemIcon>
            <Checkbox checked={chartSettings?.scaleToNameplateCapacity} color="primary" />
          </ListItemIcon>
          <ListItemText primary={t`Always scale power axis to nameplate capacity`} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default React.memo(LineChartSettingsMenu)
