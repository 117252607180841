import React from 'react'
import { ReTableCell } from 'modules/reTable/ReTableCell'

import { ReTableContextMenuItem, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { AnyObject } from 'react-final-form'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { FormApi } from 'final-form'
import { QUERY_STRING } from 'utils/query-string'
import UpdateTableItemActions from 'modules/reTable/UpdateTableItemsActions'

interface ReTableEditableRowActionsCellProps {
  showContextMenu: boolean
  showFormActions: boolean
  actionMenuItems: ReTableContextMenuItem[]
  onClickMenuItem: (action: ReTableRowContextActions, rowItem?: any) => void
  onSubmit?: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onSave?: () => void // this should be used when final form is not used
  form?: FormApi
  onClose?: () => void
  dialogText: string
  dialogContext: string
  pageRoute?: string
  pageQueries?: QUERY_STRING[] | null
  navigationDialogKey: string
  rowItem?: any
  showAddActions?: boolean
  showTableRowContextMenu?: boolean
  rowSelected?: boolean
  onAddItem?: () => void
  overrideText?: boolean
  cellWidth?: string
}

const ReTableEditableRowActionsCell: React.FC<ReTableEditableRowActionsCellProps> = ({
  showContextMenu,
  showFormActions,
  actionMenuItems,
  onClickMenuItem,
  onSubmit,
  onClose,
  form,
  dialogText,
  dialogContext,
  pageRoute = '',
  pageQueries = null,
  navigationDialogKey,
  rowItem,
  showAddActions,
  showTableRowContextMenu,
  rowSelected,
  onAddItem,
  overrideText,
  cellWidth,
}) => {
  return (
    <>
      <ReTableCell width={cellWidth || editableRowActionsCellWidth}>
        <UpdateTableItemActions
          onAddItem={onAddItem}
          showContextMenu={showContextMenu}
          showFormActions={showFormActions}
          actionMenuItems={actionMenuItems}
          onClickMenuItem={onClickMenuItem}
          onSubmit={onSubmit}
          onClose={onClose}
          form={form}
          dialogText={dialogText}
          dialogContext={dialogContext}
          pageRoute={pageRoute}
          pageQueries={pageQueries}
          navigationDialogKey={navigationDialogKey}
          rowItem={rowItem}
          showAddActions={showAddActions}
          showTableRowContextMenu={showTableRowContextMenu}
          rowSelected={rowSelected}
          overrideText={overrideText}
        />
      </ReTableCell>
    </>
  )
}

export default React.memo(ReTableEditableRowActionsCell)
