import React from 'react'

import { FormKey, FormKeyValueGrid, FormValue, KeyValuePair } from 'ui/form/assetForm.style'
import { c } from 'ttag'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'
import { Field } from 'react-final-form'
import { Box } from '@material-ui/core'

const VersionForm: React.FC = () => {
  return (
    <Box mt={1}>
      <KeyValuePair>
        <FormKeyValueGrid>
          <FormKey>{c('PenaltyRegulation').t`Active since`}:</FormKey>
          <FormValue>
            <Field name="activeSinceTZ.date" label={''} minDate={new Date()} component={DateTimePickerFinalForm} />
          </FormValue>
        </FormKeyValueGrid>
      </KeyValuePair>
    </Box>
  )
}

export default VersionForm
