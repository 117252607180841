import React from 'react'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import Highlight from 'ui/Highlight'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { math } from 'polished'

interface ReTableTextCellProps {
  value: string
  isEdit?: boolean
  label?: string
  name?: string
  query?: string
  width?: string | number
  fieldAutoFocus?: boolean
  tableToolbarHasActions?: boolean
}

const ReTableTextCell: React.FC<ReTableTextCellProps> = ({
  value = '',
  isEdit,
  label,
  name = '',
  query = '',
  width,
  fieldAutoFocus,
  tableToolbarHasActions = false,
}) => {
  return (
    <>
      {isEdit ? (
        <Field
          fullWidth
          autoFocus={fieldAutoFocus}
          component={TextField}
          name={name}
          defaultValue={value}
          label={label}
        />
      ) : (
        <FixedWidthTextContainer
          tableToolbarHasActions={tableToolbarHasActions}
          text={value}
          width={math(`${width} - 1em`) || 'inherit'}
        >
          <Highlight text={value} query={query} />
        </FixedWidthTextContainer>
      )}
    </>
  )
}

export default React.memo(ReTableTextCell)
