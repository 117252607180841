import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import styled from 'styled-components'

const InvalidShiftClickMessage = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  position: absolute;
  transition: all 3s ease-out;
  background: white;
  box-shadow: 0px 0px 4px grey;
  z-index: 1;
  width: calc(100% - 5px);
  left: 3px;
  padding: 5px !important;
  border-radius: 2px;
  font-size: 12px;
  cursor: default;
`

interface HideableShiftClickMessageProps {
  onHideFinished: () => void
}
const HideableShiftClickMessage: React.FC<HideableShiftClickMessageProps> = ({ onHideFinished }) => {
  const [hovered, setHovered] = useState(false)

  const handleMouseLeave = () => {
    onHideFinished()
    setHovered(false)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onHideFinished() // Callback to notify that hide is complete
    }, 2000)

    if (hovered) {
      clearTimeout(timer)
    }
    return () => clearTimeout(timer)
  }, [onHideFinished, hovered])

  return (
    <InvalidShiftClickMessage
      onMouseOver={() => setHovered(true)}
      onMouseLeave={handleMouseLeave}
    >{t`You can use shift-click only to select items within the same hierarchy level.`}</InvalidShiftClickMessage>
  )
}

export default HideableShiftClickMessage
