import { Box } from '@material-ui/core'
import DataMenu from 'modules/dataStreams/DataStreamsMenu'
import { useActiveAreaConfigs } from 'modules/dataStreams/api/areaForecastConfigs.api'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useActiveWeatherConfigs } from 'modules/dataStreams/api/weatherData.api'
import { RETABLE_ID_DATASTREAMS } from 'modules/reTable/reTable.types'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import InlineLoading from 'ui/InlineLoading'
import LayoutTitle from 'ui/LayoutTitle'
import Flex from 'ui/styles/Flex'
import { SideNavContainer } from 'modules/app/app.styles'
import DataStreamTree from 'modules/dataStreams/tree/DataStreamTree'
import { QUERY_DATA_STREAM_ID, useQueryMatch, useQueryParams } from 'utils/query-string'

const DataHeader = styled.div``

const ToolbarWrapper = styled.div`
  margin: 0 1em;
`

const DataContent = styled(Flex)`
  padding-left: 1em;
  overflow-x: hidden;
`

const DataStreamsSideNav = styled(SideNavContainer)`
  right: 0;
`

const DataStreams: React.FC = () => {
  const [siteForecastDetailsId, setSiteForecastDetailsId] = useState<string>('')
  const areaForecastConfigs = useActiveAreaConfigs()
  const forecastConfigs = useSiteForecastConfigs()
  const weatherConfigs = useActiveWeatherConfigs()
  const isDataStreamDetails = useQueryMatch(QUERY_DATA_STREAM_ID)
  const { queryParams } = useQueryParams()

  const loading = useMemo(() => {
    return areaForecastConfigs.isLoading || forecastConfigs.isLoading || weatherConfigs.isLoading
  }, [areaForecastConfigs.isLoading, forecastConfigs.isLoading, weatherConfigs.isLoading])

  useEffect(() => {
    if (queryParams[QUERY_DATA_STREAM_ID]) {
      setSiteForecastDetailsId(queryParams[QUERY_DATA_STREAM_ID])
    } else {
      setSiteForecastDetailsId('')
    }
  }, [queryParams[QUERY_DATA_STREAM_ID]])

  return (
    <DataStreamsSideNav className="not-printable" zIndex={'4'}>
      <Flex direction="column" fullHeight>
        <DataHeader>
          <LayoutTitle>
            <DataMenu />
            <Box>
              {t`Data Streams`}
              {loading && <InlineLoading />}
            </Box>
          </LayoutTitle>
        </DataHeader>
        <ToolbarWrapper>
          <ReTableToolbar id={RETABLE_ID_DATASTREAMS} showClearSorting={false} />
        </ToolbarWrapper>
        <DataContent direction="column" fullHeight>
          <DataStreamTree siteForecastDetailsId={siteForecastDetailsId} isDataStreamDetails={isDataStreamDetails} />
        </DataContent>
      </Flex>
    </DataStreamsSideNav>
  )
}

// DataStreams.whyDidYouRender = true
export default React.memo(DataStreams)
