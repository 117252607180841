import { addDays, differenceInDays, endOfDay, fromUnixTime, getMinutes, isSameDay } from 'date-fns'
import { Timezone } from 'fixtures/timezones'
import Highcharts from 'highcharts/highstock'
import { Asset } from 'modules/asset/store/asset.types'
import { DataIndicationType, ForecastConfig, TimeSeries, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useMemo } from 'react'
import { c } from 'ttag'
import { useUniqueSelectedAssets } from 'utils/asset'

import { formatNumber, isNumeric } from 'utils/dataFormatting'
import {
  convertLocalDateToZonedTime,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_ONLY_TIME,
  formatDate,
  formatDateOnlyTime,
} from 'utils/date'
import {
  isAustriaMarketTimeSeries,
  isFranceM0TimeSeries,
  isMarktwertTimeSeries,
  SimplePoint,
  SimpleRange,
} from 'utils/timeseries'
import { AppUnits } from 'utils/units'
import { YAxisOptions } from 'highcharts'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'

export const WorkspaceChartWidgetHeight = '15em'
export const WorkspaceTableWidgetHeight = '4em'
export const CHART_BOOST_THRESHOLD_VALUE = 99999999999999999999999

// FIXME currently only working for equidistant intervals
// waiting for PR-5530
export const detectTimeSeriesInterval = (data: SimplePoint[] | SimpleRange[]): number => {
  const counts: Record<string, number> = {}
  let lastTime: number | null = null

  data.forEach((row: SimplePoint | SimpleRange) => {
    const time = row[0]
    if (lastTime) {
      const range = time - lastTime
      if (!counts[range]) {
        counts[range] = 0
      }
      counts[range] += 1
    }
    lastTime = time
  })

  let highestCount = 0
  return Object.keys(counts).reduce((mostCommonInterval, interval) => {
    const intervalCount = counts[interval]
    if (intervalCount > highestCount) {
      highestCount = intervalCount
      return Number(interval)
    } else {
      return mostCommonInterval
    }
  }, 0)
}

export const findNearestMatchingInterval = (intervalA: number, intervalB: number): number => {
  let a = intervalA
  let b = intervalB
  let i = 1
  let j = 1
  let nearestMatchingInterval = intervalA || intervalB
  if (intervalA && intervalB) {
    while (a !== b) {
      a = intervalA * i
      if (a === b) {
        nearestMatchingInterval = a
      } else if (a > b) {
        while (a !== b && a > b) {
          b = intervalB * j
          if (a === b) {
            nearestMatchingInterval = b
          }
          j++
        }
      }
      i++
    }
  }

  return nearestMatchingInterval
}

// Intervals should in minutes
export const createSeriesDataWithCustomInterval = (
  data: [number, number, number][] | undefined,
  detectedIntervalInMin = 30,
  customIntervalInMin: number, // 15 for rebap
): [number, number, number][] | undefined => {
  // There is a scenario when detectedIntervalInMin can be 0 , so divide by 0 doesn't make sense , and portal freezes , when you select forecast error chart
  if (detectedIntervalInMin === 0) {
    detectedIntervalInMin = 30
  }

  let dataWithCustomInterval = data
  const detectedIntervalInMilliSec = detectedIntervalInMin * 60 * 1000

  if (detectedIntervalInMin !== customIntervalInMin && data) {
    // Filter the data based on the custom interval
    // For ex: if custom interval is 30, we filter data with time 00:30, 01:00, 01:30, 02:00...
    const dataWithMatchingMinutes = (data || []).filter((values) => {
      const minutes = getMinutes(values[0])
      if (customIntervalInMin === 60) {
        return minutes === 0
      } else if (customIntervalInMin === 30) {
        return [30, 0].includes(minutes)
      } else {
        return [15, 30, 45, 0].includes(minutes)
      }
    })

    // Find the number of intervals to aggregate after filtering in the above method
    const noOfIntervalsToAggregate = customIntervalInMin / detectedIntervalInMin

    // If custom interval is greater than detected interval
    if (noOfIntervalsToAggregate > 1) {
      dataWithCustomInterval = (dataWithMatchingMinutes || []).map((values) => {
        const transformedValues = [...values]
        let noOfIntervalsFound = 1
        // Aggregate the data
        for (let i = 1; i < noOfIntervalsToAggregate; i++) {
          const time = transformedValues[0] - detectedIntervalInMilliSec * i
          const valuesAtThisTime = data.find((d) => d[0] === time)
          if (valuesAtThisTime && getMinutes(time) !== customIntervalInMin) {
            noOfIntervalsFound++
            transformedValues[1] = transformedValues[1] + valuesAtThisTime[1]
            transformedValues[2] = transformedValues[2] + valuesAtThisTime[2]
          }
        }
        // need to return the avg values of the aggregated intervals
        return [
          transformedValues[0],
          transformedValues[1] / noOfIntervalsFound,
          transformedValues[2] / noOfIntervalsFound,
        ]
      })
    } else if (noOfIntervalsToAggregate < 1) {
      const noOfTimeStampsToAdd = detectedIntervalInMin / customIntervalInMin
      const customIntervalInMilliSeconds = customIntervalInMin * 1000 * 60
      // TODO need to check if we should take previous/next values also check end timestamp
      const timeSeriesWithCustomInterval: [number, number, number][] = []
      dataWithMatchingMinutes.forEach((values) => {
        // Existing timestamp and value
        timeSeriesWithCustomInterval.push(values)
        // New custom intervals
        for (let i = 1; i < noOfTimeStampsToAdd; i++) {
          const seriesTimeStamp = values[0] + customIntervalInMilliSeconds
          const seriesValue = values[1]
          timeSeriesWithCustomInterval.push([seriesTimeStamp, seriesValue, seriesValue])
        }
      })
      dataWithCustomInterval = timeSeriesWithCustomInterval
    }
  }
  return dataWithCustomInterval
}

interface ChartTooltipFormatterProps {
  timezone: Timezone
  seriesCreatedWithDateZero?: boolean
  displayOnlyTime?: boolean
  showInputForSchedule?: boolean
  eventSchedule?: any
}

export const getTooltipFormatter = ({
  timezone,
  seriesCreatedWithDateZero,
  displayOnlyTime,
  showInputForSchedule,
  eventSchedule,
}: ChartTooltipFormatterProps) => {
  if (false) {
    // return function () {
    //   if (!this.points) return false
    //
    //   const { x, y } = this
    //   const pointObject = this.points.find((p: Highcharts.Point) => {
    //     return p.x === x && p.y === y
    //   })
    //   if (!pointObject) return false
    //
    //   const thisPoint: Highcharts.Point = pointObject.point
    //
    //   setTimeout(() => {
    //     const button = document.getElementById('tooltip-save')
    //     const valueContainer = document.getElementById('value-div')
    //     if (valueContainer) {
    //       valueContainer.addEventListener('click', () => {
    //         const input = document.querySelector('#tooltip-input')
    //         if (input) {
    //           input.style.display = 'block'
    //           input.defaultValue = thisPoint.y
    //           valueContainer.style.display = 'none'
    //         }
    //       })
    //     }
    //     if (button) {
    //       button.addEventListener('click', eventSchedule)
    //     }
    //   }, 0)
    //
    //   return `<div style="user-select:text; cursor: auto;" onmousedown="event.stopPropagation();">
    //         <p id="value-div">${thisPoint.y}</p>
    //         <input type="text" id="tooltip-input" type="number" style="display:none"/>
    //         <button id="tooltip-save">Save</button>
    //       </div>
    //   `
    // }
  } else {
    return function () {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (!this.points) return false
      const { x, y } = this
      const pointObject = this.points.find((p: Highcharts.Point) => {
        return p.x === x && p.y === y
      })
      if (!pointObject) return false

      const now = new Date()
      const endOfToday = endOfDay(now)
      const next30Days = addDays(endOfToday, 30)

      const thisPoint: Highcharts.Point = pointObject.point

      if (thisPoint.noToolTip) {
        return false
      }

      // setTimeout(() => {
      //   const button = document.getElementById('tooltip-save')
      //   const valueContainer = document.getElementById('value-div')
      //   if (valueContainer) {
      //     valueContainer.addEventListener('click', () => {
      //       const input = document.querySelector('#tooltip-input')
      //       if (input) {
      //         input.style.display = 'block'
      //         input.defaultValue = thisPoint.y
      //         valueContainer.style.display = 'none'
      //       }
      //     })
      //   }
      //   if (button) {
      //     button.addEventListener('click', eventSchedule)
      //   }
      // }, 0)

      const thisSeries = thisPoint.series
      const chart = thisSeries.chart
      const allSeries = chart.series

      let chosenPoints: Highcharts.Point[] = []
      const missingSeries: Highcharts.Series[] = []
      const prevDistances: Record<string, number> = {}
      const nextDistances: Record<string, number> = {}
      const prevPoints: Record<string, Highcharts.Point> = {}
      const nextPoints: Record<string, Highcharts.Point> = {}
      const firstPoints: Record<string, Highcharts.Point> = {}
      const lastPoints: Record<string, Highcharts.Point> = {}

      // FIXME using `point.setState('')` lets all series disappear sometimes
      // if (chart.tooltip.chosenPoints) {
      //   chart.tooltip.chosenPoints.forEach(function (point: Highcharts.Point) {
      //     if (point.setState) {
      //       point.setState('')
      //     }
      //   })
      // }

      allSeries.forEach(function (series) {
        if (!series.name.startsWith('Navigator')) {
          let prevDistance: number | null = null
          let nextDistance: number | null = null
          let prevPoint: Highcharts.Point | null = null
          let nextPoint: Highcharts.Point | null = null
          let firstPoint: Highcharts.Point | null = null
          let lastPoint: Highcharts.Point | null = null
          let hasChosenPoint = false

          series.points.forEach(function (point) {
            const isPrevious = point.x <= thisPoint.x
            const isNext = !isPrevious
            const distance = Math.abs(point.x - thisPoint.x)
            if (isPrevious && (prevDistance === null || distance < prevDistance)) {
              prevDistance = distance
              prevPoint = { ...point } as Highcharts.Point
            }
            if (isNext && (nextDistance === null || distance < nextDistance)) {
              nextDistance = distance
              nextPoint = { ...point } as Highcharts.Point
            }
            if (!firstPoint || point.x < firstPoint.x) {
              firstPoint = { ...point } as Highcharts.Point
            }
            if (!lastPoint || point.x > lastPoint.x) {
              lastPoint = { ...point } as Highcharts.Point
            }
          })

          prevDistances[series.name] = prevDistance || 0
          nextDistances[series.name] = nextDistance || 0

          if (prevPoint) {
            if (prevDistance === 0 || series.options.custom?.shouldBeFilled) {
              chosenPoints.push(prevPoint)
              hasChosenPoint = true
            }

            prevPoints[series.name] = prevPoint
          }

          if (nextPoint) {
            if (
              !hasChosenPoint &&
              isNumeric(nextDistance) &&
              nextDistance <= Number(series.options.custom?.detectedInterval)
            ) {
              chosenPoints.push(nextPoint)
              hasChosenPoint = true
            }
            // in case of capacity we want to show 'Since' instead of our artificial end date
            // which we added in getCapacities:226 (formattedEnd) in order to show the line until the end of the chart
            // NOTE: this is indeed a hacky solution because it would ignore a real capacity change at that day (not very likely to occur)
            if (
              series.options.custom?.datastreamType !== TimeSeriesType.CAPACITY_DATA ||
              differenceInDays(next30Days, new Date(nextPoint.x)) > 1
            ) {
              nextPoints[series.name] = nextPoint
            }
          }

          if (firstPoint) {
            firstPoints[series.name] = firstPoint
          }

          if (lastPoint) {
            lastPoints[series.name] = lastPoint
          }

          if (!hasChosenPoint) {
            missingSeries.push(series)
          }
        }
      })

      // FIXME single tooltip mode doesn't work because it's always the first series instead of the hovered one
      const MAX_TOOLTIP_POINT_COUNT = Number.MAX_SAFE_INTEGER
      const tooltipShowsSinglePoint = chosenPoints.length > MAX_TOOLTIP_POINT_COUNT + 1
      chosenPoints = tooltipShowsSinglePoint ? [thisPoint] : chosenPoints
      chart.tooltip.chosenPoints = chosenPoints

      // Sort the chosen points
      chosenPoints.sort((a, b) => {
        return a.x < b.x ? 1 : -1
      })

      // Group the chosen points which has same dates
      const groupedChosenPoints = chosenPoints.reduce<Record<string, Highcharts.Point[]>>((result, point) => {
        if (!point.series) return result
        const datastreamClassifier = point.series.options.custom?.datastreamClassifier

        const seenInterval = prevDistances[point.series.name] + nextDistances[point.series.name]
        const detectedInterval = point.series.options.custom?.detectedInterval
        const isGermanMarktWert = isMarktwertTimeSeries(datastreamClassifier)
        const isFranceM0 = isFranceM0TimeSeries(datastreamClassifier)
        const isAustriaTimeSeries = isAustriaMarketTimeSeries(datastreamClassifier)
        const interval = seenInterval || detectedInterval
        const pointSeriesName = point.series.name

        // const firstPoint = firstPoints[pointSeriesName]
        const nextPoint = nextPoints[pointSeriesName]
        const isGrouped = point.dataGroup && point.dataGroup.length > 1
        // const groupLength = isGrouped ? (point.dataGroup || []).length : 0
        const hasValue = isNumeric(point.y)

        // TODO SHOULD BE FIXED FOR GERMAN MARKET PRICE BECAUSE DATA IS IN MONTHLY INTERVAL
        if (isGermanMarktWert || isFranceM0 || isAustriaTimeSeries) {
          const timeStart = point.x
          const timeEnd = nextPoint?.x
          let timeIndex = `${timeStart || ''}::${timeEnd || ''}`
          // This is the last point (or next point is in future without data) so we should not put start date so that we see until in tooltip
          if ((timeStart && !timeEnd) || (!nextPoint.high && !nextPoint.low)) {
            timeIndex = `${''}::${point.x || ''}`
          }

          if (!result[timeIndex]) result[timeIndex] = []
          result[timeIndex].push(point)
        } else if (hasValue) {
          // FIXME support for "first points" to show "Until [DATE]" if there is no other data point to the left
          // const timeStart = point.x !== firstPoint.x ? (interval ? point.x - interval : point.x) : undefined
          // const timeStart = interval ? point.x - interval : point.x
          const timeStart = interval ? (isGrouped ? point.x : point.x - interval) : point.x
          // const timeEnd = interval ? (isGrouped ? point.x + (groupLength - 1) * interval : point.x) : nextPoint?.x
          const timeEnd = interval ? (isGrouped ? point.x + interval : point.x) : nextPoint?.x
          const timeIndex = `${timeStart || ''}::${timeEnd || ''}`

          if (!result[timeIndex]) result[timeIndex] = []
          result[timeIndex].push(point)
        } else {
          missingSeries.push(point.series)
        }

        return result
      }, {})

      let tooltipContent: string[] = []
      Object.keys(groupedChosenPoints).forEach((timeIndex, index) => {
        const groupContent = []

        const [timeStart, timeEnd] = timeIndex.split('::')
        const points = groupedChosenPoints[timeIndex]
        points.sort((a, b) => {
          const ay = a.high ? a.high : a.y
          const by = b.high ? b.high : b.y
          return ay < by ? 1 : -1
        })

        const dateStart = timeStart ? fromUnixTime(parseInt(timeStart) / 1000) : null
        const dateEnd = timeEnd ? fromUnixTime(parseInt(timeEnd) / 1000) : null

        points.forEach(function (point) {
          // FIXME using `point.setState('hover')` lets all series disappear sometimes
          // if (point.setState) {
          //   point.setState('hover')
          // }

          const isMarketPriceData = point.series.options?.custom?.datastreamType === TimeSeriesType.MARKET_PRICE_DATA
          const decimalLimit = isMarketPriceData ? 2 : 3

          const unit = point.series.options?.custom?.unit || AppUnits.KILO_WATT
          const isSchedulePoint = point.series.options?.custom?.scheduleDataStreamId
          const isRange = isNumeric(point.low) && isNumeric(point.high) && point.low !== point.high
          const value = isRange
            ? `<strong>${formatNumber({
                data: point.low,
                showFractionalPart: true,
                forceFractionalPart: true,
                separator: true,
                limit: decimalLimit,
              })} ${unit}</strong> .. <strong>${formatNumber({
                data: point.high,
                showFractionalPart: true,
                forceFractionalPart: true,
                separator: true,
                limit: decimalLimit,
              })} ${unit}</strong>`
            : //             : isSchedulePoint
              //             ? ` <strong style="user-select:text; cursor: auto;">
              // <!--                   The Schedule value is shown differently as it has value with input field-->
              //                     <span id="value-div">${formatNumber({
              //                       data: point.y,
              //                       showFractionalPart: true,
              //                       forceFractionalPart: true,
              //                       separator: true,
              //                       limit: decimalLimit,
              //                     })} ${unit}</span>
              //                     <input type="text" id="tooltip-input" type="number" style="display:none; z-index: 999999"/>
              //                      <span id="tooltip-save">Y</span>
              //                 </strong>`
              `<strong>${formatNumber({
                data: point.y,
                showFractionalPart: true,
                forceFractionalPart: true,
                separator: true,
                limit: decimalLimit,
              })} ${unit}</strong>`

          groupContent.push(
            `<span style="font-size: 16px; color: ${point.color}">\u25CF</span> ${point.series.name}: <strong>${value}</strong><br/>`,
          )
        })

        // group header as first element
        let dateLabel = ''
        // This is only for mean day chart because series is created with date zero
        // TODO make this generic for seriesCreatedWithDateZero (1970-01-01)
        if (seriesCreatedWithDateZero) {
          if (dateEnd) {
            const formattedEndDate =
              localTimezone === timezone ? dateEnd : convertLocalDateToZonedTime(dateEnd, timezone)
            dateLabel = formatDateOnlyTime(formattedEndDate)
          }
        } else if (dateStart) {
          // Display only time is for mean day chart
          if (displayOnlyTime) {
            const dateStartFormatted = formatDate(dateStart, timezone, DATE_FORMAT_ONLY_TIME)
            if (dateEnd) {
              const dateEndFormatted = formatDate(dateEnd, timezone, DATE_FORMAT_ONLY_TIME)
              dateLabel = `${dateStartFormatted} .. ${dateEndFormatted}`
            } else {
              dateLabel = `${dateStartFormatted}`
            }
          } else {
            const dateStartFormatted = formatDate(dateStart, timezone)
            if (dateEnd) {
              const dateEndFormatted = formatDate(
                dateEnd,
                timezone,
                isSameDay(dateStart, dateEnd) ? DATE_FORMAT_ONLY_TIME : DATE_FORMAT_DEFAULT,
              )
              dateLabel = `${dateStartFormatted} .. ${dateEndFormatted}`
            } else {
              dateLabel = c('Workbench:Chart').t`Since ${dateStartFormatted}`
            }
          }
        } else {
          if (dateEnd) {
            const dateEndFormatted = formatDate(dateEnd, timezone)
            dateLabel = c('Workbench:Chart').t`Until ${dateEndFormatted}`
          } else {
            dateLabel = '-'
          }
        }
        groupContent.unshift(`${index > 0 ? '<br>' : ''}<strong>${dateLabel}</strong><br>`)

        tooltipContent = [...tooltipContent, ...groupContent]
      })

      // series without data at this date
      if (missingSeries.length > 0) {
        const groupNoDataContent = []
        groupNoDataContent.push(
          `${tooltipContent.length > 0 ? '<br>' : ''}<strong>${c('Workbench:Chart').t`No data`}</strong><br>`,
        )

        const groupLoadingContent = []
        groupLoadingContent.push(
          `${tooltipContent.length > 0 ? '<br>' : ''}<strong>${c('Workbench:Chart').t`Loading data`}</strong><br>`,
        )

        missingSeries.forEach((series) => {
          const content = `<span style="font-size: 16px; color: ${series.options.color}">\u25CF</span> <em>${series.name}</em><br>`
          const isLoading = series.options?.custom?.result?.isLoading
          const isFetching = series.options?.custom?.result?.isFetching
          if (isLoading || isFetching) {
            groupLoadingContent.push(content)
          } else {
            groupNoDataContent.push(content)
          }
        })

        if (groupNoDataContent.length > 1) {
          tooltipContent = [...tooltipContent, ...groupNoDataContent]
        }

        if (groupLoadingContent.length > 1) {
          tooltipContent = [...tooltipContent, ...groupLoadingContent]
        }
      }

      return tooltipContent
    }
  }
}

interface DataIndication {
  type: DataIndicationType
  missingAssets: Asset[]
}

export const useForecastSeriesDataIndication = (forecastConfig?: ForecastConfig) => {
  const { ALL_SITES, PARTIAL_SITES, NO_SITES } = DataIndicationType
  const selectedAssets = useUniqueSelectedAssets()

  const dataIndication = useMemo(() => {
    const indication: DataIndication = {
      type: NO_SITES,
      missingAssets: [],
    }

    if (forecastConfig) {
      indication.missingAssets = selectedAssets.filter(
        (asset) => !forecastConfig?.id || !asset.productConfigIds.includes(forecastConfig.id),
      )

      if (selectedAssets.length === indication.missingAssets.length) {
        indication.type = NO_SITES
      } else if (indication.missingAssets.length > 0) {
        indication.type = PARTIAL_SITES
      } else {
        indication.type = ALL_SITES
      }
    }

    return indication
  }, [forecastConfig, selectedAssets])

  return dataIndication
}

const roundToNearestValue = (number: number, nearestValue: number) => {
  if (number < 0) {
    return Math.floor(number / nearestValue) * nearestValue
  } else {
    return Math.ceil(number / nearestValue) * nearestValue
  }
}

export const getYAxisTickPositions = (timeSeries: TimeSeries[]) => {
  const values = timeSeries?.filter((series) => isNumeric(series[1])).map((val) => val[1])
  const noOfTickPositions = 5
  const minVal = Math.min(...values)
  const maxVal = Math.max(...values)
  const tickPositions: number[] = []
  const difference = minVal < 0 ? Math.abs(minVal) + maxVal : maxVal

  const possibleInterval = difference / 5

  const nearestCount =
    possibleInterval < 10
      ? 5
      : possibleInterval < 25
      ? 10
      : possibleInterval < 50
      ? 25
      : possibleInterval < 100
      ? 50
      : 100

  let interval = 0
  for (let i = 0; i < noOfTickPositions; i++) {
    interval = roundToNearestValue(interval + possibleInterval, nearestCount)
    tickPositions.push(interval)
  }
  tickPositions.push(0)
  if (minVal < 0) {
    const negativeTickValue = roundToNearestValue(possibleInterval, nearestCount)
    tickPositions.push(-negativeTickValue)
  }

  return tickPositions.sort((a, b) => a - b)
}

function getMax(arr) {
  let len = arr.length
  let max = -Infinity

  while (len--) {
    max = arr[len] > max ? arr[len] : max
  }
  return max
}

function getMin(arr) {
  let len = arr.length
  let min = 0

  while (len--) {
    min = arr[len] < min ? arr[len] : min
  }
  return min
}

// The data returned from method is used to fix the y axis values constant irrespective of zoom
export const getYAxisData = (timeSeries: TimeSeries[]) => {
  const values = timeSeries?.filter((series) => isNumeric(series[1])).map((val) => val[1])

  // const min = Math.min(...values)
  const min = getMin([...values])
  const minVal = min > 0 ? 0 : min
  // const maxVal = Math.max(...values)
  const maxVal = getMax([...values])
  // const difference = minVal < 0 ? Math.abs(minVal) + maxVal : maxVal

  const possibleInterval = maxVal / 6
  const max = maxVal + possibleInterval
  return {
    min: minVal,
    max: max,
    startOnTick: false,
    endOnTick: false,
    minRange: max + (minVal < 0 ? Math.abs(minVal) : 0),
    alignTicks: true,
  }
}

export interface ArtificialPointSegment {
  artificialPointIndex: number
  artificialPoint: {
    marker: { states: { hover: { enabled: boolean } } }
    noToolTip: boolean
    x: number
    low: any
    high: any
  }
}

export const findPointOldValue = (pointData) => {
  const points = pointData.origin.points
  for (const key in points) {
    if (points[key] && typeof points[key] === 'object' && isNumeric(points[key].low)) {
      return points[key].low
    }
  }
  return null
}

export const getEmptyChartYAxisData = (): YAxisOptions => {
  return {
    alignTicks: true,
    crosshair: true,
    gridLineWidth: 1,
    labels: {
      align: 'left',
      reserveSpace: true,
    },
    lineWidth: 1,
    minPadding: 0,
    opposite: false,
    showEmpty: false,
    startOnTick: false,
    endOnTick: false,
    title: {
      // offset: 48,
      text: `${c('Workbench:Quality').t`Power`} (${AppUnits.KILO_WATT})`,
    },
  }
}

export const getNameplateCapacityBasedOnAggregation = (capacities: number[], aggregationMode: ChartAggregationMode) => {
  switch (aggregationMode) {
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MIN:
      return Math.min(...capacities)
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_AVG:
      const sum = capacities.reduce((partialSum, currentVal) => partialSum + currentVal, 0)
      return sum / capacities.length
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MAX:
      return Math.max(...capacities)
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_SUM:
      return capacities.reduce((partialSum, currentVal) => partialSum + currentVal, 0)
    default:
      return Math.max(...capacities)
  }
}
