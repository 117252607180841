import React, { useMemo } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { useAvailabilitiesByAssetsTableItems } from 'modules/asset/availability/api/availability.api'
import CenteredLoading from 'ui/CenteredLoading'
import { Availability } from 'modules/asset/availability/types/availability.types'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import AvailabilityTable from 'modules/asset/availability/components/table/AvailabilityTable'
import { FormContainer } from 'ui/form/form.style'
import AssetCrudCommonHeader from 'modules/asset/assetCrud/AssetCrudCommonHeader'
import { assetTabNames } from 'fixtures/assetForm'
import { jt } from 'ttag'
import Flex from 'ui/styles/Flex'
import AssetLink from 'modules/asset/AssetLink'

const MessageFlex = styled(Flex)`
  margin: 1rem;
`

export interface AvailabilitiesProps {
  assetDescendants: Asset[]
  asset: Asset
}

const Availabilities: React.FC<AvailabilitiesProps> = ({ asset, assetDescendants }) => {
  const availabilityResults = useAvailabilitiesByAssetsTableItems(assetDescendants)
  const anyLoading = useMemo(() => availabilityResults.some((res) => res.isLoading), [availabilityResults])

  const availabilities = useMemo(() => {
    let result = [] as Availability[]
    availabilityResults.forEach((res) => {
      if (res.isFetched && res.isSuccess && res.data) {
        result = [...result, ...res?.data]
      }
    })
    return result
  }, [availabilityResults])

  const assetElement = useMemo(
    () => (
      <Box mr={0.5}>
        <AssetLink asset={asset} />
      </Box>
    ),
    [asset],
  )

  return (
    <FormContainer>
      <AssetCrudCommonHeader asset={asset} activeTab={assetTabNames.availability} />
      {assetDescendants.length > 0 && (
        <>
          {asset.changed ? (
            <MessageFlex direction="row" alignItems="center">
              {asset.deleted
                ? jt`${assetElement} has been deleted. Availability information will be shown if you discard the draft.`
                : jt`${assetElement} has been changed. Availability information will be shown if you activate or discard the draft.`}
            </MessageFlex>
          ) : (
            <>
              {anyLoading ? (
                <CenteredLoading size="2em" />
              ) : (
                <AvailabilityTable rootAsset={asset} assets={assetDescendants} availabilities={availabilities} />
              )}
            </>
          )}
        </>
      )}
    </FormContainer>
  )
}

export default React.memo(Availabilities)
