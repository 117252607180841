import React, { useEffect, useRef } from 'react'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_ONLY_TIME, formatDate } from 'utils/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isSameDay, subMilliseconds } from 'date-fns'
import { Timezone } from 'fixtures/timezones'
import { theme } from 'themes/theme-light'
import { Box } from '@material-ui/core'

interface ScheduleChartInputFieldProps {
  pointData: Record<string, any>
  onCloseScheduleInputField: () => void
  timezone: Timezone
  onUpdateScheduleSeries: (pointData: Record<string, any>, valueForCalculation?: number, arrow?: number) => void
}

const ScheduleChartInputField: React.FC<ScheduleChartInputFieldProps> = ({
  pointData,
  onCloseScheduleInputField,
  timezone,
  onUpdateScheduleSeries,
}) => {
  const inputRef = useRef(null)

  const handleSaveScheduleInputValue = (saveValue?: boolean) => {
    const inputElement = document.getElementById('tooltip-input')
    const value = inputElement?.value ? parseInt(inputElement.value) : 0
    if (saveValue) {
      onUpdateScheduleSeries({}, value)
    }
    onCloseScheduleInputField()
  }

  const seriesInterval = pointData?.series?.options?.custom?.detectedInterval
  const startDate = subMilliseconds(new Date(pointData.x), seriesInterval)
  const endDate = new Date(pointData.x)

  const startDateFormatted = formatDate(startDate, timezone)
  const endDateFormatted = formatDate(
    endDate,
    timezone,
    isSameDay(startDate, endDate) ? DATE_FORMAT_ONLY_TIME : DATE_FORMAT_DEFAULT,
  )

  // Function to handle keydown events
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveScheduleInputValue(true)
    } else if (event.key === 'Escape') {
      onCloseScheduleInputField()
    }
  }

  useEffect(() => {
    // Add event listener for keydown events
    document.addEventListener('keydown', handleKeyDown)
    inputRef.current.focus()
    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, []) // Empty dependency array ensures this runs only on mount and unmount

  return (
    <div style={{ width: '160px', padding: '10px', fontSize: '12px', color: 'rgb(17, 17, 17)' }}>
      <strong>{`${startDateFormatted} .. ${endDateFormatted}`}</strong>

      <Box display="flex" flexDirection="row" mt={0.7} alignItems="center">
        <input
          style={{ width: '100px', padding: '3px', fontSize: '12px' }}
          type="number"
          defaultValue={pointData?.low.toFixed(3)}
          id="tooltip-input"
          ref={inputRef}
        />
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon="check"
          onClick={() => handleSaveScheduleInputValue(true)}
          style={{ cursor: 'pointer', marginLeft: '8px', fontSize: '15px' }}
        />
        <FontAwesomeIcon
          style={{ cursor: 'pointer', marginLeft: '8px', fontSize: '15px' }}
          icon="times"
          color="grey"
          onClick={onCloseScheduleInputField}
        />
      </Box>
    </div>
  )
}

export default ScheduleChartInputField
