import { ThemeProvider } from '@material-ui/styles'
import Highcharts from 'highcharts/highstock'
import addBoostModule from 'highcharts/modules/boost'
import Bootstrap from 'modules/app/Bootstrap'
import MainWrapper from 'modules/app/MainWrapper'
import { queryClient } from 'queryClient'
import React, { useCallback, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { HighchartsProvider } from 'react-jsx-highstock'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import store from 'store'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import BlockNavigationDialog from 'ui/elements/BlockNavigationDialog'
import FullHeight from 'ui/FullHeight'

import draggablePoints from 'highcharts/modules/draggable-points'
Highcharts.AST.allowedTags.push('input')
Highcharts.AST.allowedAttributes.push('onmousedown')

draggablePoints(Highcharts)

addBoostModule(Highcharts)

const Page = styled(FullHeight)`
  color: ${theme.app.color.contrast};
  background-color: white;
`

// Application

const App: React.FC = () => {
  const [initialized, setInitialized] = useState<boolean>(false)

  const handleInitialized = useCallback(() => {
    setInitialized(true)
  }, [])

  const handleUninitialized = useCallback(() => {
    setInitialized(false)
  }, [])
  console.log('app rendered')
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <HighchartsProvider Highcharts={Highcharts}>
            <HashRouter>
              {/*TODO get key from environment*/}
              <Page>
                <BlockNavigationDialog>
                  <Bootstrap onInitialized={handleInitialized} onUninitialized={handleUninitialized}>
                    <MainWrapper initialized={initialized} />
                  </Bootstrap>
                </BlockNavigationDialog>
              </Page>
            </HashRouter>
          </HighchartsProvider>
        </ThemeProvider>
      </Provider>
      {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools position="bottom-right" />}
    </QueryClientProvider>
  )
}

export default hot(App)
