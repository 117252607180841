import { Box, Snackbar } from '@material-ui/core'

import ErrorBoundary from 'modules/app/ErrorBoundary'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { QUERY_KEY_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

import DataStreamChart from 'modules/workspace/DataStreamChart'
import WorkspaceHeader from 'modules/workspace/header/WorkspaceHeader'
import {
  GET_WORKSPACE_CONFIGS_REQUEST,
  workspaceConfigsLoadingSelector,
} from 'modules/workspace/store/getWorkspaceConfigs.state'
import {
  workspaceDraftAssetSelectionSelector,
  workspaceDraftChartWidgetsSelector,
  workspaceDraftDataStreamSelectionSelector,
  workspaceDraftResultSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { SET_SERIES } from 'modules/workspace/store/series.state'

import { ChartWidget, GET_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'
import React, { useEffect, useState } from 'react'
import { useIsFetching } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import CenteredLoading from 'ui/CenteredLoading'

import Flex from 'ui/styles/Flex'
import { useUniqueSelectedAssets } from 'utils/asset'
import { useDataStreams } from 'utils/dataStream'

import { hasPermissionForLongRangeForecast, hasPermissionToCreateSchedule, isAuthorizedToPenalties } from 'utils/user'
import { filterSelectionByChartType, getChartWidgetNames, useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import AssetPenalties from 'modules/workspace/advancedChartWidgets/AssetPenalties'
import MeanDayChart from 'modules/workspace/advancedChartWidgets/MeanDayChart'
import SelectOrCreateAssetInfo from 'modules/workspace/SelectOrCreateAssetInfo'
import ForecastErrorChart from 'modules/workspace/advancedChartWidgets/ForecastErrorChart'
import SeasonalForecast from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecast'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'
import QualityTableAndMetaForecastWidgets from 'modules/workspace/advancedChartWidgets/QualityTableAndMetaForecastWidgets'
import EvaluationContent from 'modules/workspace/advancedChartWidgets/evaluation/EvaluationContent'
import Schedule from 'modules/workspace/schedule/Schedule'
import { QUERY_SCHEDULE, useQueryMatch, useQueryString } from 'utils/query-string'
import Alert from '@material-ui/lab/Alert/Alert'
import { theme } from 'themes/theme-light'

const Container = styled.div`
  height: 100%;
  overflow: hidden;
`

const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    background-color: white !important;
    color: ${theme.palette.primary.main};
  }
`

interface WorkspaceBodyProps {
  schedule_form_displayed: number
  selectedChartWidgetLength: number
}

const WorkspaceBody = styled(Box)<WorkspaceBodyProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.schedule_form_displayed ? `calc(100% - 22em)` : '100%')};
  overflow: auto;
  transition: ${(props) => (!props.schedule_form_displayed && props.selectedChartWidgetLength > 1 ? `all 0.5s` : null)};
  transition-delay: ${(props) => (props.schedule_form_displayed ? `0.6s` : null)};
`

interface WorkspaceProps {
  anyAssetSelected: boolean
}

const Workspace: React.FC<WorkspaceProps> = ({ anyAssetSelected }) => {
  const [positionTop, setPositionTop] = useState(0)
  const [notificationState, setNotificationState] = useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
    message: '',
  })
  const [revisionTimingInfo, setRevisionTimingInfo] = useState<any>({
    data: {},
    hoveredPlotLine: null,
    locationX: null,
    locationY: null,
  })

  const { vertical, horizontal, open, message } = notificationState

  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)
  const timezone = useSelector(getUserTimezoneSelector)

  const allAssets = useAllAssets()
  const selectedAssets = useUniqueSelectedAssets()
  const selectedAssetsAndModelIds = useSelector(workspaceDraftAssetSelectionSelector)

  // console.log({ selectedAssets, selectedAssetsAndModelIds })

  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const anyDataIsSelected = dataSelection.length > 0
  const areaDataSelection = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.AREA_FORECAST)

  const chartWidgetNames = getChartWidgetNames()
  const chartSettings = useLineChartSettings().data
  const metaForecastWidgetData = useMetaForecastData()
  const showMetaForecastWidget = metaForecastWidgetData.showMetaForecastWidget

  const { onDeleteQueryStrings } = useQueryString()
  const isCreateScheduleActive = useQueryMatch(QUERY_SCHEDULE)

  // Here we filter by market price because we need to pass asset as parameter to fetch the timeseries
  const marketPriceDataSelection = filterSelectionByChartType(
    dataStreams,
    dataSelection,
    TimeSeriesType.MARKET_PRICE_DATA,
  )

  const notOnlySeasonalSelected = dataSelection.some(
    (element) => element.type !== TimeSeriesType.CLIMATOLOGY && element.type !== TimeSeriesType.SEASONAL_FORECAST,
  )

  const sufficientChartDataIsSelected =
    (anyAssetSelected && anyDataIsSelected) || areaDataSelection.length > 0 || marketPriceDataSelection.length > 0

  useEffect(() => {
    if (!sufficientChartDataIsSelected) {
      dispatch({ type: SET_SERIES, series: [] })
    }
  }, [sufficientChartDataIsSelected])

  const workspaceConfigsLoading = useSelector(workspaceConfigsLoadingSelector)
  const workspaceConfig = useSelector(workspaceDraftResultSelector)
  const selectedChartWidgets = useSelector(workspaceDraftChartWidgetsSelector)
  const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()

  const showChart = true

  const isTimeSeriesFetching = useIsFetching({ queryKey: QUERY_KEY_TIMESERIES }) > 0
  const loading = isTimeSeriesFetching || workspaceConfigsLoading

  useEffect(() => {
    // on mount load all workspace configs
    dispatch({ type: GET_WORKSPACE_CONFIGS_REQUEST })
    dispatch({ type: GET_WORKSPACE_DRAFT_REQUEST })
  }, [])

  useEffect(() => {
    if (isCreateScheduleActive && !hasPermissionToCreateSchedule(user)) {
      onDeleteQueryStrings([QUERY_SCHEDULE])
    }
  }, [isCreateScheduleActive, user])

  // Duplicate widgets are added to the dom and need to removed
  const widgetContainers = document.getElementsByClassName('widgets-container')
  if (widgetContainers.length > 1) {
    for (let index = 1; index < widgetContainers.length; index++) {
      widgetContainers[index].remove()
    }
  }

  const handleScroll = (event: any) => {
    const { scrollTop } = event.target

    setPositionTop(scrollTop)
  }

  const {
    WIDGET_FORECAST_ERROR_CHART,
    WIDGET_META_FORECAST,
    WIDGET_DAILY_MEAN_CHART,
    WIDGET_SEASONAL_TABLE,
    WIDGET_PENALTY_TABLE,
    WIDGET_QUALITY_TABLE,
    WIDGET_SCHEDULE,
  } = ChartWidget

  const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setNotificationState((prevState) => {
      return { ...prevState, open: false }
    })
  }

  return (
    <Container>
      <Flex direction="column" fullHeight>
        <WorkspaceHeader
          positionTop={positionTop}
          isWorkbenchReadyWithSufficientData={sufficientChartDataIsSelected && workspaceConfig}
        />

        {allAssets.isFetched ? (
          sufficientChartDataIsSelected && workspaceConfig ? (
            <WorkspaceBody
              schedule_form_displayed={isCreateScheduleActive ? 1 : 0}
              selectedChartWidgetLength={selectedChartWidgets.length}
              mx={1}
              mb={1}
              onScroll={(event) => handleScroll(event)}
            >
              {/*
                WARNING: ErrorBoundary is needed to avoid errors.
              E.g. when increasing the time range:
              "highstock.src.js:37790 Uncaught TypeError: Cannot delete property '15' of [object Array]"
              */}
              <ErrorBoundary>
                <Flex direction="column" fullHeight className="widgets-container">
                  {/*
                Here we load the data stream chart component because timeseries is calculated in this component
                and that timeries data will be used in other charts. We will check if this widget is selected or not
                inside this component and then load the chart
              */}
                  {notOnlySeasonalSelected && (
                    <DataStreamChart
                      loading={loading}
                      range={chartSelectedDateRange}
                      isHidden={!showChart}
                      timezone={timezone}
                      countOfChartsSelected={selectedChartWidgets.length || 0}
                      selectedAssets={selectedAssets}
                      showE3Widget={showMetaForecastWidget}
                      showTooltip={chartSettings?.showChartTooltip}
                      createScheduleActive={isCreateScheduleActive}
                      setRevisionTimingInfo={setRevisionTimingInfo}
                      revisionTimingInfo={revisionTimingInfo}
                    />
                  )}
                  {/*Chart widgets*/}
                  <>{selectedChartWidgets.includes(ChartWidget.WIDGET_EVALUATION_TABLE) && <EvaluationContent />}</>

                  <>
                    {selectedChartWidgets.includes(WIDGET_FORECAST_ERROR_CHART) && (
                      <ForecastErrorChart
                        loading={loading}
                        range={chartSelectedDateRange}
                        isHidden={!showChart}
                        timezone={timezone}
                        widgetName={chartWidgetNames[WIDGET_FORECAST_ERROR_CHART]}
                        showTooltip={chartSettings?.showChartTooltip}
                        selectedAssets={selectedAssets}
                      />
                    )}
                    {selectedChartWidgets.includes(WIDGET_DAILY_MEAN_CHART) && (
                      <MeanDayChart
                        loading={loading}
                        isHidden={!showChart}
                        userTimezone={timezone}
                        widgetName={chartWidgetNames[WIDGET_DAILY_MEAN_CHART]}
                        showTooltip={chartSettings?.showChartTooltip}
                      />
                    )}
                    {selectedChartWidgets.includes(WIDGET_SEASONAL_TABLE) &&
                      hasPermissionForLongRangeForecast(user) && (
                        <SeasonalForecast
                          dataStreamSelection={dataSelection}
                          assets={selectedAssets}
                          widgetName={chartWidgetNames[WIDGET_SEASONAL_TABLE]}
                        />
                      )}
                    {(selectedChartWidgets.includes(WIDGET_QUALITY_TABLE) ||
                      selectedChartWidgets.includes(WIDGET_META_FORECAST)) && (
                      <QualityTableAndMetaForecastWidgets
                        selectedAssetsAndModelIds={selectedAssetsAndModelIds}
                        selectedAssets={selectedAssets}
                        loading={loading}
                      />
                    )}
                    {selectedChartWidgets.includes(WIDGET_PENALTY_TABLE) && isAuthorizedToPenalties(user) && (
                      <AssetPenalties
                        range={chartSelectedDateRange}
                        selectedAssetsAndModelIds={selectedAssetsAndModelIds}
                        assets={selectedAssets}
                        loading={loading}
                        widgetName={chartWidgetNames[WIDGET_PENALTY_TABLE]}
                        showTooltip={chartSettings?.showChartTooltip}
                        backCastEnabled={chartSettings?.showBackCast}
                      />
                    )}
                  </>
                </Flex>
              </ErrorBoundary>
            </WorkspaceBody>
          ) : (
            <SelectOrCreateAssetInfo />
          )
        ) : (
          <Box mt={3}>
            <CenteredLoading size="5em" />
          </Box>
        )}
        {allAssets.isFetched &&
          sufficientChartDataIsSelected &&
          workspaceConfig &&
          hasPermissionToCreateSchedule(user) && (
            <Schedule
              widgetName={chartWidgetNames[WIDGET_SCHEDULE]}
              showCreateScheduleForm={isCreateScheduleActive}
              setNotificationState={setNotificationState}
              revisionTimingInfo={revisionTimingInfo}
            />
          )}
      </Flex>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical, horizontal }}
        >
          <StyledAlert onClose={handleCloseNotification} severity="success" variant="filled">
            {message}
          </StyledAlert>
        </Snackbar>
      </div>
    </Container>
  )
}

// Workspace.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(Workspace)
