import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { LineChartSettings } from 'modules/workspace/store/workspace.types'
import { useMemo } from 'react'
import { apiRequest } from 'utils/request'
import axios from 'axios'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { Timezone } from 'fixtures/timezones'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'

// Query keys for caching data

export const QUERY_KEY_LINE_CHART_SETTINGS = 'lineChart:settings'

// Hooks to fetch and update via react-query

export const useLineChartSettings = () => {
  const userSettings = useUserSetting<LineChartSettings>(QUERY_KEY_LINE_CHART_SETTINGS)
  const user = useSelector(getUserResultSelector)
  return useMemo(() => {
    const data: LineChartSettings = {
      // default settings if not set by user
      showAvailabilityPlotBands: true,
      showDayPlotBands: true,
      showChartTooltip: true,
      showBackCast: hasPermissionForSiteAssessmentBackcast(user) ? true : false,
      scaleToNameplateCapacity: true,
      // user settings
      ...(userSettings.data || {}),
    }
    return {
      ...userSettings,
      data,
    }
  }, [userSettings])
}

export const useLineChartSettingsSaveMutation = () => {
  return useUserSettingSaveMutation<LineChartSettings>(QUERY_KEY_LINE_CHART_SETTINGS)
}

export const getDataForDownload = async (
  assetId: string,
  dataStreamId: string | undefined,
  timezone: Timezone,
  name: string | undefined,
) => {
  let requestUrl = ``
  if (assetId.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)) {
    requestUrl = `/api/backcast/v1/download/forecast-model/${
      getBackCastModelDataFromId(assetId).modelId
    }/forecast-configuration/${dataStreamId}?zone=${timezone}`
  } else {
    requestUrl = `/api/backcast/v1/download/asset/${assetId}/forecast-configuration/${dataStreamId}?zone=${timezone}`
  }
  return { csvResult: await apiRequest<any>(() => axios.get(requestUrl)), csvName: name }
}
