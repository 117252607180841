import React from 'react'
import { TypesOfScheduleTimingLines } from 'utils/schedule'
import { scheduleTimingColors } from 'themes/theme-light'
import { t } from 'ttag'

interface ScheduleRevisionTimingProps {
  revisionTimingInfo: Record<string, any>
}

const ScheduleRevisionTiming: React.FC<ScheduleRevisionTimingProps> = ({ revisionTimingInfo }) => {
  const infoForHoveredPlotLine = (plotLineType: string) => {
    switch (plotLineType) {
      case TypesOfScheduleTimingLines.revisionWindow:
        return <span style={{ color: scheduleTimingColors.revisionWindow }}>{t`Schedule submission deadline`}</span>
      case TypesOfScheduleTimingLines.realTime:
        return <span style={{ color: scheduleTimingColors.realTime }}>{t`Current time`}</span>
      case TypesOfScheduleTimingLines.forecastOffset:
        return (
          <span
            style={{ color: scheduleTimingColors.forecastOffset }}
          >{t`Earliest permitted start of the next schedule revision`}</span>
        )
      default:
        return null
    }
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: `${revisionTimingInfo.locationX + 20}px`,
        top: `${revisionTimingInfo.locationY}px`,
        zIndex: 9999999,
      }}
    >
      {infoForHoveredPlotLine(revisionTimingInfo.hoveredPlotLine)}
    </div>
  )
}

export default ScheduleRevisionTiming
