// api
import { apiRequest, useOptimisticMutation } from 'utils/request'
import axios from 'axios'
import { Availability } from 'modules/asset/availability/types/availability.types'
import { Asset } from 'modules/asset/store/asset.types'
import { useMutation, useQueries, useQueryClient } from 'react-query'
import { useUniqueAllAssets } from 'utils/asset'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { transformAvailabilitiesForReTable } from 'modules/asset/availability/utils/availabilitiesUtils'
import { isBefore } from 'date-fns'

const getAvailabilityById = async (assetId: string) => {
  return await apiRequest<Availability[]>(() => axios.get(`/api/v1/maintenance/${assetId}`))
}

const deleteAvailability = async (data: Availability) => {
  return await apiRequest<Availability>(() => axios.delete(`/api/v1/maintenance/${data.id}`))
}

const saveAvailability = async (data: Availability) => {
  const isNew = data.isNewAvailability
  if (isNew) {
    return await apiRequest<Availability>(() => axios.post('/api/v1/maintenance', data))
  } else {
    return await apiRequest<Availability>(() => axios.put('/api/v1/maintenance', data))
  }
}

export const QUERY_KEY_AVAILABILITIES_BY_ASSETS = 'assets:availabilitiesByAssets'

interface UseAvailabilitiesByAssets {
  assets: Asset[]
}

export const useAvailabilitiesByAssets = ({ assets }: UseAvailabilitiesByAssets) => {
  if (!assets?.length) return {}
  const queryOptions = assets?.map((asset) => {
    return {
      queryKey: [QUERY_KEY_AVAILABILITIES_BY_ASSETS, asset.id],
      queryFn: () => {
        return getAvailabilityById(asset.id)
      },
    }
  })

  return useQueries(queryOptions)
}

export const useAvailabilitiesByAssetsTableItems = (assets: Asset[]) => {
  const availabilityResult = useAvailabilitiesByAssets({ assets })
  const allAssets = useUniqueAllAssets()
  const userTimezone = useSelector(getUserTimezoneSelector)
  const transformedAvailabilities = availabilityResult?.map((res) => {
    return {
      ...res,
      data: res.data
        ? transformAvailabilitiesForReTable(res.data as Availability[], allAssets, userTimezone)
        : res.data,
    }
  })
  return transformedAvailabilities
}

export const useAvailabilitySaveMutation = () => {
  return useOptimisticMutation<Availability, Availability, Availability[] | undefined>({
    queryCacheKey: QUERY_KEY_AVAILABILITIES_BY_ASSETS,
    apiMutator: (data) => saveAvailability(data),
    cacheUpdater: (updatedAvailability, oldAvailabilities) => {
      return oldAvailabilities
    },
  })
}

export const useAvailabilityDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteAvailability, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_AVAILABILITIES_BY_ASSETS)
    },
  })
}
