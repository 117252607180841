import React from 'react'
import Number from 'ui/Number'
import { Key } from 'ui/KeyValueGrid'
import { getColor } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { normalizeWeights, scaleWeights } from 'utils/math'

interface BarSectionProps {
  bgcolor: string
  width: string
}

const BarSection = styled.div<BarSectionProps>`
  background: ${(props) => props.bgcolor || 'inherit'};
  width: ${(props) => props.width || 'inherit'};
  height: '12px';
`

export interface WeatherModel {
  name: string
  weight: number
}

enum ModelWeightsIndex {
  ICON_EU,
  CMC,
  ECMWF,
  GFS,
  ECMWF_VAR_EPS_MONTHLY_FORECAST,
  UKMET_UM,
  COSMO_EU,
  COSMO_REA6,
  COSMO_DE,
  UKMET_EURO4,
  NAM,
  TRANSVALOR,
  UKMET_WPFS_SINGLE,
  UKMET_WPFS_BLENDED,
  UKMET_SPFBH,
  ICON_WORLD,
  LSA_SAF_MSG,
  NSIDC_IMS,
  REUNIWATT_CMV,
  REUNIWATT_POWER_FORECAST,
  ENSEMBLE_VIRTUAL,
  METEOBLUE,
}

interface WeatherModelEnsembleBarProps {
  weatherModels: WeatherModel[]
}
const WeatherModelEnsembleBar: React.FC<WeatherModelEnsembleBarProps> = ({ weatherModels }) => {
  // {CMC:9, ICON_EU:1 ...}
  const weightsInObjectFormat = weatherModels.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.name]: curr.weight,
    }
  }, {})

  const weightsHaveValueMoreThanOne = Object.keys(weightsInObjectFormat).some((key) => weightsInObjectFormat[key] > 1)

  let transformedWeights = weightsInObjectFormat
  // Scale the weights if at least one weight is greater than 1
  if (weightsHaveValueMoreThanOne) {
    transformedWeights = scaleWeights(weightsInObjectFormat)
  }
  const transformedWeightsInWeatherModelFormat = Object.keys(transformedWeights).map((key) => {
    return {
      name: key,
      weight: transformedWeights[key],
    }
  })

  const normalizedWeights = normalizeWeights(weightsInObjectFormat)
  const transformedNormalizedForWeightsForEnsembleBar = Object.keys(normalizeWeights(normalizedWeights)).map((key) => {
    return {
      name: key,
      weight: normalizedWeights[key],
    }
  })

  // console.log({ weightsInObjectFormat, transformedWeightsInWeatherModelFormat })

  const improvedModelsWeight = transformedWeightsInWeatherModelFormat

  const getTooltipContent = () => {
    return (
      <Box width="28em">
        {improvedModelsWeight.map((model, index) => {
          const colorIndex = !isNaN(ModelWeightsIndex[model.name]) ? ModelWeightsIndex[model.name] : index
          return (
            <Flex key={model.name} alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center" width="22em">
                <FontAwesomeIcon icon="circle" size="sm" color={getColor(colorIndex)} />
                <Key>{model.name}:</Key>
              </Box>

              <strong>
                <Number data={model.weight * 100} limit={2} />
              </strong>
            </Flex>
          )
        })}
      </Box>
    )
  }

  return (
    <Flex style={{ height: '12px' }}>
      <PopperTooltip
        popperLabel={
          <Flex style={{ height: '12px', width: '10em' }}>
            {transformedNormalizedForWeightsForEnsembleBar.map((model, index) => {
              const percent = model.weight * 100 + '%'
              const colorIndex = !isNaN(ModelWeightsIndex[model.name]) ? ModelWeightsIndex[model.name] : index

              return <BarSection key={index} bgcolor={getColor(colorIndex)} width={percent} />
            })}
          </Flex>
        }
        popperContent={getTooltipContent()}
      />
    </Flex>
  )
}

export default React.memo(WeatherModelEnsembleBar)
