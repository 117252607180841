import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { FormApi, Mutator } from 'final-form'
import {
  MDCAssetType,
  MeterDataCleansingConfiguration,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { genericFormSubscription } from 'utils/form'
import { formatDateShort } from 'utils/date'
import MeterDataCleansingForm from 'modules/asset/assetCrud/meterDataCleansing/form/MeterDataCleansingForm'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { c, t } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { Asset } from 'modules/asset/store/asset.types'
import {
  trimMDCConfigToGetCleansingData,
  getDefaultMeterDataCleansingConfiguration,
  transformMDCConfigData,
  convertMDCConfigDatesToUserTimezone,
  getDefaultValuesForBulkMdcFilterSettings,
} from 'utils/meterDataCleansing'
import {
  getCleansingResult,
  startTrainingFromCleansing,
  useDefaultMDCFilterSettings,
  useEnercastMDCFilterSettings,
  usePersistConfigurationSaveMutation,
  usePersistConfigurationsByAssetId,
  useUserMdcTrainingValueSaveMutation,
} from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { QUERY_ACTIVE_TAB, useQueryString } from 'utils/query-string'
import { assetTabNames } from 'fixtures/assetForm'
import { Timezone } from 'fixtures/timezones'
import { validateMeterDataCleansingForm } from 'utils/formValidations'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isSolarAsset } from 'utils/asset'

export enum MDC_REQUEST_FAILED {
  START_TRAINING = 'START_TRAINING',
  GET_CLEANSED_DATA = 'GET_CLEANSED_DATA',
}

let formReference: FormApi<MeterDataCleansingConfiguration>

interface MeterDataCleansingDetailsProps {
  asset: Asset
  totalTimePeriod: MDCTimePeriod
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  timePeriodsToExcludedFromTraining: TimePeriodToHighlightInterface[]
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onChangeTimePeriodForTraining: (timePeriod: MDCTimePeriod) => void
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteAllExcludedTimePeriods: () => void
  onSetExcludedTimePeriodsOnLoadAndAfterSave: (timePeriods: TimePeriodToHighlightInterface[]) => void
  formChangedExternally: boolean
  excludedTimePeriodFromChartIsInvalid: boolean
  onSetFormChangedExternally: (value: boolean) => void
  timezone: Timezone
  linkToDefault: boolean
  handleLinkToDefaultChange: (value?: boolean) => void
}

const MeterDataCleansingDetails: React.FC<MeterDataCleansingDetailsProps> = ({
  asset,
  totalTimePeriod,
  timePeriodToExcludeFromChart,
  timePeriodsToExcludedFromTraining,
  onChangeTimePeriod,
  onChangeTimePeriodForTraining,
  timePeriodToEdit,
  onEditExcludedTimePeriod,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  onDeleteAllExcludedTimePeriods,
  onSetExcludedTimePeriodsOnLoadAndAfterSave,
  formChangedExternally,
  excludedTimePeriodFromChartIsInvalid,
  onSetFormChangedExternally,
  timezone,
  linkToDefault,
  handleLinkToDefaultChange,
}) => {
  const refetchInterval = useRef<any>()
  const userTimezone = timezone
  const user = useSelector(getUserResultSelector)

  const [triggerTrainingDialog, setTriggerTrainingDialog] = useState(false)
  const [refreshData, setRefreshData] = useState(false)
  const [cleansingData, setCleansingData] = useState({})
  const [trainingStarted, setTrainingStarted] = useState(false)
  const [configSaved, setConfigSaved] = useState(false)
  const [mdcRequestFailed, setMdcRequestFailed] = useState<MDC_REQUEST_FAILED | null>(null)
  const [mdcRequestErrorMsg, setMdcRequestErrorMsg] = useState<string | null>(null)
  const [loadingCleansingData, setLoadingCleansingData] = useState(false)

  const typeOfAsset = isSolarAsset(asset) ? MDCAssetType.SOLAR : MDCAssetType.WIND
  const defaultFilterSettings = useDefaultMDCFilterSettings(typeOfAsset)?.data
  const enercastFilterSettings = useEnercastMDCFilterSettings(typeOfAsset)?.data
  const formSubscription = useMemo(() => genericFormSubscription(), [])

  const {
    mutate: saveUserMdcTrainingValue,
    isSuccess: saveUserMdcTrainingValueSuccess,
    isLoading: saveUserMdcTrainingValueLoading,
  } = useUserMdcTrainingValueSaveMutation()

  const { onUpdateQueryString } = useQueryString()
  const { forecastModels } = assetTabNames

  // Save and Get configurations
  const saveConfigResult = usePersistConfigurationSaveMutation(asset, trainingStarted)
  const saveConfigurations = saveConfigResult.mutate
  const saveConfigurationsSuccess = saveConfigResult.isSuccess

  const mdcConfigByAssetResult = usePersistConfigurationsByAssetId(asset.id)

  const getDefaultFilterSettings = () => {
    const filterSettings = defaultFilterSettings || enercastFilterSettings || getDefaultValuesForBulkMdcFilterSettings()
    return filterSettings
  }

  const assetMDCConfig = useMemo(
    () =>
      mdcConfigByAssetResult?.data
        ? convertMDCConfigDatesToUserTimezone(mdcConfigByAssetResult?.data, userTimezone)
        : {},
    [mdcConfigByAssetResult?.data, userTimezone],
  )
  const getAssetMDConfigSuccess = useMemo(() => mdcConfigByAssetResult.isSuccess, [mdcConfigByAssetResult.isSuccess])
  const getAssetMDConfigFailed = useMemo(() => mdcConfigByAssetResult.isError, [mdcConfigByAssetResult.isError])

  const formMutators = useMemo<{
    [key: string]: Mutator<MeterDataCleansingConfiguration, Partial<MeterDataCleansingConfiguration>>
  }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
    }
  }, [])

  const onLinkToDefaultChange = useCallback(() => {
    handleLinkToDefaultChange()
  }, [handleLinkToDefaultChange])

  const handleFormSubmit = useCallback(() => {
    handleSaveConfiguration()
  }, [linkToDefault])

  const handleStartTraining = useCallback(() => {
    setMdcRequestFailed(null)
    setMdcRequestErrorMsg(null)
    setTriggerTrainingDialog(true)
  }, [])

  const handleConfirmStartTraining = () => {
    // Start training involves 3 steps
    setTrainingStarted(true)

    // Step-1 Save Meter Data Cleansing for Training value to "Both" in the user settings
    // After successfully saving the value in user settings we start the training in STEP-3 use effect
    saveMDCTrainingValueIntoUserSettings()
  }

  // STEP-1 Save the value into user settings
  const saveMDCTrainingValueIntoUserSettings = () => {
    const FILTERING_AND_CLEANSING = 'FILTERING_AND_CLEANSING'

    saveUserMdcTrainingValue({ user: user?.login, setting: FILTERING_AND_CLEANSING })
  }

  // STEP-2 to start training we need the latest values so save the MDC configuration from the form
  useEffect(() => {
    if (trainingStarted) {
      handleSaveConfiguration()
    }
  }, [trainingStarted])

  // STEP-3 UseEffect to call the start training after m
  useEffect(() => {
    if (!saveUserMdcTrainingValueLoading && saveUserMdcTrainingValueSuccess) {
      // Prepare request object
      const trimDataForTheRequest = { ...trimMDCConfigToGetCleansingData(formReference.getState().values) }
      const config = transformMDCConfigData({ config: trimDataForTheRequest, userTimezone, asset })
      const requestObject = {
        ...config,
        cleansedData: cleansingData,
      }

      // Set training started
      // setTrainingStarted(true)
      setMdcRequestFailed(null)
      setMdcRequestErrorMsg(null)

      // Make api request
      startTrainingFromCleansing(asset, requestObject)
        .then(() => {
          onUpdateQueryString({ [QUERY_ACTIVE_TAB]: forecastModels })
        })
        .catch(() => {
          setMdcRequestFailed(MDC_REQUEST_FAILED.START_TRAINING)
        })
        .finally(() => {
          setTrainingStarted(false)
          setTriggerTrainingDialog(false) // Close dialog
        })
    }
  }, [saveUserMdcTrainingValueLoading, saveUserMdcTrainingValueSuccess, asset])

  const handleCancelStartTraining = () => {
    setTriggerTrainingDialog(false)
  }

  const handleSetRefreshDataValue = (value: boolean) => {
    setRefreshData(value)
  }

  useEffect(() => {
    if (formChangedExternally) {
      handleSetRefreshDataValue(true)
    }
  }, [formChangedExternally])

  const handleSetRefreshOnSaveExcludedTimePeriod = (timePeriod: TimePeriodToHighlightInterface) => {
    handleSetRefreshDataValue(true)
    onSaveExcludedTimePeriod(timePeriod)
  }

  const handleRefreshData = () => {
    // make api request
    setLoadingCleansingData(true)
    setMdcRequestFailed(null)
    setMdcRequestErrorMsg(null)

    const formValues = { ...formReference.getState().values }
    const trimDataForTheRequest = { ...trimMDCConfigToGetCleansingData(formValues) }
    const convertedConfig = transformMDCConfigData({ config: trimDataForTheRequest, userTimezone, asset })
    getCleansingResult({ assetId: asset?.id, cleansingData: convertedConfig })
      .then((res) => {
        if (res) {
          // when we have result and status code is not 202
          setCleansingData(res)
          setRefreshData(false)
          setLoadingCleansingData(false)
        } else {
          // when we don't have result and status code is 202
          reTriggerCleansingResult(convertedConfig)
        }
      })
      .catch((error) => {
        setLoadingCleansingData(false)
        setRefreshData(false)
        setMdcRequestFailed(MDC_REQUEST_FAILED.GET_CLEANSED_DATA)
        const errorObj = JSON.parse(error.message)
        if (errorObj?.hint || errorObj?.cause) {
          const msg = errorObj?.hint || errorObj?.cause || ''
          setMdcRequestErrorMsg(msg)
        }
      })
  }

  // we have a scenario when we are getting status code 202 from backend , in such a case we need to call this function
  const reTriggerCleansingResult = (convertedConfig: MeterDataCleansingConfiguration) => {
    refetchInterval.current = setInterval(() => {
      getCleansingResult({ assetId: asset?.id, cleansingData: convertedConfig })
        .then((res) => {
          if (res) {
            if (refetchInterval.current) {
              clearInterval(refetchInterval.current)
            }
            setCleansingData(res)
            setRefreshData(false)
            setLoadingCleansingData(false)
          }
        })
        .catch((error) => {
          if (refetchInterval.current) {
            clearInterval(refetchInterval.current)
          }
          setLoadingCleansingData(false)
          setMdcRequestFailed(MDC_REQUEST_FAILED.GET_CLEANSED_DATA)
          const errorObj = JSON.parse(error.message)
          if (errorObj?.hint || errorObj?.cause) {
            const msg = errorObj?.hint || errorObj?.cause || ''
            setMdcRequestErrorMsg(msg)
          }
        })
    }, 2000)
  }

  const initialMeterDataCleansingConfig: MeterDataCleansingConfiguration = useMemo(() => {
    const filterSettings = getDefaultFilterSettings()
    let initialConfig = getDefaultMeterDataCleansingConfiguration({ totalTimePeriod, asset, filterSettings })
    if (assetMDCConfig && Object.keys(assetMDCConfig).length > 0) {
      initialConfig = { ...assetMDCConfig } as MeterDataCleansingConfiguration
      // Add id and name
      const updatedExcludedTimePeriods = assetMDCConfig?.excludedTimePeriods?.map((timePeriod) => {
        const startDate = timePeriod.start.date
        const endDate = timePeriod.start.date
        const id = `${formatDateShort(startDate)}-${formatDateShort(endDate)}`
        const name = `${formatDateShort(startDate)}-${formatDateShort(endDate)}`
        return {
          ...timePeriod,
          id,
          name,
          highlight: false,
        }
      })

      initialConfig['excludedTimePeriods'] = updatedExcludedTimePeriods

      if (Object.keys(initialConfig.timePeriodForTraining).length === 0) {
        initialConfig['timePeriodForTraining'] = totalTimePeriod
      }
    }
    const configWithAssetName = {
      ...initialConfig,
      name: asset.name, // We need this name key to display in the header section
    }

    return configWithAssetName
  }, [totalTimePeriod, assetMDCConfig, defaultFilterSettings, userTimezone, enercastFilterSettings, asset])

  // This handler is used to persist the data only in UI
  const handleSaveConfiguration = () => {
    const data = { ...formReference?.getState().values }
    const configDataInUTC = transformMDCConfigData({ config: data, userTimezone, asset })
    // we are calling updated because we are including also linkToDefault to check which api should be called, and will be deleted before making api call.
    const updateConfigDataInUTC = { ...configDataInUTC, linkToDefault }
    saveConfigurations(updateConfigDataInUTC)
    setConfigSaved(true)
    onSetFormChangedExternally(false)
  }

  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <MeterDataCleansingForm
          initialMeterDataCleansingConfig={initialMeterDataCleansingConfig}
          asset={asset}
          cleansingData={cleansingData}
          defaultFilterSettings={getDefaultFilterSettings()}
          form={form}
          timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
          timePeriodsToExcludedFromTraining={timePeriodsToExcludedFromTraining}
          mdcRequestFailed={mdcRequestFailed}
          onChangeTimePeriod={onChangeTimePeriod}
          onChangeTimePeriodForTraining={onChangeTimePeriodForTraining}
          onFormSubmit={handleSubmit}
          onStartTraining={handleStartTraining}
          onSetRefreshDataValue={handleSetRefreshDataValue}
          onSetFormChangedExternally={onSetFormChangedExternally}
          onRefresh={handleRefreshData}
          loadingCleansingData={loadingCleansingData}
          trainingStarted={trainingStarted}
          timePeriodToEdit={timePeriodToEdit}
          onEditExcludedTimePeriod={onEditExcludedTimePeriod}
          onCancelEditExcludedTimePeriod={onCancelEditExcludedTimePeriod}
          onSaveExcludedTimePeriod={handleSetRefreshOnSaveExcludedTimePeriod}
          onDeleteExcludedTimePeriod={onDeleteExcludedTimePeriod}
          onDeleteAllExcludedTimePeriods={onDeleteAllExcludedTimePeriods}
          refreshData={refreshData}
          totalTimePeriod={totalTimePeriod}
          userTimezone={userTimezone}
          formChangedExternally={formChangedExternally}
          saveResult={saveConfigResult}
          excludedTimePeriodFromChartIsInvalid={excludedTimePeriodFromChartIsInvalid}
          linkToDefault={linkToDefault}
          onLinkToDefaultChange={onLinkToDefaultChange}
          mdcRequestErrorMsg={mdcRequestErrorMsg}
        />
      )
    },
    [
      initialMeterDataCleansingConfig,
      asset,
      totalTimePeriod,
      timePeriodToExcludeFromChart,
      timePeriodsToExcludedFromTraining,
      onChangeTimePeriod,
      onChangeTimePeriodForTraining,
      refreshData,
      loadingCleansingData,
      trainingStarted,
      mdcRequestFailed,
      mdcRequestErrorMsg,
      cleansingData,
      timePeriodToEdit,
      defaultFilterSettings,
      onCancelEditExcludedTimePeriod,
      onEditExcludedTimePeriod,
      onSaveExcludedTimePeriod,
      onDeleteExcludedTimePeriod,
      onDeleteAllExcludedTimePeriods,
      userTimezone,
      formChangedExternally,
      saveConfigResult,
      handleStartTraining,
      excludedTimePeriodFromChartIsInvalid,
      linkToDefault,
      onLinkToDefaultChange,
      onSetFormChangedExternally,
    ],
  )

  useEffect(() => {
    if (saveConfigurationsSuccess) {
      const currentConfiguration = formReference?.getState().values
      formReference.reset(currentConfiguration)
    }
  }, [saveConfigurationsSuccess])

  // Refresh data when config is fetched for the first time not every time when config is saved
  useEffect(() => {
    if ((getAssetMDConfigFailed || getAssetMDConfigSuccess) && !configSaved) {
      handleRefreshData()
    }
  }, [getAssetMDConfigSuccess, getAssetMDConfigFailed, configSaved])

  // If filters of an asset are linked to default filters, mdcConfigByAssetResult returns 404 , so in this case we are automatically setting checkbox to true.
  useEffect(() => {
    if (!mdcConfigByAssetResult.isLoading && mdcConfigByAssetResult.isError && !linkToDefault) {
      handleLinkToDefaultChange(true)
    }
  }, [mdcConfigByAssetResult?.isError])

  useEffect(() => {
    const excludedTimePeriods = initialMeterDataCleansingConfig.excludedTimePeriods || []
    onSetExcludedTimePeriodsOnLoadAndAfterSave(excludedTimePeriods)
  }, [initialMeterDataCleansingConfig])

  // This useEffect is used only to make clean up
  useEffect(() => {
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
      }
    }
  }, [])

  return (
    <>
      <Form
        mutators={formMutators}
        onSubmit={handleFormSubmit}
        initialValues={initialMeterDataCleansingConfig}
        subscription={formSubscription}
        render={formRender}
        validate={validateMeterDataCleansingForm}
      />

      {triggerTrainingDialog && (
        <ConfirmationDialog
          heading={c('Meter data cleansing').t`Starting training`}
          text={c('Meter data cleansing').t`Do you want to start training ?`}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={() => handleConfirmStartTraining()}
          onCancel={() => handleCancelStartTraining}
          openDialog={triggerTrainingDialog}
        />
      )}
    </>
  )
}

export default React.memo(MeterDataCleansingDetails)
