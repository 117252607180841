import {
  createForecastConfigColumn,
  getColumnsNarrow,
  getColumnsWide,
  getDefaultSelectedColumnNames,
} from 'fixtures/assetTree'
import {
  getBackcastStatusOfForecastModel,
  getIsDataCachedByListOfCoordinates,
  useAllAssets,
} from 'modules/asset/api/assets.api'
import { useDraftChangeCounts } from 'modules/asset/api/metadata.api'
import { getAllPlausibilityResultSelector } from 'modules/asset/plausability/redux_store/state/plausability.getAll'
import { getMeterdataSummariesResultSelector } from 'modules/asset/store/getMeterdataSummaries.state'
import { testIdAssetTree } from 'modules/asset/tree/AssetTree.ids'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { getQualityOverviewResultSelector } from 'modules/quality/redux_store/state/getQualityOverview'
import { getTrainingInfoLastSetNetworkSelector } from 'modules/quality/redux_store/state/getTrainingInfo'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTable from 'modules/reTable/ReTable'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { Column, RETABLE_ID_ASSETS, Sort } from 'modules/reTable/reTable.types'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { SAVE_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { table } from 'themes/theme-light'
import { getAssetCountryCode, getAssetSiteForecasts, getNomcap, isCluster, useUniqueAllAssets } from 'utils/asset'
import { usePenaltyNotifications } from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'
import { CoordinateCachedData } from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'
import AssetTreeBody from 'modules/asset/tree/AssetTreeBody'
import AssetTreeFooter from 'modules/asset/tree/AssetTreeFooter'
import AssetTreeHead from 'modules/asset/tree/AssetTreeHead'
import { getAllCleansingParams } from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { FindAllCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { useDataStreams } from 'utils/dataStream'
import { IconButton, Snackbar } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface AssetTreeProps {
  isAssetTable: boolean
  isAssetDetails: boolean
  assetDetailsId: string
  isWide: boolean
}

const AssetTree: React.FC<AssetTreeProps> = ({ isAssetTable, isAssetDetails, assetDetailsId, isWide }) => {
  const dispatch = useDispatch()
  const [assetOperational, setAssetOperational] = useState<CoordinateCachedData[]>()
  const [backcastStatus, setBackcastStatus] = useState<any>()
  const [assetFilterSetup, setAssetFilterSetup] = useState<Record<string, FindAllCleansingFilterSettings>>({})
  const [capacitySummaryHasFractionalValues, setCapacitySummaryHasFractionalValues] = useState<boolean>(false)
  const user = useSelector(getUserResultSelector)
  const [showInvalidShiftClickSnackbar, setShowInvalidShiftClickSnackbar] = useState(false)

  // check if revision chooser is visible
  const { pendingCount } = useDraftChangeCounts()

  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const siteForecastConfigs = useSiteForecastConfigs()
  const penaltyNotificationsResult = usePenaltyNotifications()
  // const assetsCoordinatesCached = useAssetsOperational()

  const allAssets = useAllAssets()
  const uniqueAssets = useUniqueAllAssets()

  // dataStreams
  const dataStreams = useDataStreams()

  // forecast configs for columns
  const forecastConfigs = useSiteForecastConfigs()

  const [assetsToRender, setAssetsToRender] = useState([])
  const handleAssetsToRenderChange = useCallback((itemsToRender) => {
    requestAnimationFrame(() => {
      setAssetsToRender(itemsToRender)
    })
  }, [])

  const columnsNarrow = useMemo(getColumnsNarrow, [])

  const columnsWide = useMemo(() => {
    const cols = getColumnsWide(user)
    const configs = forecastConfigs.isSuccess
      ? (forecastConfigs.data || [])
          // we don't have quality evaluations for default forecast
          .filter((forecastConfig) => forecastConfig.id !== 'default')
      : []
    // create column for this forecast config
    const forecastCols = configs.map<Column>(createForecastConfigColumn)
    // const penaltyCols = configs.map<Column>(createPenaltyColumn)

    return [...cols, ...forecastCols].filter((col) => {
      // columns can have permissions which the user needs to have to see them
      return typeof col.isAuthorized === 'undefined' || (user && col.isAuthorized(user))
    })
  }, [user, forecastConfigs.isSuccess, forecastConfigs.data])

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_ASSETS)
  const columnsSelectedWide = useMemo(() => columnsSelected.filter((c) => !c.fixed), [columnsSelected])

  const columnsAvailable = useMemo(() => {
    return [...columnsNarrow, ...columnsWide]
  }, [columnsNarrow, columnsWide])

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_ASSETS, columnsAvailable })
  }, [JSON.stringify(columnsAvailable)])

  useEffect(() => {
    if (allAssets && allAssets?.data) {
      const coordinates = (allAssets?.data || [])
        .filter((asset) => !isCluster(asset) && asset?.location?.coordinate?.longitude)
        .map((assetWithLocation) => {
          return assetWithLocation?.location?.coordinate
        })
      if (coordinates?.length) {
        getIsDataCachedByListOfCoordinates(coordinates).then((response) => {
          setAssetOperational(response)
        })
      }
    }
  }, [allAssets?.data])

  // TODO depending of selected columns
  const summaries = useSelector(getMeterdataSummariesResultSelector)
  const lastSetNetwork = useSelector(getTrainingInfoLastSetNetworkSelector)
  const qualityOverview = useSelector(getQualityOverviewResultSelector)
  const plausibilityData = useSelector(getAllPlausibilityResultSelector)

  const assetSiteForecastNames = useMemo(() => {
    if (siteForecastConfigs.data) {
      return assetsToRender.reduce((obj, asset) => {
        const assetId = asset?.id
        return {
          ...obj,
          [assetId]: getAssetSiteForecasts(asset, siteForecastConfigs.data)
            .map((config) => config.name)
            .join(', '),
        }
      }, {})
    } else {
      return {}
    }
  }, [assetsToRender, siteForecastConfigs])

  const assetCountryCodes = useMemo(() => {
    return assetsToRender.reduce((obj, asset) => {
      const assetId = asset?.id
      return {
        ...obj,
        [assetId]: getAssetCountryCode(asset),
      }
    }, {})
  }, [assetsToRender])

  const assetPenaltyAccuracyData = useMemo(() => {
    if (penaltyNotificationsResult?.data) {
      const penaltyNotifications = penaltyNotificationsResult?.data
      return assetsToRender.reduce((obj, asset) => {
        const assetId = asset?.id
        const penaltyAccuracies = penaltyNotifications
          .filter((pn) => pn.assetId === assetId && pn.primary)
          .map((fpn) => fpn.accuracy)
        return {
          ...obj,
          [assetId]: penaltyAccuracies.reduce((acc, curr) => acc + curr, 0) / penaltyAccuracies.length,
        }
      }, {})
    } else return {}
  }, [assetsToRender, penaltyNotificationsResult?.data])

  const assetOperationalDataForSort = useMemo(() => {
    if (assetOperational?.length && assetsToRender) {
      return (assetsToRender || []).reduce((obj, asset) => {
        const assetId = asset?.id
        const assetLocationCached = (assetOperational || []).find((ao) => {
          return (
            ao.latitude === asset?.location?.coordinate?.latitude &&
            ao.longitude === asset?.location?.coordinate?.longitude
          )
        })
        return {
          ...obj,
          [assetId]: assetLocationCached ? (assetLocationCached.cached ? 1 : 0) : NaN,
        }
      }, {})
    } else return {}
  }, [assetsToRender, assetOperational])

  // const collapsibleIds = useMemo(
  //   () => (allAssets.data || []).filter((asset) => asset.uiChildren.length > 0).map((asset) => asset.id),
  //   [allAssets.data],
  // )

  const collapsibleIds = useMemo(() => (allAssets.data || []).map((asset) => asset.id), [allAssets.data])

  const defaultCollapsed = useMemo(() => {
    if (!allAssets.data?.length) return

    // per default only show one level deep
    // return (allAssets.data || [])
    //   .filter((asset) => asset.uiLevel === 1 && asset.uiChildren.length > 0)
    //   .map((asset) => asset.id)
    return (allAssets.data || []).map((asset) => asset.id)
  }, [allAssets.data])

  const defaultColumnsSelected = useMemo(getDefaultSelectedColumnNames, [])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  const sortData = useMemo(() => {
    return {
      meterdataSummaries: summaries,
      lastSetNetwork,
      qualityOverview,
      plausibilityData,
      siteForecastNames: assetSiteForecastNames,
      countryCodes: assetCountryCodes,
      assetPenaltyAccuracy: assetPenaltyAccuracyData,
      assetOperationalData: assetOperationalDataForSort,
      assetFilterSetup: assetFilterSetup,
    }
  }, [summaries, lastSetNetwork, qualityOverview, plausibilityData, assetFilterSetup])

  // add padding for additional elements or whitespace to table rows
  const showsRevisionChooser = pendingCount > 0
  const hasExtendedHeader = isWide && columnsSelected.some((col) => col.subItems && col.subItems.length > 0)
  const itemsPaddingHeader = 1 // table header
  const itemsPaddingFooter = 1 + (showsRevisionChooser ? 4 : 0) // add asset link + revision chooser widget
  const renderAheadCount = 25 // how many items are rendered before and after

  // Info about backcast status of forecast-model per asset and forecast model
  // We are using isWide as dependency because we want to make this api call everytime that asset tree table is expanded.
  useEffect(() => {
    if (allAssets?.data) {
      getBackcastStatusOfForecastModel().then((response) => {
        setBackcastStatus(response)
      })
    }
  }, [allAssets?.data, isWide])

  useEffect(() => {
    const findFilterSettingsColumn = columnsSelectedWide.find((column) => column.name === 'assetSpecificFilterSetup')
    if (allAssets?.data && findFilterSettingsColumn && !isAssetDetails) {
      getAllCleansingParams(collapsibleIds).then((response) => {
        setAssetFilterSetup(response)
      })
    }
  }, [allAssets?.data, columnsSelectedWide, isAssetDetails])

  // Remove old models selectionId from assetSelection as it had different Id format 'name/assetId/CONST/CONST/forecastId'
  useEffect(() => {
    if (assetSelection.length && uniqueAssets.length) {
      const currentAssetIds = uniqueAssets.map((asset) => asset.id)

      const filteredSelection = assetSelection.filter((selectedId) => {
        const isIdExist = currentAssetIds.some((assetId) => selectedId.includes(assetId)) // deleted assets or models
        const isNotOldModel = selectedId && !selectedId.includes('/') // old models had '/' in their id
        return isIdExist && isNotOldModel
      })

      if (assetSelection.length !== filteredSelection.length) {
        const draft = {
          asset: {
            selection: filteredSelection,
          },
        }
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
    }
  }, [assetSelection, uniqueAssets])

  // console.log({ assetsToRender, defaultCollapsed })

  const handleSummaryValuesHasFractions = (value: boolean) => {
    setCapacitySummaryHasFractionalValues(value)
  }

  const showNumCapFraction = useMemo(
    () =>
      assetsToRender.some((asset) => getNomcap(asset).toString().split('.').length > 1) ||
      capacitySummaryHasFractionalValues,
    [assetsToRender, capacitySummaryHasFractionalValues],
  )

  const handleCloseShiftClickSnackbar = () => {
    setShowInvalidShiftClickSnackbar(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showInvalidShiftClickSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseShiftClickSnackbar}
        message="Note archived"
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseShiftClickSnackbar}>
              <FontAwesomeIcon icon="window-close" />
            </IconButton>
          </React.Fragment>
        }
      />
      {/* we wait for forecast configs to be initialized so that
          we can initialize correct default columns */}
      {forecastConfigs.isSuccess && (
        <ReTable
          data-testid={testIdAssetTree}
          id={RETABLE_ID_ASSETS}
          itemHeight={table.rowHeight}
          // itemHeight={getItemHeight}
          items={allAssets.data || []}
          hasExtendedHeader={hasExtendedHeader}
          itemsPaddingHeader={itemsPaddingHeader}
          itemsPaddingFooter={itemsPaddingFooter}
          renderAheadCount={renderAheadCount}
          isNarrow={!isAssetTable}
          collapsibleIds={collapsibleIds}
          selectedIds={assetSelection}
          defaultCollapsed={defaultCollapsed}
          defaultColumnsSelected={defaultColumnsSelected}
          defaultSort={defaultSort}
          sortData={sortData}
          onItemsToRenderChange={handleAssetsToRenderChange}
        >
          <AssetTreeHead isWide={isWide} columnsNarrow={columnsNarrow} columnsWide={columnsSelectedWide} />
          <AssetTreeBody
            isWide={isWide}
            columns={columnsSelectedWide}
            assets={assetsToRender}
            selectedAssets={assetSelection}
            isAssetDetails={isAssetDetails}
            assetOperational={assetOperational}
            backcastStatus={backcastStatus}
            assetFilterSetup={assetFilterSetup}
            assetDetailsId={assetDetailsId}
            showNumCapFraction={showNumCapFraction}
            dataStreams={dataStreams}
            onSetShowInvalidShiftClickSnackbar={setShowInvalidShiftClickSnackbar}
          />
          <AssetTreeFooter
            onCapacitySummaryValuesHasFractions={handleSummaryValuesHasFractions}
            isWide={isWide}
            columns={columnsSelectedWide}
            qualityOverview={qualityOverview}
          />
        </ReTable>
      )}
    </>
  )
}

// AssetTree.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(AssetTree)
