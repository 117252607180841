import React, { useMemo } from 'react'
import { Tooltip } from 'react-jsx-highstock'

import { Timezone } from 'fixtures/timezones'
import { getTooltipFormatter } from 'utils/chart'

interface TooltipOptionsProps {
  timezone?: Timezone
  options?: Record<string, unknown>
  seriesCreatedWithDateZero?: boolean
  displayOnlyTime?: boolean
  showInputForSchedule?: boolean
  eventSchedule?: any
}

const TooltipOptions: React.FC<TooltipOptionsProps> = ({
  timezone,
  options,
  seriesCreatedWithDateZero = false,
  displayOnlyTime = false,
  showInputForSchedule,
  eventSchedule,
}) => {
  const tooltipOptions = useMemo(() => {
    return {
      backgroundColor: 'rgba(245, 245, 245, 0.85)',
      borderColor: 'rgba(205, 205, 205, 0.5)',
      borderRadius: 3,
      borderWidth: 1,
      // stickOnContact: true,
      formatter: timezone
        ? getTooltipFormatter({
            timezone,
            seriesCreatedWithDateZero,
            displayOnlyTime,
            showInputForSchedule,
            eventSchedule,
          })
        : undefined,
      // headerFormat: '<span>{point.x:%Y-%m-%d %H:%M}</span>',FF
      shared: true,
      split: false,
      style: {
        color: '#111',
        pointerEvents: 'auto',
      },
      useHTML: true,
      valueDecimals: 1,
      ...(options || {}),
    }
  }, [timezone, showInputForSchedule])

  return <Tooltip {...tooltipOptions} />
}

export default React.memo(TooltipOptions)
