import React from 'react'
import MeterDataCleansingChart, {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { convertUtcToZonedTime, convertZonedTimeToAnotherZonedTime } from 'utils/date'
import { Timezone } from 'fixtures/timezones'
import { Asset } from 'modules/asset/store/asset.types'
import { isAfter, isBefore } from 'date-fns'

interface MeterDataCleansingChartWrapperProps {
  totalTimePeriod: MDCTimePeriod
  timezone?: Timezone
  asset: Asset
  onAddNewTimePeriodToExclude: (timePeriod: TimePeriodToHighlightInterface) => void
  timePeriodsToExcludeFromChart: TimePeriodToHighlightInterface[]
  timePeriodExcludedFromTraining: TimePeriodToHighlightInterface[]
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  linkToDefault: boolean
}

const MeterDataCleansingChartWrapper: React.FC<MeterDataCleansingChartWrapperProps> = ({
  totalTimePeriod,
  timezone = 'UTC',
  asset,
  onAddNewTimePeriodToExclude,
  timePeriodsToExcludeFromChart,
  onEditExcludedTimePeriod,
  timePeriodExcludedFromTraining,
  linkToDefault,
}) => {
  // Convert the zoned dates to UTC and then create a local date because highchart expects local date
  const timePeriodsToExcludeFromChartInUTC = [...timePeriodsToExcludeFromChart].map((timePeriod) => {
    const startDateInUTC = convertZonedTimeToAnotherZonedTime(timePeriod.start.date, timezone, 'UTC')
    const endDateInUTC = convertZonedTimeToAnotherZonedTime(timePeriod.end.date, timezone, 'UTC')
    return {
      ...timePeriod,
      start: { date: new Date(startDateInUTC), timezone },
      end: { date: new Date(endDateInUTC), timezone },
    }
  })

  // Convert the zoned dates to UTC and then create a local date because highchart expects local date
  const timePeriodExcludedFromTrainingInUTC = [...timePeriodExcludedFromTraining].map((timePeriod) => {
    const startDateInUTC = convertZonedTimeToAnotherZonedTime(timePeriod.start.date, timezone, 'UTC')
    const endDateInUTC = convertZonedTimeToAnotherZonedTime(timePeriod.end.date, timezone, 'UTC')
    return {
      ...timePeriod,
      start: { date: new Date(startDateInUTC), timezone },
      end: { date: new Date(endDateInUTC), timezone },
    }
  })

  // We need to convert the UTC timePeriod because selection from chart will always produces dates in UTC
  const handleSelectTimePeriod = (timePeriod: TimePeriodToHighlightInterface) => {
    let startDate = convertUtcToZonedTime(timePeriod.start.date, timezone)
    let endDate = convertUtcToZonedTime(timePeriod.end.date, timezone)
    if (totalTimePeriod?.start?.date && isBefore(startDate, totalTimePeriod.start.date as Date)) {
      startDate = totalTimePeriod.start.date
    }
    if (totalTimePeriod?.end?.date && isAfter(endDate, totalTimePeriod.end.date as Date)) {
      endDate = totalTimePeriod.end.date
    }
    const timePeriodInUserTimezone = {
      ...timePeriod,
      start: { date: startDate, timezone },
      end: { date: endDate, timezone },
    }
    // console.log({ timePeriodInUserTimezone })
    onAddNewTimePeriodToExclude(timePeriodInUserTimezone)
  }

  const totalTimePeriodInDateRangeFormat = [totalTimePeriod.start.date as Date, totalTimePeriod.end.date as Date]

  return (
    <MeterDataCleansingChart
      asset={asset}
      onSelectTimePeriod={handleSelectTimePeriod}
      onClickExcludedTimePeriodToEdit={onEditExcludedTimePeriod}
      timePeriodsToExcludeFromChart={timePeriodsToExcludeFromChartInUTC}
      timezone={timezone}
      totalTimePeriod={totalTimePeriodInDateRangeFormat}
      timePeriodExcludedFromTraining={timePeriodExcludedFromTrainingInUTC}
      linkToDefault={linkToDefault}
    />
  )
}

export default React.memo(MeterDataCleansingChartWrapper)
